import React, { useEffect, useState } from "react";
import { TailoredStep, TailoringInterface } from "../../../Context";
import { FaThumbsDown } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa6";
import { FaQuestion } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { emptyVideo } from "../StepSelectionModal";

interface InputProps {
    handleChange : Function,
    idx : number,
    question : TailoringInterface
}

const NewQuestion = ({idx, handleChange, question}:InputProps) : React.ReactElement => {
    return(
        <form onChange={(e)=>{handleChange("add", idx, e)}} className="w-full p-2 flex flex-wrap gap-2 border-brandLightGray border-2 rounded-xl">
            <div className="flex flex-row justify-start items-center w-full">
                <div className="w-2/3">
                    <p className="text-xl w-full">Topic {idx + 1}</p>
                </div>
                <div className="w-1/3 flex justify-end">
                    <span className="tooltip tooltip-left" data-tip="Delete field">
                        <MdDeleteForever className="size-6 text-red hover:scale-110 transition cursor-pointer" onClick={()=>{handleChange("remove", idx);}}/>    
                    </span>
                </div>
            </div>
            <input type="text"  name="quesitonLabel" placeholder="Question..." defaultValue={question.question}
            className="input input-bordered rounded-full w-full"/>
        </form>
    );
}

interface Props {
    idx : number,
    tailor : TailoredStep,
    setTailorScreen : Function
}

const TailoringQuestions = ({idx, tailor, setTailorScreen}:Props) : React.ReactElement => {
    const [tailoring, setTailoring] = useState<TailoredStep>(tailor);
    const [questions, setQuestions] = useState<TailoringInterface[]>((tailor.questions)? tailor.questions : []);

    const handleTopicChanges = (action : string, idx : number, event? : any) => {
        let newQuestions : TailoringInterface[] = questions!;
        if(event && newQuestions){
            newQuestions![idx].question = event.target.value;
        }
        if(action === "remove"){
            newQuestions = questions!.filter((question : any) => question !== questions![idx]);
            setQuestions(newQuestions);
        }
        const newTailoring : TailoredStep = {
            header : tailoring.header,
            description : tailoring.description,
            ctaString : tailoring.ctaString,
            topicString : tailoring.topicString,
            selectAllString : tailoring.selectAllString,
            importanceStrings : tailoring.importanceStrings,
            questions : newQuestions,
            textScreenStyle : tailoring.textScreenStyle,
            h1Style : tailoring.h1Style,
            pStyle : tailoring.pStyle,
            ctaStyle : tailoring.ctaStyle,
            tableStyle : tailoring.tableStyle
        }
        setTailorScreen(newTailoring);
        setTailoring(newTailoring);
        sessionStorage.setItem(`TailoringQuestions${idx}`, JSON.stringify(newTailoring));
    }

    const updateChanges = () => {
        const newTailoring : TailoredStep = {
                    header : tailoring.header,
                    description : tailoring.description,
                    ctaString : tailoring.ctaString,
                    topicString : tailoring.topicString,
                    selectAllString : tailoring.selectAllString,
                    importanceStrings : tailoring.importanceStrings,
                    questions : (questions) ? questions : [],
                    textScreenStyle : tailoring.textScreenStyle,
                    h1Style : tailoring.h1Style,
                    pStyle : tailoring.pStyle,
                    ctaStyle : tailoring.ctaStyle,
                    tableStyle : tailoring.tableStyle
                }
        setTailorScreen(newTailoring);
        setTailoring(newTailoring);
        sessionStorage.setItem(`TailoringQuestions${idx}`, JSON.stringify(newTailoring));
    }

    useEffect(()=>{
    }, []);

    return(
        <div className="w-full flex flex-row gap-5">
            <div className="w-1/2 flex flex-row justify-start items-start gap-2 p-2 m-2">
                <form onChange={updateChanges} className="w-full p-5 flex flex-col gap-5">
                    <p className="text-xl">Edit questions</p>
                    {
                        questions?.map((question : TailoringInterface, idx : number) => {
                            return<NewQuestion idx={idx} question={question} handleChange={handleTopicChanges}/>
                        })
                    }
                    <button className="btn w-fit font-light bg-brandPurple text-white" type="button"
                    onClick={()=>{
                        if(questions){
                            setQuestions([...questions, {question : "", importance : undefined, video : JSON.parse(JSON.stringify(emptyVideo.video))}])
                        }else{
                            setQuestions([{question : "", importance : undefined, video : JSON.parse(JSON.stringify(emptyVideo.video))}]);
                        }
                    }}>Add new</button>
                </form>
            </div>
            <div className="w-1/2" style={{padding : `${tailoring.textScreenStyle.padding}px`, backgroundColor : `${tailoring.textScreenStyle.backgroundColor}`}}>
                <div className={`text-${"left"} flex flex-col gap-3`}>
                    <div className="flex flex-row">
                        <div className="flex flex-col gap-5 w-3/5">
                            <p style={{fontSize :`${tailoring.h1Style.fontSize}px`, color: `${tailoring.h1Style.color}`}}>{tailoring.header}</p>
                            <p style={{fontSize :`${tailoring.pStyle.fontSize}px`, color: `${tailoring.pStyle.color}`}}>{tailoring.description}</p>
                        </div>
                        <div className="flex justify-end items-end w-2/5">
                            <button className="btn font-light" style={{fontSize :`${tailoring.ctaStyle.fontSize}px`, color: `${tailoring.ctaStyle.color}`,
                                padding : `${tailoring.ctaStyle.padding}px`, borderRadius : `${tailoring.ctaStyle.borderRadius}px`,
                                backgroundColor : `${tailoring.ctaStyle.backgroundColor}`}}>{tailoring.ctaString}</button>
                        </div>
                    </div>
                    <div className="p-5 rounded-lg" style={{backgroundColor : `${tailoring.tableStyle.backgroundColor}`}}>
                        <table className="w-full">
                            <thead className="">
                                <tr className="">
                                    <th className="w-2/5 text-left" style={{fontSize :`${tailoring.tableStyle.titleFont}px`, color: `${tailoring.tableStyle.titleColor}`}}>
                                        {tailoring.topicString} 
                                    </th>
                                    {tailoring.importanceStrings.map((importance, idx) => {
                                        return(
                                            <th className="w-1/5 text-center" key={idx} 
                                            style={{fontSize :`${tailoring.tableStyle.titleFont}px`, color: `${tailoring.tableStyle.titleColor}`}}>
                                                {importance}<br/>
                                                <span className="text-xs cursor-pointer" style={{color: `${tailoring.ctaStyle.backgroundColor}`}}>
                                                    {tailoring.selectAllString}
                                                </span>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                        </table>
                        <div className="divider divider-neutral"></div>
                        <table className="w-full mt-5">
                        <tbody className="mt-5">
                                {tailoring.questions.map((question : any, idx : number)=> {
                                    return(
                                        <tr style={{backgroundColor : `${(idx % 2 !== 0)? tailoring.tableStyle.backgroundColor : `${tailoring.tableStyle.backgroundColor} 50%`}`}}>
                                            <td className="p-5 w-2/5" style={{fontSize :`${tailoring.tableStyle.textFont}px`, color: `${tailoring.tableStyle.textColor}`}}>
                                                {question.question}
                                            </td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 3) ? "bg-green text-white" : "hover:bg-green hover:text-white opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{}}>
                                                <p className="flex justify-center items-center"><FaThumbsUp /></p>
                                            </td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 2) ? "bg-yellow" : "hover:bg-yellow opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{}}>
                                                <p className="flex justify-center items-center"><FaQuestion /></p>
                                            </td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 1) ? "bg-red text-white" : "hover:bg-red hover:text-white opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{}}>
                                                <p className="flex justify-center items-center"><FaThumbsDown /></p>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TailoringQuestions;