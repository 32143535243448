import React, { useContext } from "react";
import { Context, Data } from "../../components/Context";
import NavBar from "../../components/general/NavBar";
import { FaPlus } from "react-icons/fa";
import MagnetCard from "../../components/user/sequenceList/MagnetCard";

const SequenceList = () : React.ReactElement => {
    const { loadDone, workspaceData, leadMagnets, navigate, setSteps } = useContext(Context);

    return(
        <div className="h-full">
            <NavBar pageName="Lead Magnets" pageDescription={`Colleciton of your lead magnets`}/>
            <div className="flex lg:flex-wrap max-lg:flex-col p-5 h-full gap-5">
                <div className={`
                flex flex-col justify-center items-center
                2xl:w-[22%] xl:w-[30%] lg:w-[30%] ${(leadMagnets && leadMagnets.length > 3)?"h-[37vh]":"h-[320px]"}
                border-dashed border-4 rounded-lg border-brandLightPurple
                text-brandLightPurple 
                cursor-pointer 
                hover:scale-105 transition hover:text-brandLightGray hover:border-brandLightGray hover:bg-brandLightPurple
                `} onClick={()=>{sessionStorage.clear(); setSteps(undefined); navigate("/sequences/newFunnel");}}>
                    <p>Create a new lead magnet</p>
                    <FaPlus className="size-10"/>
                </div>
                {
                (loadDone && leadMagnets && workspaceData)
                ?<>{leadMagnets.map((magnet : any, idx : number) => {
                    let magnetData : Data = {id : "", name : magnet.form_name, steps14Days : [], stepsComparison : [], conversion14Days : 0, conversionComparison : 0};
                    let CollectsLeads : any ={collectsLeads : false};
                    if(workspaceData){
                        workspaceData.forEach((data : Data) => {
                            if(data.id === magnet._id){magnetData = data}
                        })
                    };

                    if(Object.keys(magnet.steps).length > 0){
                        for(let i = 0; i < Object.keys(magnet.steps).length; i++){
                            if(magnet.steps[i].action === "form"){
                                CollectsLeads.collectsLeads = true;
                                CollectsLeads.step = i;  
                            }
                        }
                    }
                    var thumbnail : string = "/CX-Tailor-Logo-Slogan.png";
                    
                    if(magnet.steps[0].action === "video"){
                        if(magnet.steps[0].video.poster !== undefined && magnet.steps[0].video.poster !== ""){
                            thumbnail = magnet.steps[0].video.poster;
                        };
                    }
                    return<MagnetCard 
                           active={(magnetData.steps14Days[0].view)?magnetData.steps14Days[0].view > 0:false} thumbnail={thumbnail} name={magnet.form_name} id={magnet._id} idx={idx}
                           startRate={(magnetData.steps14Days.length > 0 && magnetData.steps14Days[0].view)?magnetData.steps14Days[0].start / magnetData.steps14Days[0].view : 0}
                           startComparison={(magnetData.stepsComparison.length > 0 && magnetData.stepsComparison[0].view)?magnetData.stepsComparison[0].start / magnetData.stepsComparison[0].view : 0}
                           conversion={(magnetData.conversion14Days !== null)? magnetData.conversion14Days:0} conversionComparison={(magnetData.conversionComparison !== null)?magnetData.conversionComparison:0}
                           leads={(CollectsLeads.collectsLeads)?magnetData.steps14Days[CollectsLeads.step].finish:undefined} 
                           leadsComparison={(CollectsLeads.collectsLeads)?magnetData.stepsComparison[CollectsLeads.step].finish:undefined} 
                           />
                })}</>
                :<>
                    {[...Array(7)].map((idx : number) => {return <span className="skeleton w-[22%] h-2/5"></span>})}
                </>
            }
            </div>
        </div>
    );
};

export default SequenceList;