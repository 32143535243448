import React, { useContext, useRef, useState } from "react";
import { Context, Step } from "../../components/Context";
import VideoStep from "../../components/user/MagnetCreation/VideoScreen/VideoStep";
import FormStep from "../../components/user/MagnetCreation/FormPage/FormStep";
import EndStep from "../../components/user/MagnetCreation/ThankYouPage/EndStep";
import TextStep from "../../components/user/MagnetCreation/TextScreen/TextScreenStep";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import FunnelPreview from "../../components/user/sequenceInfo/FunnelPreview";

interface ChangeList {
    stepIdx : number,
    action : string
}

interface VariationProps {
    newABsteps : Step[],
    setNewABsteps : Function,
    changesMadeTo : ChangeList[],
    setChangesMadeTo : Function,
    endScreenAdded : boolean,
    setEndScreenAdded : Function
}

const CreateVariation = ({ newABsteps, setNewABsteps, changesMadeTo, setChangesMadeTo, endScreenAdded, setEndScreenAdded } : VariationProps) : React.ReactElement => {

    return(
        <div className="p-5 mb-10 mt-10">
                {
                    (newABsteps && newABsteps.length > 0)
                    ?<>
                    {newABsteps.map((step : any, idx : number) => {
                        if(step.action === "video"){
                            return<div className="flex flex-col justify-center items-center w-full">
                                    <VideoStep 
                                    setSteps={setNewABsteps} steps={newABsteps} idx={idx} step={step} 
                                    editOnly={true} changesMadeTo={changesMadeTo} setChangesMadeTo={setChangesMadeTo}
                                    />
                                    <div className="h-20 w-1 bg-brandPurple"></div>
                                </div>
                        }
                        if(step.action === "form"){
                            return<div className="flex flex-col justify-center items-center w-full">
                                    <FormStep setSteps={setNewABsteps} steps={newABsteps} step={step} idx={idx}
                                    editOnly={true} changesMadeTo={changesMadeTo} setChangesMadeTo={setChangesMadeTo}
                                    />
                                    <div className="h-20 w-1 bg-brandPurple"></div>
                                  </div>
                        }
                        if(step.action === "textScreen"){
                            return<div className="flex flex-col justify-center items-center w-full">
                                    <TextStep setSteps={setNewABsteps} steps={newABsteps} step={step} idx={idx}
                                    editOnly={true} changesMadeTo={changesMadeTo} setChangesMadeTo={setChangesMadeTo}
                                    />
                                    <div className="h-20 w-1 bg-brandPurple"></div>
                                  </div>
                        }
                        if(step.action === "end screen"){
                            return <div className="flex flex-col justify-center items-center w-full">
                                        <EndStep setSteps={setNewABsteps} steps={newABsteps} step={step} idx={idx} setEndScreenAdded={setEndScreenAdded}
                                        editOnly={true} changesMadeTo={changesMadeTo} setChangesMadeTo={setChangesMadeTo}/>
                                    </div>
                        }
                        return null
                    })}
                    </>
                    :null
                }
            </div>
    );
}

const ABtest = () : React.ReactElement => {
    const {selectedMagnet, newABsteps, setNewABsteps, saveNewABTest, user, userToken, workspace} = useContext(Context);
    const [endScreenAdded, setEndScreenAdded] = useState<boolean>(false);
    const [variationCreated, setVariationCreated] = useState<boolean>(false);
    const [changesMadeTo, setChangesMadeTo] = useState<ChangeList[]>([]);

    const testNameRef = useRef<HTMLInputElement>(null);
    const commentsRef = useRef<HTMLInputElement>(null);
    const [mainGoal, setMainGoal] = useState<string>();
    const [secGoal, setSecGoal] = useState<string>();

    const saveAndContinue = () => {
        saveNewABTest(
            workspace._id, userToken, user._id, selectedMagnet.form_name, selectedMagnet._id,
            testNameRef.current!.value, (commentsRef.current?.value !== null)?commentsRef.current!.value:"",
            selectedMagnet.steps, newABsteps, mainGoal, secGoal
        );
    }

    return(
        <div className="">
            <div className="flex flex-row gap-5 p-5 justify-start items-center w-full z-10 sticky shadow-md bg-brandLightGray">
                <div className="w-1/3 flex flex-row justify-start items-center gap-5">
                    <Link to="/sequences/info" className="flex flex-row justify-start items-center text-lg text-brandPurple cursor-pointer"
                    onClick={()=>{sessionStorage.clear();}}>
                        <MdArrowBackIosNew className="size-4"/>Cancel & Exit
                    </Link>
                </div>
                <div className="w-1/3 flex justify-center items-center">
                    <ul className="steps w-full">
                        <li className="step step-success cursor-pointer" onClick={()=>{setVariationCreated(false);}}>B-variation</li>
                        <li className={`step ${(variationCreated?"step-success":"")} cursor-pointer`}>Settings</li>
                    </ul>
                </div>
                <div className="w-1/3 flex justify-end">
                    {
                        (variationCreated)
                        ?<button className="btn font-light bg-brandPurple text-white hover:bg-brandLightPurple rounded-full"
                        disabled={
                            (testNameRef.current?.value === null || testNameRef.current?.value === "") ||
                            (mainGoal === null || mainGoal === undefined) || (secGoal === null || secGoal === undefined)
                        }
                        onClick={saveAndContinue}>
                            Start AB-test
                        </button>
                        :<button className="btn font-light bg-brandPurple text-white hover:bg-brandLightPurple rounded-full" disabled={changesMadeTo.length === 0}
                        onClick={()=>{setVariationCreated(true);}}>
                            Continue
                        </button>
                    }
                </div>
            </div>
            {
                (variationCreated)
                ?<div className="p-5 flex flex-wrap justify-start items-start">
                    <div className="w-1/2 p-5">
                        <p className="text-2xl mb-5">Finalize and launch your AB-test</p>
                        <p className="text-xl">Settings</p>
                        <div className="mt-5 mb-5">
                            <p>AB-test name</p>
                            <input type="text" ref={testNameRef} className="input input-bordered w-1/2" placeholder="new ab test"/>
                        </div>
                        <div className="mt-5 mb-5">
                            <p>Comments</p>
                            <input type="text" ref={commentsRef} className="input input-bordered w-1/2" placeholder="new ab test"/>
                        </div>
                        <div className="mt-5 mb-5">
                            <p>Main goal</p>
                            <select className="select select-bordered w-1/2" onChange={(e)=>{setMainGoal(e.target.value); console.log(e.target.value);}}>
                                <option disabled selected>Main goal</option>
                                <option key={0} value={JSON.stringify({step : 0, action : "start", name: "Start rate"})}>Start rate</option>
                                {newABsteps.map((step : Step, idx: number) => {
                                    return<option key={idx + 1} value={JSON.stringify({step : idx, action : "completion", name : `Step ${idx + 1}: ${step.action} completion`})}>
                                            {`Step ${idx + 1}: ${step.action} completion`}
                                        </option>
                                })}
                                <option key={newABsteps.length + 1} value={JSON.stringify({step : newABsteps.length, action : "completion", name: "Completion rate"})}>
                                    Completion rate
                                </option>
                            </select>
                        </div>
                        <div className="mt-5 mb-5">
                            <p>Secondary goal</p>
                            <select className="select select-bordered w-1/2" onChange={(e)=>{setSecGoal(e.target.value);}}>
                                <option disabled selected>Secondary goal</option>
                                <option key={0} value={JSON.stringify({step : 0, action : "start", name: "Start rate"})}>Start rate</option>
                                {newABsteps.map((step : Step, idx: number) => {
                                    return<option key={idx + 1} value={JSON.stringify({step : idx, action : "finish", name : `Step ${idx + 1}: ${step.action} completion`})}>
                                        {`Step ${idx + 1}: ${step.action} completion`}
                                        </option>
                                })}
                                <option key={newABsteps.length + 1} value={JSON.stringify({step : newABsteps.length, action : "finish", name : "Completion rate"})}>
                                    Completion rate
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <p className="pl-5 pt-5 text-xl">Preview of the B-variant:</p>
                        <FunnelPreview steps={newABsteps}/>
                    </div>
                </div>
                :<CreateVariation 
                newABsteps={newABsteps} setNewABsteps={setNewABsteps} 
                changesMadeTo={changesMadeTo} setChangesMadeTo={setChangesMadeTo}
                endScreenAdded={endScreenAdded} setEndScreenAdded={setEndScreenAdded}/>
            }
        </div>
    );
};

export default ABtest;