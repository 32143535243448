import React, { useState } from "react";
import { Step } from "../../Context";
import { FaPlus } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { ChromePicker } from "react-color";
import { GrTextAlignCenter, GrTextAlignLeft, GrTextAlignRight } from "react-icons/gr";

interface DeleteProps {
    steps : Step[],
    setSteps : Function
    deleteId : number,
    selectedStep : Step,
    selectedStepNumber : number,
}

const DeleteField = ( { steps, setSteps, deleteId, selectedStep, selectedStepNumber } : DeleteProps) : React.ReactElement => {
    return(
        <div className="dropdown dropdown-hover dropdown-bottom flex w-1/5 justify-end items-center">
            <p tabIndex={0} role="button" className="text-brandPurple cursor-pointer"><BsThreeDotsVertical /></p>
            <ul tabIndex={0} className="dropdown-content menu z-10 p-2 bg-white shadow-md rounded-lg w-fit">
                <p className="w-32 flex flex-row justify-start items-center gap-2 text-red cursor-pointer" onClick={()=>{
                    let inputs = JSON.parse(JSON.stringify(selectedStep.form!.inputFields));
                    const newInputs = inputs.filter((input : any) => input !== inputs[deleteId]);
                    console.log(newInputs);
                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                        if(idx === selectedStepNumber){
                            step.form!.inputFields = newInputs;
                            console.log(step);
                            return step
                        }else{
                            return step
                        }
                    })
                    setSteps(newSteps);
                }}>
                   <MdDeleteForever/> Delete field
                </p>
            </ul>
        </div>
    );
}

interface Props{
    selectedStep : Step,
    selectedStepNumber : number,
    steps : Step[],
    setSteps : Function,
}

export default function FormEditor ( { selectedStep, selectedStepNumber, steps, setSteps } : Props ) : React.ReactElement {
    const [availableFields, setAvailableFields] = useState<any[]>([
        {type :"email", name : "email", label : "email", viewValue : "Email"},
        {type :"text", name : "firstName", label : "first name", viewValue : "First name"},
        {type :"text", name : "lastName", label : "last name", viewValue : "Last name"},
        {type :"text", name : "company", label : "company", viewValue : "Company"},
        {type :"text", name : "phone", label : "phone", viewValue : "Phone"},
    ]);

    const updateTextValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.form!.header = (event.currentTarget.elements.namedItem("title") as HTMLInputElement).value
                step.form!.description = (event.currentTarget.elements.namedItem("desc") as HTMLInputElement).value
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateInputValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.form!.inputFields.map((input : any, id : number) => {
                    input.label = (event.currentTarget.elements.namedItem(input.name) as HTMLInputElement).value;
                    return input
                })
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const AddInputField = (input : any) => {
        let newInputs = JSON.parse(JSON.stringify(selectedStep.form!.inputFields));
        newInputs.push(input);

        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.form!.inputFields = newInputs;
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateFormStyleValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.form!.textScreenStyle.gap = Number((event.currentTarget.elements.namedItem("gap") as HTMLInputElement).value);
                step.form!.textScreenStyle.backgroundImage = (event.currentTarget.elements.namedItem("bg-img") as HTMLInputElement).value;
                step.form!.h1Style.fontSize = Number((event.currentTarget.elements.namedItem("title-size") as HTMLInputElement).value);
                step.form!.pStyle.fontSize = Number((event.currentTarget.elements.namedItem("desc-size") as HTMLInputElement).value);
                step.form!.inputStyle.width = Number((event.currentTarget.elements.namedItem("input-w") as HTMLInputElement).value);
                step.form!.inputStyle.fontSize = Number((event.currentTarget.elements.namedItem("input-size") as HTMLInputElement).value);
                step.form!.inputStyle.padding = Number((event.currentTarget.elements.namedItem("input-p") as HTMLInputElement).value);
                step.form!.inputStyle.borderRadius = Number((event.currentTarget.elements.namedItem("input-b") as HTMLInputElement).value);
                step.form!.ctaStyle.fontSize = Number((event.currentTarget.elements.namedItem("cta-size") as HTMLInputElement).value);
                step.form!.ctaStyle.padding = Number((event.currentTarget.elements.namedItem("cta-padding") as HTMLInputElement).value);
                step.form!.ctaStyle.borderRadius = Number((event.currentTarget.elements.namedItem("cta-border") as HTMLInputElement).value);
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    return(
         <div className="p-5 flex flex-col gap-5 h-full">
            <div className="w-full flex gap-2 flex-wrap">
                <form className="w-full flex flex-col flex-wrap gap-7" onChange={updateTextValues}>
                    <p className="text-lg">Form content</p>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">Title</label>
                        <input type="url" defaultValue={selectedStep.form!.header} name="title" className="w-full input input-bordered input-md text-sm"/>
                    </div>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">Description</label>
                        <input type="text" defaultValue={selectedStep.form!.description} name="desc" className="w-full input input-bordered input-md text-sm"/>
                    </div>
                </form>
                <div className="divider w-full"></div>
                <form className="flex flex-row justify-start items-center gap-7 flex-wrap w-full" onChange={updateInputValues}>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <p className="text-lg w-1/2">Input fields</p>
                        <div className="dropdown dropdown-hover drop-down-bottom w-1/2">
                            <p tabIndex={0} className="cursor-pointer flex flex-row justify-end items-center gap-2">
                                <FaPlus className="size-4 text-green"/> Add new field
                            </p>
                            <ul tabIndex={0} className="dropdown-content menu z-10 p-2 w-full bg-white shadow-md flex rounded-lg flex-col justify-start items-start gap-2">
                                {availableFields.map((field : any, idx : number) => {
                                    let found : boolean = false;
                                    selectedStep.form!.inputFields.forEach((input : any) => {
                                        if(field.name === input.name){
                                            found = true;
                                        }
                                    })

                                    if(!found){
                                        return(
                                            <li key={idx} className="cursor-pointer hover:bg-brandLightGray w-full p-3 rounded-lg"
                                            onClick={()=>{AddInputField(field);}}>{field.viewValue}</li>        
                                        );
                                    }else{
                                        return null
                                    }
                                    
                                })}
                                <li className="cursor-pointer hover:bg-brandLightGray w-full p-3 rounded-lg"
                                onClick={()=>{AddInputField({type :"text", name : "other" + selectedStep.form!.inputFields.length, label : "other", viewValue : "Other"});}}>Other</li>
                            </ul>
                        </div>
                    </div>
                    {selectedStep.form!.inputFields!.map((input : any, idx : number) => {
                        return(
                            <div key={idx} className="w-full">
                                <div className="flex flex-row justify-start items-center">
                                    <p className="w-4/5 text-sm">{input.viewValue} -field</p>
                                    <DeleteField steps={steps} setSteps={setSteps} deleteId={idx} selectedStep={selectedStep} selectedStepNumber={selectedStepNumber}/>
                                </div>
                                <input type={input.type} className="input input-bordered w-full input-md text-sm" value={input.label} name={input.name}/>
                            </div>
                        );
                    })}
                </form>
                <div className="divider w-full"></div>
                <form className="w-full flex flex-col flex-wrap gap-7">
                    <p className="text-lg">Form actions</p>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">CTA text</label>
                        <input type="url" defaultValue={selectedStep.form!.ctaText} name="cta-text" className="w-full input input-bordered input-md text-sm"
                        onChange={(event)=>{
                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                if(idx === selectedStepNumber){
                                    step.form!.ctaText = event.currentTarget.value
                                    return step
                                }else{
                                    return step
                                }
                            })
                            setSteps(newSteps);
                        }}/>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-5">
                        <p className="text-sm">Email notification</p>
                        <input type="checkbox" checked={selectedStep.form!.emailNotification}
                        className="toggle toggle-sm checked:bg-green checked:border-green bg-red border-red hover:bg-red hover:checked:bg-green disabled:bg-red" 
                        onClick={()=>{
                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                if(idx === selectedStepNumber){
                                    step.form!.emailNotification = !step.form!.emailNotification
                                    return step
                                }else{
                                    return step
                                }
                            })
                            setSteps(newSteps);
                        }}/>
                    </div>
                    <div className={`flex flex-col justify-start items-center flex-wrap gap-2 w-full ${(selectedStep.form!.emailNotification)?"":"hidden"}`}>
                        <label className="w-full text-sm">Email address</label>
                        <input type="email" defaultValue={selectedStep.form!.emailResults} name="emailResults" className="w-full input input-bordered input-md text-sm"
                        onChange={(event) => {
                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                if(idx === selectedStepNumber){
                                    step.form!.emailResults = event.currentTarget.value
                                    return step
                                }else{
                                    return step
                                }
                            })
                            setSteps(newSteps);
                        }}/>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-5">
                        <p className="text-sm">Redirect after submission</p>
                        <input type="checkbox" checked={selectedStep.form!.redirect}
                        className="toggle toggle-sm checked:bg-green checked:border-green bg-red border-red hover:bg-red hover:checked:bg-green disabled:bg-red" 
                        onClick={()=>{
                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                if(idx === selectedStepNumber){
                                    step.form!.redirect = !step.form!.redirect
                                    return step
                                }else{
                                    return step
                                }
                            })
                            setSteps(newSteps);
                        }}/>
                    </div>
                    <div className={`flex flex-col justify-start items-center flex-wrap gap-2 w-full ${(selectedStep.form!.redirect)?"":"hidden"}`}>
                        <label className="w-full text-sm">Redirect URL</label>
                        <input type="url" defaultValue={selectedStep.form!.redirectUrl} placeholder="https://cxtailor.com" name="redirect-url" 
                        className="w-full input input-bordered input-md text-sm"
                        onChange={(event)=>{
                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                if(idx === selectedStepNumber){
                                    step.form!.redirectUrl = event.currentTarget.value
                                    return step
                                }else{
                                    return step
                                }
                            })
                            setSteps(newSteps);
                        }}/>
                    </div>
                </form>
                <div className="divider w-full"></div>
                <form className="w-full flex flex-col flex-wrap gap-7" onChange={updateFormStyleValues}>
                    <p className="text-lg">Styling</p>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Background color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`rgba(
                                ${selectedStep.form!.textScreenStyle.backgroundColor.r},
                                ${selectedStep.form!.textScreenStyle.backgroundColor.g},
                                ${selectedStep.form!.textScreenStyle.backgroundColor.b},
                                ${selectedStep.form!.textScreenStyle.backgroundColor.a}
                                )`}>
                                <div tabIndex={0} className="w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray" 
                                style={{backgroundColor : 
                                `rgba(
                                ${selectedStep.form!.textScreenStyle.backgroundColor.r},
                                ${selectedStep.form!.textScreenStyle.backgroundColor.g},
                                ${selectedStep.form!.textScreenStyle.backgroundColor.b},
                                ${selectedStep.form!.textScreenStyle.backgroundColor.a}
                                )`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.form!.textScreenStyle.backgroundColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.form!.textScreenStyle.backgroundColor = event.rgb;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">Background image URL</label>
                        <input type="url" defaultValue={selectedStep.form!.textScreenStyle.backgroundImage} name="bg-img" className="w-full input input-bordered input-md text-sm"/>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-1/2 text-sm">Text align</label>
                        <div className="join w-1/2 flex justify-end items-center">
                            <button type="button" className={`join-item btn text-lg btn-sm
                                ${(selectedStep.form!.textScreenStyle.textAlign === "start")
                                ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                :""}`} onClick={()=>{
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.form!.textScreenStyle.textAlign = "start";
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}>
                                <GrTextAlignLeft />
                            </button>
                            <button type="button" className={`join-item btn text-lg btn-sm
                                ${(selectedStep.form!.textScreenStyle.textAlign === "center")
                                ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                :""}`} onClick={()=>{
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.form!.textScreenStyle.textAlign = "center";
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}>
                                <GrTextAlignCenter />
                            </button>
                            <button type="button" className={`join-item btn text-lg btn-sm
                                ${(selectedStep.form!.textScreenStyle.textAlign === "end")
                                ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                :""}`} onClick={()=>{
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.form!.textScreenStyle.textAlign = "end";
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}>
                                <GrTextAlignRight />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Element spacing</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.form!.textScreenStyle.gap} name="gap" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Title size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.form!.h1Style.fontSize} name="title-size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Title color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.form!.h1Style.color}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.form!.h1Style.color}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.form!.h1Style.color} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.form!.h1Style.color = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Description size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.form!.pStyle.fontSize} name="desc-size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Description color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.form!.pStyle.color}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.form!.pStyle.color}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.form!.pStyle.color} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.form!.pStyle.color = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Input width</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={1} defaultValue={selectedStep.form!.inputStyle.width} name="input-w" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">%</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Input padding</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.form!.inputStyle.padding} name="input-p" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Input border radius</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={1} defaultValue={selectedStep.form!.inputStyle.borderRadius} name="input-b" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">px</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Input / Label size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.form!.inputStyle.fontSize} name="input-size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Input / Label color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.form!.inputStyle.color}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.form!.inputStyle.color}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.form!.inputStyle.color} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.form!.inputStyle.color = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Input background color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.form!.inputStyle.backgroundColor}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.form!.inputStyle.backgroundColor}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.form!.inputStyle.backgroundColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.form!.inputStyle.backgroundColor = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">CTA font size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.form!.ctaStyle.fontSize} name="cta-size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">CTA font color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.form!.ctaStyle.color}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.form!.ctaStyle.color}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.form!.ctaStyle.color} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.form!.ctaStyle.color = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">CTA background color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.form!.ctaStyle.backgroundColor}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.form!.ctaStyle.backgroundColor}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.form!.ctaStyle.backgroundColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.form!.ctaStyle.backgroundColor = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">CTA padding</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.25} defaultValue={selectedStep.form!.ctaStyle.padding} name="cta-padding" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">CTA border radius</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} defaultValue={selectedStep.form!.ctaStyle.borderRadius} name="cta-border" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">px</span>
                        </label>
                    </div>
                </form>
            </div>
        </div>
    );
}