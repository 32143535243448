import React from "react";
import { Step } from "../../Context";
import { ChromePicker } from "react-color";
import { GrTextAlignCenter, GrTextAlignLeft, GrTextAlignRight } from "react-icons/gr";

interface Props{
    selectedStep : Step,
    selectedStepNumber : number,
    steps : Step[],
    setSteps : Function,
}

export default function TextScreenEditor( { selectedStep, selectedStepNumber, steps, setSteps } : Props ) : React.ReactElement {

    const updateTextValues = (event : React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                if(idx === selectedStepNumber){
                    step.textScreen!.h1 = (event.currentTarget.elements.namedItem("title") as HTMLInputElement).value
                    step.textScreen!.description = (event.currentTarget.elements.namedItem("desc") as HTMLInputElement).value
                    step.textScreen!.ctaText = (event.currentTarget.elements.namedItem("cta-text") as HTMLInputElement).value
                    return step
                }else{
                    return step
                }
            })
            setSteps(newSteps);
        }
    
    const updateFormStyleValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.textScreen!.textScreenStyle.gap = Number((event.currentTarget.elements.namedItem("gap") as HTMLInputElement).value);
                step.textScreen!.textScreenStyle.backgroundImage = (event.currentTarget.elements.namedItem("bg-img") as HTMLInputElement).value;
                step.textScreen!.h1Style.fontSize = Number((event.currentTarget.elements.namedItem("title-size") as HTMLInputElement).value);
                step.textScreen!.pStyle.fontSize = Number((event.currentTarget.elements.namedItem("desc-size") as HTMLInputElement).value);
                step.textScreen!.ctaStyle.fontSize = Number((event.currentTarget.elements.namedItem("cta-size") as HTMLInputElement).value);
                step.textScreen!.ctaStyle.padding = Number((event.currentTarget.elements.namedItem("cta-padding") as HTMLInputElement).value);
                step.textScreen!.ctaStyle.borderRadius = Number((event.currentTarget.elements.namedItem("cta-border") as HTMLInputElement).value);
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }
    
    return(
            <div className="p-5 flex flex-col gap-5 h-full">
            <div className="w-full flex gap-2 flex-wrap">
                <form className="w-full flex flex-col flex-wrap gap-7" onChange={updateTextValues}>
                    <p className="text-lg">Form content</p>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">Title</label>
                        <input type="url" defaultValue={selectedStep.textScreen!.h1} name="title" className="w-full input input-bordered text-sm input-md"/>
                    </div>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">Description</label>
                        <input type="text" defaultValue={selectedStep.textScreen!.description} name="desc" className="w-full input input-bordered text-sm input-md"/>
                    </div>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">CTA text</label>
                        <input type="url" defaultValue={selectedStep.textScreen!.ctaText} name="cta-text" className="w-full input input-bordered text-sm input-md"/>
                    </div>
                </form>
                <div className="divider w-full"></div>
                <form className="w-full flex flex-col flex-wrap gap-7" onChange={updateFormStyleValues}>
                    <p className="text-lg">Styling</p>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Background color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`rgba(
                                ${selectedStep.textScreen!.textScreenStyle.backgroundColor.r},
                                ${selectedStep.textScreen!.textScreenStyle.backgroundColor.g},
                                ${selectedStep.textScreen!.textScreenStyle.backgroundColor.b},
                                ${selectedStep.textScreen!.textScreenStyle.backgroundColor.a}
                                )`}>
                                <div tabIndex={0} className="w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray" 
                                style={{backgroundColor : 
                                `rgba(
                                ${selectedStep.textScreen!.textScreenStyle.backgroundColor.r},
                                ${selectedStep.textScreen!.textScreenStyle.backgroundColor.g},
                                ${selectedStep.textScreen!.textScreenStyle.backgroundColor.b},
                                ${selectedStep.textScreen!.textScreenStyle.backgroundColor.a}
                                )`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.textScreen!.textScreenStyle.backgroundColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.textScreen!.textScreenStyle.backgroundColor = event.rgb;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">Background image URL</label>
                        <input type="url" defaultValue={selectedStep.textScreen!.textScreenStyle.backgroundImage} name="bg-img" 
                        className="w-full input input-bordered text-sm input-md"/>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-1/2 text-sm">Text align</label>
                        <div className="join w-1/2 flex justify-end items-center">
                            <button type="button" className={`join-item btn text-lg btn-sm
                                ${(selectedStep.textScreen!.textScreenStyle.textAlign === "start")
                                ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                :""}`} onClick={()=>{
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.textScreen!.textScreenStyle.textAlign = "start";
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}>
                                <GrTextAlignLeft />
                            </button>
                            <button type="button" className={`join-item btn text-lg btn-sm
                                ${(selectedStep.textScreen!.textScreenStyle.textAlign === "center")
                                ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                :""}`} onClick={()=>{
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.textScreen!.textScreenStyle.textAlign = "center";
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}>
                                <GrTextAlignCenter />
                            </button>
                            <button type="button" className={`join-item btn text-lg btn-sm
                                ${(selectedStep.textScreen!.textScreenStyle.textAlign === "end")
                                ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                :""}`} onClick={()=>{
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.textScreen!.textScreenStyle.textAlign = "end";
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}>
                                <GrTextAlignRight />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Element spacing</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.textScreen!.textScreenStyle.gap} name="gap" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Title size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.textScreen!.h1Style.fontSize} name="title-size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Title color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.textScreen!.h1Style.color}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.textScreen!.h1Style.color}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.textScreen!.h1Style.color} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.textScreen!.h1Style.color = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 tex-sm">Description size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.textScreen!.pStyle.fontSize} name="desc-size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Description color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.textScreen!.pStyle.color}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.textScreen!.pStyle.color}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.textScreen!.pStyle.color} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.textScreen!.pStyle.color = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">CTA font size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                            <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.textScreen!.ctaStyle.fontSize} name="cta-size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">CTA font color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.textScreen!.ctaStyle.color}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.textScreen!.ctaStyle.color}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.textScreen!.ctaStyle.color} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.textScreen!.ctaStyle.color = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">CTA background color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.textScreen!.ctaStyle.backgroundColor}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.textScreen!.ctaStyle.backgroundColor}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.textScreen!.ctaStyle.backgroundColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.textScreen!.ctaStyle.backgroundColor = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">CTA padding</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                            <input type="number" min={0} max={100} step={0.25} defaultValue={selectedStep.textScreen!.ctaStyle.padding} name="cta-padding" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">CTA border radius</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                            <input type="number" min={0} max={100} defaultValue={selectedStep.textScreen!.ctaStyle.borderRadius} name="cta-border" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">px</span>
                        </label>
                    </div>
                </form>
            </div>
        </div>
    );
}