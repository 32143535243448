import React, { useEffect, useRef, useState } from "react";
import { GrTextAlignCenter } from "react-icons/gr";
import { GrTextAlignLeft } from "react-icons/gr";
import { GrTextAlignRight } from "react-icons/gr";
import { FormInterface } from "../../../Context";

interface Props {
    idx : number,
    form : FormInterface,
    setForm : Function
}

const FormStyle = ({idx, form, setForm}:Props) : React.ReactElement => {
    const screenPRef = useRef<HTMLInputElement>(null);
    const bgColorRef = useRef<HTMLInputElement>(null);
    const [align, setAlign] = useState<string>("left");

    const titleSRef = useRef<HTMLInputElement>(null);
    const titleCRef = useRef<HTMLInputElement>(null);

    const descSRef = useRef<HTMLInputElement>(null);
    const descCRef = useRef<HTMLInputElement>(null);

    const labelSRef = useRef<HTMLInputElement>(null);
    const labelCRef = useRef<HTMLInputElement>(null);

    const inpWRef = useRef<HTMLInputElement>(null);
    const inpPRef = useRef<HTMLInputElement>(null);
    const inpSRef = useRef<HTMLInputElement>(null);
    const inpBRef = useRef<HTMLInputElement>(null);

    const ctaSRef = useRef<HTMLInputElement>(null);
    const ctaPRef = useRef<HTMLInputElement>(null);
    const ctaBRef = useRef<HTMLInputElement>(null);
    const ctaFCRef = useRef<HTMLInputElement>(null);
    const ctaBCRef = useRef<HTMLInputElement>(null);

    const [formDraft, setFormDraft] = useState<FormInterface>(form);

    const updateChanges = (alignStr ? : string) => {
        const newForm : FormInterface = {
            header : form.header,
            description : form.description,
            inputFields : form.inputFields,
            ctaText : form.ctaText,
            textScreenStyle : {textAlign : (alignStr)?alignStr:align, padding : screenPRef.current?.value, backgroundColor : bgColorRef.current?.value},
            h1Style : {fontSize : titleSRef.current?.value, color : titleCRef.current?.value},
            pStyle : {fontSize : descSRef.current?.value, color : descCRef.current?.value},
            labelStyle : {fontSize : labelSRef.current?.value, color : labelCRef.current?.value},
            inputStyle : {width : inpWRef.current?.value, padding : inpPRef.current?.value, fontSize : inpSRef.current?.value, borderRadius : inpBRef.current?.value},
            ctaStyle : {fontSize : ctaSRef.current?.value, 
                color : ctaFCRef.current?.value, backgroundColor : ctaBCRef.current?.value, 
                padding : ctaPRef.current?.value, borderRadius : ctaBRef.current?.value
            }
        }
        setForm(newForm);
        setFormDraft(newForm);
        sessionStorage.setItem(`form${idx}`, JSON.stringify(form));
    }

    useEffect(()=>{
        const sessionForm = sessionStorage.getItem(`form${idx}`);
        if(sessionForm){
            setFormDraft(JSON.parse(sessionForm));
        }
    }, []);

    return(
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                <div className="flex flex-wrap gap-5 p-2 mt-5">
                    <form onChange={()=>{updateChanges()}} className="w-full p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Form Screen Styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-1/4">
                                <p className="mb-2">Text align</p>
                                <div className="flex flex-row gap-2">
                                    <p className={`${(align === "left")?"text-green":"text-black"}`} onClick={()=>{setAlign("left"); updateChanges("left")}}>
                                        <GrTextAlignLeft className="xl:size-8 lg:size-6"/>
                                    </p>
                                    <p className={`${(align === "center")?"text-green":"text-black"}`} onClick={()=>{setAlign("center"); updateChanges("center")}}>
                                        <GrTextAlignCenter className="xl::size-8 lg:size-6"/>
                                    </p>
                                    <p className={`${(align === "right")?"text-green":"text-black"}`} onClick={()=>{setAlign("right"); updateChanges("right")}}>
                                        <GrTextAlignRight className="xl:size-8 lg:size-6"/>
                                    </p>
                                </div>
                            </div>
                            <div className="w-2/4">
                                <p>Padding</p>
                                <input type="number" ref={screenPRef} defaultValue={formDraft.textScreenStyle.padding} placeholder="0px"
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/3">
                                <p>BG color</p>
                                <input type="color" ref={bgColorRef} value={formDraft.textScreenStyle.color}
                                className="w-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(33%-0.7rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Header</p>
                        <div className="w-full">
                            <p>Font size</p>
                            <input type="number" placeholder="24px" ref={titleSRef} defaultValue={formDraft.h1Style.fontSize}
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Color</p>
                            <input type="color" ref={titleCRef} value={formDraft.h1Style.color}
                            className="w-full"/>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(33%-0.7rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Description</p>
                        <div className="w-full">
                            <p>Font size</p>
                            <input type="number" placeholder="18px" ref={descSRef} defaultValue={formDraft.pStyle.fontSize}
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Color</p>
                            <input type="color" ref={descCRef} value={formDraft.pStyle.color}
                            className="w-full"/>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(33%-0.7rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Label</p>
                        <div className="w-full">
                            <p>Font size</p>
                            <input type="number" placeholder="18px" ref={labelSRef} defaultValue={formDraft.labelStyle.fontSize}
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Color</p>
                            <input type="color" ref={labelCRef} value={formDraft.labelStyle.color}
                            className="w-full"/>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-full p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Input Styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-1/4">
                                <p>Font size</p>
                                <input type="number" placeholder="18px" ref={inpSRef} defaultValue={formDraft.inputStyle.fontSize}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/4">
                                <p>Padding</p>
                                <input type="number" placeholder="5px" ref={inpPRef} defaultValue={formDraft.inputStyle.padding}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/4">
                                <p>Border radius</p>
                                <input type="number" placeholder="10px" ref={inpBRef} defaultValue={formDraft.inputStyle.borderRadius}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/4">
                                <p>Width</p>
                                <input type="number" max={100} min={0} placeholder="100%" ref={inpWRef} defaultValue={formDraft.inputStyle.width}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-full p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">CTA Styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-1/5">
                                <p>Font size</p>
                                <input type="number" placeholder="18px" ref={ctaSRef} defaultValue={formDraft.ctaStyle.fontSize}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Padding</p>
                                <input type="number" placeholder="5px" ref={ctaPRef} defaultValue={formDraft.ctaStyle.padding}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Border radius</p>
                                <input type="number" placeholder="10px" ref={ctaBRef} defaultValue={formDraft.ctaStyle.borderRadius}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Color</p>
                                <input type="color" ref={ctaFCRef} value={formDraft.ctaStyle.color} className="w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>BG color</p>
                                <input type="color" ref={ctaBCRef} value={formDraft.ctaStyle.backgroundColor} className="w-full"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="w-1/3 mt-5 p-2">
                <div className="p-5 border-2 border-brandLightGray rounded-lg">
                    <p className="mb-2">Funnel Step Preview:</p>
                    <div className={`text-${formDraft.textScreenStyle.textAlign} flex flex-col gap-3`} style={{padding : `${formDraft.textScreenStyle.padding}px`, 
                        backgroundColor : `${formDraft.textScreenStyle.backgroundColor}`}}>
                        {(formDraft.header)?
                        <p style={{fontSize : `${formDraft.h1Style.fontSize}px`, color : `${formDraft.h1Style.color}`}}>
                            {formDraft.header}
                        </p>
                        :null}
                        {(formDraft.description)?
                        <p style={{fontSize : `${formDraft.pStyle.fontSize}px`, color : `${formDraft.pStyle.color}`}}>
                            {formDraft.description}
                        </p>
                        :null}
                        <form className="">
                            {
                            (formDraft.inputFields && formDraft.inputFields.length > 0)
                            ?<>{formDraft.inputFields.map((input : any, idx : number) => {
                                return<div key={idx}>
                                        <label htmlFor={input.name}  style={{fontSize : `${formDraft.labelStyle.fontSize}px`, color : `${formDraft.labelStyle.color}`}}>
                                            {input.label}
                                        </label><br/>
                                        <input readOnly type={input.type} name={input.type} className="border-[1px]"
                                        style={{width :`${formDraft.inputStyle.width}%`, padding : `${formDraft.inputStyle.padding}px`,
                                        fontSize : `${formDraft.inputStyle.fontSize}px`, borderRadius: `${formDraft.inputStyle.borderRadius}px`}}/>
                                    </div>
                            })}</>
                            :null
                            }
                            <button className="mt-2"
                            style={{fontSize : `${formDraft.ctaStyle.fontSize}px`, padding :  `${formDraft.ctaStyle.padding}px`, borderRadius : `${formDraft.ctaStyle.borderRadius}px`,
                            color :  `${formDraft.ctaStyle.color}`, backgroundColor :  `${formDraft.ctaStyle.backgroundColor}`}}>
                                {(formDraft.ctaText)?formDraft.ctaText:"Submit form"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormStyle