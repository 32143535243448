import React, { useContext } from "react";
import { Context, StepData } from "../../Context";
import StepResultCard from "./StepResultCard";

const FunnelSteps = () : React.ReactElement => {
    const { loadDone, magnetData, selectedMagnet } = useContext(Context);
    
    return(
        <div className="p-5 2xl:w-2/3">
            <p className="text-2xl">Step Insights of Your Lead Funnel &#128064;</p>
            {
                (loadDone && magnetData && selectedMagnet)
                ?<div>
                    {
                        magnetData.steps14Days.map((step : StepData, idx : number) => {
                            
                            if(selectedMagnet.steps[idx].action === "video"){
                                return<div key={idx} className="bg-brandLightGray p-5 mt-5 mb-5 flex lg:flex-row max-lg:flex-col rounded-xl gap-3 justify-start items-center">
                                    <p className="text-xl font-bold lg:w-1/5">Step {idx}: <span className="font-normal">{selectedMagnet.steps[idx].action}</span></p>
                                    <div className="flex lg:flex-row max-lg:flex-col xl:w-4/5 max-xl:w-full gap-3">
                                        {
                                            (idx === 0)

                                            ?<StepResultCard title="Video Starts" 
                                            color={`${(step.view && (step.start / step.view) * 100 > 20)?"bg-green":(step.view && (step.start / step.view) * 100 > 10)?"bg-yellow":"bg-red"}`} 
                                            textColor={`${(step.view && (step.start / step.view) * 100 > 20)?"text-white":(step.view && (step.start / step.view) * 100 > 10)?"text-black":"text-white"}`}
                                            result={(step.start) ? step.start : 0} percentage={false} 
                                            comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].start : 0} loadign={false}/>

                                            :<StepResultCard title="Video Starts" 
                                            color={`${(step.start && step.start > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                            result={(step.start) ? step.start : 0} percentage={false} 
                                            comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].start : 0} loadign={false}/>
                                        }

                                        <StepResultCard title="Drop Off point" 
                                        color={`bg-brandPurple`} textColor="text-white" comparisonText="Bounced"
                                        result={(step.pauseCount) ? step.pauseTotal / step.pauseCount : 0} percentage={true} 
                                        comparison={(step.pauseCount) ?step.pauseCount : 0} loadign={false}/>

                                        <StepResultCard title="Video Finishes" 
                                        color={`${(step.finish && (step.finish / step.start) * 100 > 50)?"bg-green":((step.finish / step.start) * 100 > 25)?"bg-yellow":"bg-red"}`} 
                                        textColor={`${(step.finish && (step.finish / step.start) * 100 > 50)?"text-white":((step.finish / step.start) * 100 > 25)?"text-black":"text-white"}`}
                                        result={(step.finish) ? step.finish : 0} percentage={false} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish : 0} loadign={false}/>

                                        <StepResultCard title="Completion Rate" 
                                        color={`${(step.finish && (step.finish / step.start) * 100 > 50)?"bg-green":((step.finish / step.start) * 100 > 25)?"bg-yellow":"bg-red"}`} 
                                        textColor={`${(step.finish && (step.finish / step.start) * 100 > 50)?"text-white":((step.finish / step.start) * 100 > 25)?"text-black":"text-white"}`}
                                        result={(step.finish) ? (step.finish / step.start)*100 : 0} percentage={true} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish / magnetData.stepsComparison[idx].start * 100 : 0} loadign={false}/>
                                    </div>
                                 </div>
                            }
                            if(selectedMagnet.steps[idx].action === "form"){
                                return<div key={idx} className="bg-brandLightGray p-5 mt-5 mb-5 flex lg:flex-row max-lg:flex-col rounded-xl gap-3 justify-start items-center">
                                    <p className="text-xl font-bold lg:w-1/5 gap-3">Step {idx}: <span className="font-normal">{selectedMagnet.steps[idx].action}</span></p>
                                    <div className="flex lg:flex-row max-lg:flex-col xl:w-4/5 max-xl:w-full gap-3">
                                        <StepResultCard title="Form Views" 
                                        color={`${(step.start && step.start > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                        result={(step.start) ? step.start : 0} percentage={false} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].start : 0} loadign={false}/>

                                        <StepResultCard title="Leads Collected" 
                                        color={`${(step.finish && step.finish > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                        result={(step.finish) ? step.finish : 0} percentage={false} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish : 0} loadign={false}/>

                                        <StepResultCard title="Conversion Rate" 
                                        color={`${(step.finish && (step.finish / step.start * 100) > 20)?"bg-green":((step.finish / step.start * 100) > 10)?"bg-yellow":"bg-red"}`} 
                                        textColor={`${(step.finish && (step.finish / step.start * 100) > 20)?"text-white":((step.finish / step.start * 100) > 10)?"text-black":"text-white"}`}
                                        result={(step.finish) ? (step.finish / step.start) * 100 : 0} percentage={true} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish / magnetData.stepsComparison[idx].start * 100 : 0} loadign={false}/>
                                    </div>
                                 </div>
                            }
                            if(selectedMagnet.steps[idx].action === "textScreen"){
                                return<div key={idx} className="bg-brandLightGray p-5 mt-5 mb-5 flex lg:flex-row max-lg:flex-col rounded-xl gap-3 justify-start items-center">
                                    <p className="text-xl font-bold lg:w-1/5 gap-3">Step {idx}: <span className="font-normal">{selectedMagnet.steps[idx].action}</span></p>
                                    <div className="flex lg:flex-row max-lg:flex-col xl:w-4/5 max-xl:w-full gap-3">
                                        <StepResultCard title="Views" 
                                        color={`${(step.start && step.start > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                        result={(step.start) ? step.start : 0} percentage={false} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].start : 0} loadign={false}/>

                                        <StepResultCard title="Continues" 
                                        color={`${(step.finish && step.finish > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                        result={(step.finish) ? step.finish : 0} percentage={false} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish : 0} loadign={false}/>

                                        <StepResultCard title="Conversion Rate" 
                                        color={`${(step.finish && (step.finish / step.start * 100) > 20)?"bg-green":((step.finish / step.start * 100) > 10)?"bg-yellow":"bg-red"}`} 
                                        textColor={`${(step.finish && (step.finish / step.start * 100) > 20)?"text-white":((step.finish / step.start * 100) > 10)?"text-black":"text-white"}`}
                                        result={(step.finish) ? (step.finish / step.start) * 100 : 0} percentage={true} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish / magnetData.stepsComparison[idx].start * 100 : 0} loadign={false}/>
                                    </div>
                                 </div>
                            }
                            if(selectedMagnet.steps[idx].action === "tailored"){
                                return<div>
                                            <div key={idx} className="bg-brandLightGray p-5 mt-5 mb-5 flex lg:flex-row max-lg:flex-col rounded-xl gap-3 justify-start items-center">
                                                <p className="text-xl font-bold lg:w-1/5 gap-3">Step {idx}: <span className="font-normal">{selectedMagnet.steps[idx].action}</span></p>
                                                <div className="flex lg:flex-row max-lg:flex-col xl:w-4/5 max-xl:w-full gap-3">
                                                    <StepResultCard title="Views" 
                                                    color={`${(step.start && step.start > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                                    result={(step.start) ? step.start : 0} percentage={false} 
                                                    comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].start : 0} loadign={false}/>

                                                    <StepResultCard title="Finishes" 
                                                    color={`${(step.finish && step.finish > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                                    result={(step.finish) ? step.finish : 0} percentage={false} 
                                                    comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish : 0} loadign={false}/>

                                                    <StepResultCard title="Conversion Rate" 
                                                    color={`${(step.finish && (step.finish / step.start * 100) > 20)?"bg-green":((step.finish / step.start * 100) > 10)?"bg-yellow":"bg-red"}`} 
                                                    textColor={`${(step.finish && (step.finish / step.start * 100) > 20)?"text-white":((step.finish / step.start * 100) > 10)?"text-black":"text-white"}`}
                                                    result={(step.finish) ? (step.finish / step.start) * 100 : 0} percentage={true} 
                                                    comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish / magnetData.stepsComparison[idx].start * 100 : 0} loadign={false}/>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-2 ml-20 mt-2 justify-end items-center w-full">
                                                {step.subStep?.map((subStep : StepData, i : number) => {
                                                    return <div key={idx} className="bg-brandLightGray p-5 mt-5 mb-5 flex flex-col rounded-xl gap-3 w-4/5">
                                                                <p className="text-xl font-bold text-left gap-3">
                                                                    Video {idx}: <span className="font-normal">{selectedMagnet.steps[idx].tailored.questions[i].question}</span>
                                                                </p>
                                                                <div className="flex lg:flex-row max-lg:flex-col w-full justify-end items-center gap-3">
                                                                    <StepResultCard title="Video Starts" 
                                                                    color={`${(subStep.start && subStep.start > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                                                    result={(subStep.start) ? subStep.start : 0} percentage={false} 
                                                                    comparison={(magnetData.stepsComparison[idx].subStep[i]) ? magnetData.stepsComparison[idx].subStep[i].start : 0} loadign={false}/>
                
                                                                    <StepResultCard title="Video Finishes" 
                                                                    color={`${(subStep.finish && subStep.finish > 0)?"bg-green":"bg-red"}`} textColor="text-white"
                                                                    result={(subStep.finish) ? subStep.finish : 0} percentage={false} 
                                                                    comparison={(magnetData.stepsComparison[idx].subStep[i]) ? magnetData.stepsComparison[idx].subStep[i].finish : 0} loadign={false}/>
                
                                                                    <StepResultCard title="Conversion Rate" 
                                                                    color={`${(subStep.finish && (subStep.finish / step.start * 100) > 20)?"bg-green":((subStep.finish / subStep.start * 100) > 10)?"bg-yellow":"bg-red"}`} 
                                                                    textColor={`${(subStep.finish && (subStep.finish / subStep.start * 100) > 20)?"text-white":((subStep.finish / subStep.start * 100) > 10)?"text-black":"text-white"}`}
                                                                    result={(subStep.finish) ? (subStep.finish / subStep.start) * 100 : 0} percentage={true} 
                                                                    comparison={(magnetData.stepsComparison[idx].subStep[i]) ? magnetData.stepsComparison[idx].subStep[i].finish / magnetData.stepsComparison[idx].subStep[i].start * 100 : 0} loadign={false}/>
                                                                </div>
                                                            </div>
                                                })}
                                            </div>
                                        </div>
                            }
                            if(selectedMagnet.steps[idx].action === "end screen"){
                                return<div key={idx} className="bg-brandLightGray p-5 mt-5 mb-5 flex lg:flex-row max-lg:flex-col rounded-xl gap-3 justify-start items-center">
                                    <p className="text-xl font-bold lg:w-1/5 gap-3">Step {idx}: <span className="font-normal">{selectedMagnet.steps[idx].action}</span></p>
                                    <div className="flex lg:flex-row max-lg:flex-col xl:w-4/5 max-xl:w-full gap-3">
                                        <StepResultCard title="End Views" 
                                        color={`${(step.start && (step.start / magnetData.steps14Days[idx - 1].finish) * 100 > 50)?"bg-green":"bg-red"}`} 
                                        textColor="text-white"
                                        result={(step.start) ? step.start : 0} percentage={false} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].start : 0} loadign={false}/>

                                        <StepResultCard title="CTA Clicks" 
                                        color={`${(step.finish && (step.finish / step.start) * 100 > 50)?"bg-green":((step.finish / step.start) * 100 > 25)?"bg-yellow":"bg-red"}`} 
                                        textColor={`${(step.finish && (step.finish / step.start) * 100 > 50)?"text-white":((step.finish / step.start) * 100 > 25)?"text-black":"text-white"}`}
                                        result={(step.finish) ? step.finish : 0} percentage={false} 
                                        comparison={(magnetData.stepsComparison[idx]) ? magnetData.stepsComparison[idx].finish : 0} loadign={false}/>

                                        <StepResultCard title="Completion Rate" 
                                        color={`${(step.finish && (step.finish / step.start) * 100 > 50)?"bg-green":((step.finish / step.start) * 100 > 25)?"bg-yellow":"bg-red"}`} 
                                        textColor={`${(step.finish && (step.finish / step.start) * 100 > 50)?"text-white":((step.finish / step.start) * 100 > 25)?"text-black":"text-white"}`}
                                        result={(step.finish) ? step.finish / step.start * 100 : 0} percentage={true} 
                                        comparison={(magnetData.stepsComparison[idx]) ? (magnetData.stepsComparison[idx].finish / magnetData.stepsComparison[idx].start) * 100 : 0} loadign={false}/>
                                    </div>
                                 </div>
                            }

                            return <></>

                        })
                    }
                </div>
                :<div>Loading...</div>
            }
        </div>
    );
};

export default FunnelSteps;