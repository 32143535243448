import React, { useEffect, useRef, useState } from "react";
import { VideoInterface } from "../../../Context";


interface ContentProps {
    video : VideoInterface,
    setVideo : Function,
    idx : number,
    saveToStorage : boolean,
    setGeneralPreview : Function,
}

const VideoContent = ({ video, setVideo, idx, saveToStorage, setGeneralPreview}:ContentProps) : React.ReactElement => {
    //Video content variables
    const urlRef = useRef<HTMLInputElement>(null);
    const posterRef = useRef<HTMLInputElement>(null);
    const showTunedRef = useRef<HTMLInputElement>(null);
    const timeLeftRef = useRef<HTMLInputElement>(null);
    const timeTextRef = useRef<HTMLInputElement>(null);
    const CTAscreenRef = useRef<HTMLInputElement>(null);
    const titleRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const ctaRef = useRef<HTMLInputElement>(null);

    const [videoDraft, setVideoDraft] = useState<VideoInterface>(video);

    //Video preview variables
    const videoRef = useRef<HTMLVideoElement>(null);
    const [preview, setPreview] = useState({CTAscreen : video.CTAscreen, timeLeft : false , timeText : 0});

    const updateChanges = () => {
        const newVideo : VideoInterface = {
            url : (urlRef.current?.value) ? urlRef.current.value : video.url,
            poster : (posterRef.current?.value) ? posterRef.current.value : video.poster,
            showTimeLeft : (showTunedRef.current?.checked) ? Boolean(showTunedRef.current!.checked) : video.showTimeLeft,
            timeLeftPersentage : (timeLeftRef.current?.value) ? Number(timeLeftRef.current!.value) : video.timeLeftPersentage,
            timeLeftText : (timeTextRef.current?.value) ? timeTextRef.current.value : video.timeLeftText,
            CTAscreen : (CTAscreenRef.current?.checked === true || CTAscreenRef.current?.checked === false) ? Boolean(CTAscreenRef.current!.checked) : video.CTAscreen,
            title : (titleRef.current?.value) ? titleRef.current.value : video.title,
            description : (descriptionRef.current?.value) ? descriptionRef.current.value : video.description,
            ctaText : (ctaRef.current?.value) ? ctaRef.current.value : video.ctaText,
            videoStyle : video.videoStyle,
            textScreenStyle : video.textScreenStyle,
            h1Style : video.h1Style,
            pStyle : video.pStyle,
            ctaStyle : video.ctaStyle
        };
        setVideo(newVideo);
        setVideoDraft(newVideo);
        if(saveToStorage){sessionStorage.setItem(`video${idx}`, JSON.stringify(video));}
    }

    const DisplayTimeLeft = () => {
        if(videoRef.current?.currentTime){
            if(videoRef.current.duration){
                if(videoDraft.timeLeftPersentage){
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 > videoDraft.timeLeftPersentage){
                        setPreview({...preview, timeLeft : true, timeText : Number((videoRef.current.duration - videoRef.current.currentTime).toFixed(0))});
                    }else{
                        setPreview({...preview, timeLeft : false, timeText : 0});
                    }
                }
            }
        }
    }

    useEffect(()=>{
        if(saveToStorage){
            const sessisonVideo = sessionStorage.getItem(`video${idx}`);
            if(sessisonVideo){
                setVideoDraft(JSON.parse(sessisonVideo));
                const parsedVideo = JSON.parse(sessisonVideo)
                setPreview({...preview, CTAscreen : parsedVideo.CTAscreen});
            }
        }
    }, []);

    return(
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                <div className="flex flex-wrap gap-5 p-2 mt-5">
                    <form onChange={updateChanges} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Video:</p>
                        <div className="w-full">
                            <p>Video URL</p>
                            <input type="text" ref={urlRef} defaultValue={videoDraft.url} placeholder="Paste your video URL here..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Thumbnail URL</p>
                            <input type="text" ref={posterRef} defaultValue={videoDraft.poster} placeholder="Paste your thumbnail URL here..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                    </form>
                    <form onChange={updateChanges} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Stay Tuned Message</p>
                        <div className="flex flex-row justify-start items-center gap-2">
                            <input type="checkbox" defaultChecked={videoDraft.showTimeLeft} ref={showTunedRef}/>
                            <p>Add "Stay Tuned" message</p>
                        </div>
                        {
                        (videoDraft.showTimeLeft)
                        ?<div>
                                <div className="w-full">
                                <p>Customize "Time Left" message</p>
                                <input type="text" defaultValue={videoDraft.timeLeftText} ref={timeTextRef} placeholder="Time left until next step..." 
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-full">
                                <p>Show message when x% watched</p>
                                <input type="number" defaultValue={videoDraft.timeLeftPersentage} ref={timeLeftRef} placeholder="60%" 
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                        </div>
                        :null
                        }
                    </form>
                    <form onChange={updateChanges} className="w-full p-5 flex flex-wrap gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl w-[calc(40%-0.25rem)]">Add Custom CTA</p>
                        <div className="w-w-[calc(60%-0.25rem)] flex flex-row justify-start items-center gap-2">
                            <input type="checkbox" defaultChecked={videoDraft.CTAscreen} ref={CTAscreenRef}
                            onChange={()=>{
                                setPreview({...preview, CTAscreen: !videoDraft.CTAscreen}); 
                                setGeneralPreview(CTAscreenRef.current?.checked);
                            }}/>
                            <p>Add custom Call To Action</p>
                        </div>
                        {
                        (videoDraft.CTAscreen)
                        ?<div className="flex flex-wrap gap-2">
                                <div className="w-[calc(50%-0.25rem)]">
                                    <p>Header title</p>
                                    <input type="text" defaultValue={videoDraft.title} placeholder="Title..." ref={titleRef}
                                    className="input input-bordered rounded-full w-full"/>
                                </div>
                                <div className="w-[calc(50%-0.25rem)]">
                                    <p>CTA button text</p>
                                    <input type="text" defaultValue={videoDraft.ctaText} placeholder="CTA button text..." ref={ctaRef}
                                    className="input input-bordered rounded-full w-full"/>
                                </div>
                                <div className="w-full">
                                    <p>Description</p>
                                    <textarea rows={4} defaultValue={videoDraft.description} placeholder="Description..." cols={30} 
                                    className="input input-bordered rounded-2xl w-full h-fit" ref={descriptionRef}/>
                                </div>
                        </div>
                        :null
                        }
                    </form>
                </div>
            </div>
            <div className="w-1/3 mt-5 p-2">
                <div className="p-5 border-2 border-brandLightGray rounded-lg">
                    <p className="mb-2">Funnel Step Preview:</p>
                    {
                        (preview.CTAscreen)
                        ?<div className={`text-${videoDraft.textScreenStyle.textAlign}`} style={{
                            padding : `${videoDraft.textScreenStyle.padding}px`, 
                            backgroundColor :`${videoDraft.textScreenStyle.backgroundColor}`
                        }}>
                            {(videoDraft.title)?<p style={{fontSize : `${videoDraft.h1Style.fontSize}px`, color: `${videoDraft.h1Style.color}`}}>
                                                    {videoDraft.title}
                                                </p>:null}
                            {(videoDraft.description)?<p className="mt-5" style={{fontSize : `${videoDraft.pStyle.fontSize}px`, color: `${videoDraft.pStyle.color}`}}>
                                                        {videoDraft.description}
                                                      </p>:null}
                            {(videoDraft.ctaText)
                            ?<button className="mt-5" style={{
                                fontSize : `${videoDraft.ctaStyle.fontSize}px`, 
                                color: `${videoDraft.ctaStyle.color}`, backgroundColor :`${videoDraft.ctaStyle.backgroundColor}`,
                                padding:`${videoDraft.ctaStyle.padding}px`, borderRadius : `${videoDraft.ctaStyle.borderRadius}px`
                            }}
                            onClick={()=>{setPreview({...preview, CTAscreen:false})}}>
                                {videoDraft.ctaText}
                            </button>
                            :null}
                        </div>
                        :<div>
                            <video ref={videoRef} src={videoDraft.url} poster={videoDraft.poster} onTimeUpdate={DisplayTimeLeft} controls 
                            style={{width : `${videoDraft.videoStyle.width}%`, borderRadius : `${videoDraft.videoStyle.borderRadius}px`}}>
                            </video>
                            {(preview.timeLeft && videoDraft.showTimeLeft)
                            ?<p className="text-right">{`${videoDraft.timeLeftText} : ${preview.timeText}s`}</p>
                            :null
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
        
    );
};

export default VideoContent;
