import React from "react";
import { Step } from "../../Context";
import { MdOndemandVideo } from "react-icons/md";
import { MdFormatListBulleted } from "react-icons/md";
import { GrLinkNext } from "react-icons/gr";
import { RiTextSnippet } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";

interface DeleteProps {
    steps : Step[],
    selectedStep : number,
    setSelectedStep : Function,
    setSteps : Function,
    endingAdded : boolean,
    setEndingAdded : Function,
}

const DeleteStep = ( { steps, setSteps, selectedStep, setSelectedStep, endingAdded, setEndingAdded } : DeleteProps) : React.ReactElement => {
    return(
        <div className="dropdown dropdown-hover dropdown-bottom flex w-1/5 justify-end items-center">
            <p tabIndex={0} role="button" className="text-brandPurple cursor-pointer"><BsThreeDotsVertical /></p>
            <ul tabIndex={0} className="dropdown-content menu z-10 p-2 bg-white shadow-md rounded-lg w-fit">
                <p className="w-32 flex flex-row justify-start items-center gap-2 text-red cursor-pointer" onClick={()=>{
                    if(steps[selectedStep].action === "end screen"){
                        setEndingAdded(false);
                    }
                    if(selectedStep > 0){
                        setSelectedStep(selectedStep - 1);
                    }else{
                        setSelectedStep(0);
                    }
                    setSteps(steps.filter((stepRemoval : Step) => stepRemoval !== steps[selectedStep]));
                }}>
                   <MdDeleteForever/> Delete step
                </p>
            </ul>
        </div>
    );
}

interface Props {
    steps : Step[],
    setSteps : Function, 
    selectedStep : number,
    setSelectedStep : Function,
    setEditStep : Function,
    endingAdded : boolean,
    setEndingAdded : Function,
}

export default function EditorSelection( { steps, setSteps, setSelectedStep, selectedStep, setEditStep, endingAdded, setEndingAdded } : Props ) : React.ReactElement {

    return(
        <div className="flex flex-col justify-start p-2 items-end gap-2">
            {steps.map((step : Step, idx : number) => {
                if(step.action === "video"){
                    return(
                        <div key={idx} 
                        className={`${(selectedStep === idx) ? "bg-brandLightGray" : "hover:bg-brandLightGray"} w-full p-5 flex flex-row justify-start items-center`} >
                            <p className="flex flex-row justify-start items-center gap-2 w-4/5 cursor-pointer"
                            onClick={()=>{setSelectedStep(idx); setEditStep(true);}}><MdOndemandVideo className="size-6"/> Step {idx + 1}: Video Step</p>
                            <DeleteStep setSteps={setSteps} steps={steps} selectedStep={idx} setSelectedStep={setSelectedStep} 
                            endingAdded={endingAdded} setEndingAdded={setEndingAdded}/>
                        </div>
                    );
                }
                if(step.action === "form"){
                    return(
                        <div key={idx} 
                        className={`${(selectedStep === idx) ? "bg-brandLightGray" : "hover:bg-brandLightGray"} w-full p-5 flex flex-row justify-start items-center`} >
                            <p className="flex flex-row justify-start items-center gap-2 w-4/5 cursor-pointer"
                            onClick={()=>{setSelectedStep(idx); setEditStep(true);}}><MdFormatListBulleted className="size-6"/> Step {idx + 1}: Form Step</p>
                            <DeleteStep setSteps={setSteps} steps={steps} selectedStep={idx} setSelectedStep={setSelectedStep} 
                            endingAdded={endingAdded} setEndingAdded={setEndingAdded}/>
                        </div>
                    );
                }
                if(step.action === "textScreen"){
                    return(
                        <div key={idx} 
                        className={`${(selectedStep === idx) ? "bg-brandLightGray" : "hover:bg-brandLightGray"} w-full p-5 flex flex-row justify-start items-center`} >
                            <p className="flex flex-row justify-start items-center gap-2 w-4/5 cursor-pointer"
                            onClick={()=>{setSelectedStep(idx); setEditStep(true);}}><RiTextSnippet className="size-6"/> Step {idx + 1}: Text screen Step</p>
                            <DeleteStep setSteps={setSteps} steps={steps} selectedStep={idx} setSelectedStep={setSelectedStep} 
                            endingAdded={endingAdded} setEndingAdded={setEndingAdded}/>
                        </div>
                    );
                }
                if(step.action === "end screen"){
                    return(
                        <div key={idx} 
                        className={`${(selectedStep === idx) ? "bg-brandLightGray" : "hover:bg-brandLightGray"} w-full p-5 flex flex-row justify-start items-center`} >
                            <p className="flex flex-row justify-start items-center gap-2 w-4/5 cursor-pointer"
                            onClick={()=>{setSelectedStep(idx); setEditStep(true);}}><GrLinkNext className="size-6"/> Step {idx + 1}: Ending</p>
                            <DeleteStep setSteps={setSteps} steps={steps} selectedStep={idx} setSelectedStep={setSelectedStep} 
                            endingAdded={endingAdded} setEndingAdded={setEndingAdded}/>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
}