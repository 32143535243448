import { isSameDay } from "date-fns";
import React, {createContext, useEffect, useState} from "react";
import { useLocation, useNavigate, NavigateFunction, Location } from "react-router-dom";

interface Props {
    children : React.ReactNode
}

export const Context : React.Context<any> = createContext(undefined);

export const ContextProvider : React.FC<Props> = (props: Props) : React.ReactElement => {

    //Comment before local development:
    const apicall : string = "https://admin.cxtailor.com";

    //Comment before deployment:
    //const apicall : string = "http://localhost:3000";

    const location : Location = useLocation();
    const navigate : NavigateFunction = useNavigate();
    const [loadDone, setLoadDone] = useState<boolean>(false);

    const [userToken, setUserToken] = useState<string>();
    const [error, setError] = useState<number>();
    const [inviteMsg, setInviteMsg] = useState<any>();

    //Basic information regarding user worksapces...
    const [workspace, setWorkspace] = useState<Workspace>();
    const [user, setUser] = useState<User>();
    const [workspaceUsers, setWorkspaceUsers] = useState<User[]>();
    const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>();
    const [leadMagnets, setLeadMagnets] = useState<LeadMagnet[]>();
    const [workspaceLeads, setWorkspaceLeads] = useState<Lead[]>();
    const [workspaceData, setWorkspaceData] = useState<Data[]>();

    //SelectedMagent info
    const [selectedMagnet, setSelectedMagnet] = useState<LeadMagnet>();
    const [magnetData, setMagnetData] = useState<Data>();
    const [rawData, setRawData] = useState<RawData[]>();
    const [sources, setSources] = useState<[]>();
    const [campaigns, setCampaigns] = useState<[]>();
    const [magnetLeads, setMagnetLeads] = useState<Lead[]>();
    const [magnetDateData, setMagnetDateData] = useState<DateData>();
    const [magnetDateTotals, setMagnetDateTotals] = useState<DateTotals>();
    const [resultsEnd, setResultsEnd] = useState<Date>(new Date());
    const [resultsStart, setResultsStart] = useState<Date>(new Date(new Date().setDate(resultsEnd.getDate() - 14)));

    //New leadmagnet info
    const [newLeadMagnet, setNewLeadMagnet] = useState<LeadMagnet>();
    const [steps, setSteps] = useState<Step[]>([]);

    //New AB -test info
    const [newABsteps, setNewABsteps] = useState<Step[]>();

    //AB -test info -general
    const [selectedABtest, setSelectedABtest] = useState<ABtest>();
    const [selectedABdata, setSelectedABdata] = useState<ABdata>();

    /*
    USER INFORMATION FUNCTIONS 
    -- Functions bellow are used for user information --
    */

    //Handles user login:
    const login = async(email : string, password : string) : Promise<void> => {
        try{
            const connection = await fetch(`/auth/login`,
                {
                method : "POST",
                headers : {
                    'Accept' : "application/json" , 
                    "Content-Type" : "application/json" 
                },
                body : JSON.stringify({
                    email : email,
                    password : password
                })
            });

            if(connection.status === 200){
                const userFound = await connection.json();
                setUser({
                    _id : userFound[0].user_id,
                    workspace_id : userFound[0].workspace_id,
                    first_name : userFound[0].first_name,
                    last_name : userFound[0].last_name,
                    email : userFound[0].email,
                    user_rights : userFound[0].user_rights
                });
                setUserToken(userFound[0].token);
                localStorage.setItem("userString", JSON.stringify(userFound[0]._id));
                getWorkspace(userFound[0].workspace_id, userFound[0].token);
                getWorkspaceForms(userFound[0].workspace_id, userFound[0].token);
                getWorkspaceData(userFound[0].workspace_id, userFound[0].token);
                getWorkspaceLeads(userFound[0].workspace_id, userFound[0].token);
                getWorkspaceUsers(userFound[0].workspace_id, userFound[0].token);
                getInvitedUsers(userFound[0].workspace_id, userFound[0].token);
                setLoadDone(true);
                navigate("/");
            }else{
                setError(connection.status)
            }

        }catch(e:any){
            setError(400);
        }
    }
    //Handles user login if information already in localstorage/cookies
    const checkLogin = async() : Promise<void> => {
        const user : string | null = localStorage.getItem("userString");
        const form : string | null = sessionStorage.getItem("form");
        if(user && (!location.pathname.includes("/funnel") && !location.pathname.includes("/sharedFunnel"))){
            const parsedUser : any = JSON.parse(user);
            try{
                const connection : any = await fetch(`/auth/checkLogin/${parsedUser}`);
                if(connection.status === 200){
                    const userFound : any = await connection.json();
                    if(userFound.length > 0){
                        setUser({
                            _id : userFound[0].user_id,
                            workspace_id : userFound[0].workspace_id,
                            first_name : userFound[0].first_name,
                            last_name : userFound[0].last_name,
                            email : userFound[0].email,
                            user_rights : userFound[0].user_rights
                        });
                        setUserToken(userFound[0].token);
                        getWorkspace(userFound[0].workspace_id, userFound[0].token);
                        getWorkspaceUsers(userFound[0].workspace_id, userFound[0].token);
                        getInvitedUsers(userFound[0].workspace_id, userFound[0].token);
                        getWorkspaceForms(userFound[0].workspace_id, userFound[0].token);
                        getWorkspaceData(userFound[0].workspace_id, userFound[0].token);
                        getWorkspaceLeads(userFound[0].workspace_id, userFound[0].token);
                        if(form){
                            const parsedForm : string = JSON.parse(form);
                            getSelectedForm(parsedForm, userFound[0].token);
                            getMagnetLeads(parsedForm, userFound[0].token);
                            getMagnetData(parsedForm, userFound[0].token, resultsStart, resultsEnd);
                        }
                    }else{
                        localStorage.clear();
                        if(location.pathname === "/login"){
                            navigate("/login");
                        }else if(location.pathname === "/register"){
                            navigate("/register");
                        }else{
                            navigate("/login");
                        }
                    }
                }
            }catch(e:any){
                console.log(e);
            }
        }else{
            if(location.pathname.includes("/funnel") || location.pathname.includes("/sharedFunnel")){
                navigate(location.pathname);
            }
            else if(location.pathname === "/login"){
                navigate("/login");
            }else if(location.pathname === "/register"){
                navigate("/register");
            }else{
                navigate("/login");
            }
        }
    }
    //Handles user logouts
    const userLogOut = async() : Promise<void> => {
        try{
            const connection = await fetch(`/auth/logout/${user?._id}`, {
                method : "DELETE",
                headers : {
                    'Accept' : "application/json" , 
                    "Content-Type" : "application/json" 
                }});
            if(connection.status === 200){
                setUserToken(undefined);
                sessionStorage.clear();
                localStorage.clear();
                navigate("/login");
            }
        }catch(e:any){
            console.log(e);
        }
    }

    /*
    WORKSPACE FUNCTIONS
    -- Functions below are used for workspace information --
    */
    const getWorkspace = async(workspace_id : string, token : string) : Promise<void> => {
        try{
            const connection = await fetch(`/api/workspace/${workspace_id}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(connection.status === 200){
                const worksapceInfo : Workspace[] = await connection.json();
                setWorkspace(worksapceInfo[0]);
            }

        }catch(e:any){
            console.log(e);
        }
    };

    const getWorkspaceUsers = async(workspace_id : string, token : string) : Promise<void> => {
        try{
            const connection = await fetch(`/api/user/workspaceUsers/${workspace_id}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(connection.status === 200){
                const data : User[] = await connection.json();
                setWorkspaceUsers(data);
                
            }

        }catch(e:any){
            console.log(e);
        }
    }

    const deleteUser = async(workspace_id : string, user_id : string, token : string) : Promise<void> =>{
        try{
            const connection = await fetch(`/api/user/delete/${user_id}`,{
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }});
            if(connection.status === 200){
                getWorkspaceUsers(workspace_id, token);
            }

        }catch(e:any){
            console.log(e);
        }
    }

    const inviteUser = async(email : string, user_rights : string, workspace_id : string, workspace_name : string, userEmail : string, token : string) : Promise<void> => {
        try{
            const connection = await fetch(`/api/user/invite`, 
                {
                    method : "POST",
                    headers : {
                    'Authorization' : `Bearer ${token}`,
                    'Accept' : "application/json",
                    "Content-Type" : "application/json",
                    },
                    body : JSON.stringify({
                        email : email,
                        workspace_id : workspace_id,
                        user_rights : user_rights,
                        workspace_name : workspace_name,
                        userEmail : userEmail
                    })
                }
            );
            if(connection.status === 200){
                setInviteMsg({message: "Invite sent succesfully", severity : "success"});
                const data : InvitedUser[] = await connection.json();
                setInvitedUsers(data);

            }else if(connection.status === 302){
                setInviteMsg({message:"Given email already in use in another workspace", severity : "warning"});

            }else{
                setInviteMsg({message :"Something went wrong", severity : "error"});
            }

        }catch(e:any){
            setInviteMsg({message : "Something went wrong", severity : "error"});
            console.log(e);
        }
    }

    const getInvitedUsers = async(workspace_id : string, token : string) : Promise<void> => {
        try{
            const connection = await fetch(`/api/user/invitedUsers/${workspace_id}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(connection.status === 200){
                const data : InvitedUser[] = await connection.json();
                setInvitedUsers(data);
            }

        }catch(e:any){
            console.log(e);
        }
    }

    /*
    FORM FUNCTIONS
    -- Functions below are used form workspace information --
    */
    const getWorkspaceForms= async(workspace_id : string, token : string) : Promise<void> => {
        try{
            const connection = await fetch(`/api/sequence/workspaceMagnets/${workspace_id}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(connection.status === 200){
                const forms : LeadMagnet[] = await connection.json();
                setLeadMagnets(forms);
            }
        }catch(e:any){
            console.log(e);
        }
    }

    const getSelectedForm = async(form_id : string, token : string) : Promise<void> => {
        sessionStorage.setItem("form", JSON.stringify(form_id));
        try{
            const formConnection = await fetch(`/api/sequence/${form_id}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(formConnection.status === 200){
                const form : LeadMagnet[] = await formConnection.json();
                setSelectedMagnet(form[0]);
                if(form[0].ABtest_id){
                    getSelectedABtest(form[0].ABtest_id, token);
                }
            }
        }catch(e:any){
            console.log(e);
        }
    }

    const changeFormName = async(form_id : string, token : string, newName : string) : Promise<void> => {
        try{
            const formConnection = await fetch(`/api/sequence/editName/${form_id}`, {
                method : "PUT",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    'Accept' : "application/json",
                    "Content-Type" : "application/json",
                },
                body : JSON.stringify({form_name : newName})
            });
            if(formConnection.status === 200){
                const form : LeadMagnet[] = await formConnection.json();
                setSelectedMagnet(form[0]);
            }
        }catch(e:any){
            console.log(e);
        }
    }

    const editFormSteps = async(form_id : string, token : string, steps : Step[]) : Promise<void> =>{
        try{
            const connection = await fetch(`/api/sequence/editSteps/${form_id}`, {
                method : "PUT",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    'Accept' : "application/json",
                    "Content-Type" : "application/json",
                },
                body : JSON.stringify({steps : steps})
            });
            if(connection.status === 200){
                getSelectedForm(form_id, token);
                getMagnetData(form_id, token, resultsStart, resultsEnd);
                getMagnetLeads(form_id, token);
                sessionStorage.clear();
                navigate("/sequences/info");
            }

        }catch(e:any){
            console.log(e);
        }
    }

    const saveNewForm = async( workspace_id : string, user_id : string, token : string, form_name : string, steps : Step[]) : Promise<void> => {
        try{
            const newMagnet : LeadMagnet = {workspace_id : workspace_id, user_id : user_id, form_name : form_name, created_at : new Date(), steps : steps};
            const connection = await fetch(`/api/sequence/newMagnet`,
                {
                    method : "POST",
                    headers : {
                    'Authorization' : `Bearer ${token}`,
                    'Accept' : "application/json",
                    "Content-Type" : "application/json",
                    },
                    body : JSON.stringify(newMagnet)
                }
            );
            if(connection.status === 200){
                const data : LeadMagnet[] = await connection.json();
                setSelectedMagnet(data[0]);
                getMagnetData(data[0]._id!, token, resultsStart, resultsEnd);
                getMagnetLeads(data[0]._id!, token);
                getWorkspaceForms(workspace_id, token);
                getWorkspaceData(workspace_id, token);
                getWorkspaceLeads(workspace_id, token);
                navigate("/sequences/info");
            }

        }catch(e:any){
            console.log(e)
        }
    }

    const deleteForm = async(workspace_id : string, form_id : string, token : string) : Promise<void> => {
        try{
            const connection = await fetch(`/api/sequence/delete/${form_id}`,{
                method : "DELETE",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                }
            });
            if(connection.status === 200){
                sessionStorage.removeItem("form");
                getWorkspaceForms(workspace_id, token);
                getWorkspaceData(workspace_id, token);
                getWorkspaceLeads(workspace_id, token);
                navigate("/sequences");
            }
        }catch(e:any){
            console.log(e);
        }
    }
    /*
    DATA FUNCTIONS
    */
    const getWorkspaceData = async(workspace_id : string, token : string) : Promise<void> => {
        try{    
            const connection = await fetch(`/api/data/workspaceData/${workspace_id}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(connection.status === 200){
                const data : Data[] = await connection.json();
                setWorkspaceData(data.sort((a: Data, b: Data) => b.conversion14Days - a.conversion14Days));
            }
        }catch(e:any){
            console.log(e);
        }
    }

    const getMagnetData = async(form_id : string, token : string, startDate : Date, endDate : Date) : Promise<void>=>{
        try{
            const dataConnection = await fetch(`/api/data/magnetData/${form_id}/${startDate.toISOString()}/${endDate.toISOString()}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(dataConnection.status === 200){
                const foundData : MagnetData = await dataConnection.json();
                setMagnetData(foundData.clean[0]);
                setRawData(foundData.raw);
                setSources(foundData.sources);
                setCampaigns(foundData.campaigns);
                formatRawData(startDate, endDate, foundData.raw, foundData.clean[0].steps14Days.length, foundData.sources, foundData.campaigns);
            }
        }catch(e:any){
            console.log(e);
        }
    }

    const formatRawData = (date1 : Date, date2: Date, rawData : RawData[], magnetLength : number, sources : string[], campaigns : string[]) => {
        const startDate : Date = date1;
        const endDate : Date = date2;
    
        const difference : number = Math.abs(startDate.getTime() - endDate.getTime());
        const dateDifference : number = Math.ceil(difference / (1000*60*60*24));
    
        let dateData : any = [];
        let totalData : any = {campaigns : [], sources : []};

        let sourceAdd : {[key : string] : any} = {source : "Total"};
        let campaignAdd : {[key : string] : any} = {campaign : "Total"};
        for(let idx = 0; idx < magnetLength; idx++){
            sourceAdd[`step ${idx}`] = {"view" : 0, "start" : 0, "finish": 0, "pause" : 0, "pauseCount" : 0};
            campaignAdd[`step ${idx}`] = {"view" : 0, "start" : 0, "finish": 0, "pause" : 0, "pauseCount" : 0};
        };
        totalData.sources.push(sourceAdd);
        totalData.campaigns.push(campaignAdd);

        sources.forEach((source : string, idx : number)=>{
            let sourceAdd : {[key : string] : any} = {source : source};
            for(let idx = 0; idx < magnetLength; idx++){
                sourceAdd[`step ${idx}`] = {"view" : 0, "start" : 0, "finish": 0, "pause" : 0, "pauseCount" : 0};
            };
            totalData.sources.push(sourceAdd);
        });

        campaigns.forEach((campaign: string, idx: number)=>{
            let campaignAdd : {[key : string] : any} = {campaign : campaign};
            for(let idx = 0; idx < magnetLength; idx++){
                campaignAdd[`step ${idx}`] = {"view" : 0, "start" : 0, "finish": 0, "pause" : 0, "pauseCount" : 0};
            };
            totalData.campaigns.push(campaignAdd);
        });
    
        for(let i = 0; i <= dateDifference; i++){
            const tempDate : Date = new Date(date1);
            const newDate : Date =  new Date(tempDate.setDate(tempDate.getDate() + i));
            let addition : {[key : string] : any} = {date : newDate};
            
            for(let idx = 0; idx < magnetLength; idx++){
                addition[`step ${idx}`] = {"view" : 0, "start" : 0, "finish": 0, "pause" : 0, "pauseCount" : 0};
            };

            dateData.push(addition);
        }

        //handles totals: 
        for(let j = 0; j <= dateDifference; j++){
            for(let i = 0; i < rawData.length ; i++){
                if(isSameDay(rawData[i].date, dateData[j].date)){
                    try{
                        if(rawData[i].action === "pause"){
                            dateData[j][`step ${rawData[i].step}`][`pauseCount`] += 1;
                            dateData[j][`step ${rawData[i].step}`][`pause`] += rawData[i].percentage;
                           
                            dateData[j][`step ${rawData[i].step}`][`pauseCount`] += 1;
                            dateData[j][`step ${rawData[i].step}`][`pause`] += rawData[i].percentage;
                        }else{
                            dateData[j][`step ${rawData[i].step}`][`${rawData[i].action}`] += 1;
                            dateData[j][`step ${rawData[i].step}`][`${rawData[i].action}`] += 1;
                        }
                        totalData.sources.forEach((source : any) =>{
                            if(source.source === "Total"){
                                if(rawData[i].action === "pause"){
                                    source[`step ${rawData[i].step}`][`pauseCount`] += 1;
                                    source[`step ${rawData[i].step}`][`pause`] += rawData[i].percentage;
                                }else{
                                    source[`step ${rawData[i].step}`][`${rawData[i].action}`] += 1;
                                }
                            }else if(source.source === rawData[i].source){
                                if(rawData[i].action === "pause"){
                                    source[`step ${rawData[i].step}`][`pauseCount`] += 1;
                                    source[`step ${rawData[i].step}`][`pause`] += rawData[i].percentage;
                                }else{
                                    source[`step ${rawData[i].step}`][`${rawData[i].action}`] += 1;
                                }
                            }
                        });
                        totalData.campaigns.forEach((campaign : any)=>{
                            if(campaign.campaign === "Total"){
                                if(rawData[i].action === "pause"){
                                    campaign[`step ${rawData[i].step}`][`pauseCount`] += 1;
                                    campaign[`step ${rawData[i].step}`][`pause`] += rawData[i].percentage;
                                }else{
                                    campaign[`step ${rawData[i].step}`][`${rawData[i].action}`] += 1;
                                }
                            }else if(campaign.campaign === rawData[i].campaign){
                                if(rawData[i].action === "pause"){
                                    campaign[`step ${rawData[i].step}`][`pauseCount`] += 1;
                                    campaign[`step ${rawData[i].step}`][`pause`] += rawData[i].percentage;
                                }else{
                                    campaign[`step ${rawData[i].step}`][`${rawData[i].action}`] += 1;
                                }
                            }
                        })
                    }catch(e:any){
                        console.log(e);
                    }
                }
            }
        }
    
        dateData.forEach((date : any)=>{
            date.converison = ((date[`step ${magnetLength - 1}`].finish / date[`step 0`].view) 
                && (date[`step ${magnetLength - 1}`].finish / date[`step 0`].view) !== Infinity)
                ? (date[`step ${magnetLength - 1}`].finish / date[`step 0`].view)
                : 0;
            date.completionRate = ((date[`step ${magnetLength - 1}`].finish / date[`step 0`].start) 
                && (date[`step ${magnetLength - 1}`].finish / date[`step 0`].start) !== Infinity)
                ? (date[`step ${magnetLength - 1}`].finish / date[`step 0`].start)
                : 0;

            date.views = date[`step 0`].view;
            date.starts = date[`step 0`].start;
            date.completions = date[`step ${magnetLength - 1}`].finish;

            date.startRate = ((date[`step 0`].start / date[`step 0`].view) 
                && (date[`step 0`].start / date[`step 0`].view) !== Infinity)
                ? (date[`step 0`].start / date[`step 0`].view)
                : 0;
            date.date = date.date.toLocaleDateString("fi-FI");
            
        });
        setMagnetDateData(dateData);
        setMagnetDateTotals(totalData);
    }

    /*
    LEAD FUNCTIONS
    */
    const getWorkspaceLeads = async(workspace_id : string, token : string) : Promise<void> => {
        try{
            const connection = await fetch(`/api/leads/workspaceLeads/${workspace_id}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(connection.status === 200){
                const leads : Lead[] = await connection.json();
                setWorkspaceLeads(leads.reverse());
            }
        }catch(e:any){
            console.log(e);
        }
    }

    const getMagnetLeads = async(form_id : string, token : string) : Promise<void> => {
        try{
            const connection = await fetch(`/api/leads/magnetLeads/${form_id}`, {
                method : "GET",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                }
            });
            if(connection.status === 200){
                const leads : Lead[] = await connection.json();
                setMagnetLeads(leads.reverse());
            }
        }catch(e:any){
            console.log(e);
        }
    }

    /*
    AB TEST FUNCTIONS
    */
    const saveNewABTest = async(workspace_id : string, token : string, user_id : string, form_name : string, form_id : string, 
        test_name : string, comments : string, Asteps : Step[], Bsteps : Step[], mainGoal : string, secondaryGoal : string
    ) : Promise<void> => {
        try{
            const newABtest : ABtest = {
                workspace_id : workspace_id,
                form_id : form_id,
                user_id : user_id,
                form_name : form_name,
                test_name : test_name,
                comments : comments,
                Asteps : Asteps,
                Bsteps : Bsteps,
                running : true,
                winner : "",
                startDate : new Date(),
                endDate : null,
                mainGoal : JSON.parse(mainGoal),
                secondaryGoal : (secondaryGoal !== undefined)?JSON.parse(secondaryGoal):""
            };
            
            const connection = await fetch(`/api/ab/newTest`,
                {
                    method : "POST",
                    headers : {
                    'Authorization' : `Bearer ${token}`,
                    'Accept' : "application/json",
                    "Content-Type" : "application/json",
                    },
                    body : JSON.stringify(newABtest)
                }
            );
            if(connection.status === 200){
                getSelectedForm(form_id, token);
                getMagnetData(form_id, token, resultsStart, resultsEnd);
                getMagnetLeads(form_id, token);
                sessionStorage.clear();
                navigate("/sequences/info");
            }

        }catch(e:any){
            console.log(e);
        }
    }

    const getSelectedABtest = async(ABtest_id : string, token : string) : Promise<void> => {
        try{
            const dataConnection = await fetch(`/api/ab/testData/${ABtest_id}`,
                {
                    method : "GET",
                    headers : {
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                    }
                }
            );
            if(dataConnection.status === 200){
                const dataParsed : ABdata[] = await dataConnection.json();
                setSelectedABdata(dataParsed[0]);
            }

            const infoConnection = await fetch(`/api/ab/${ABtest_id}`,
                {
                    method : "GET",
                    headers : {
                    'Authorization' : `Bearer ${token}`,
                    "Content-Type" : "application/json",
                    }
                }
            );
            if(infoConnection.status === 200){
                const infoParsed = await infoConnection.json();
                setSelectedABtest(infoParsed[0]);
            }

        }catch(e:any){
            console.log(e);
        }
    }

    const chooseWinner = async(ABtest_id : string, form_id : string, token : string, steps : Step[], winner : string) : Promise<void> => {
        try{
            const testConnection = await fetch(`/api/ab/end/${ABtest_id}`, {
                method : "PUT",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    'Accept' : "application/json",
                    "Content-Type" : "application/json",
                },
                body : JSON.stringify({winner : winner})
            });

            const magnetConnection = await fetch(`/api/sequence/editSteps/${form_id}`, {
                method : "PUT",
                headers : { 
                    'Authorization' : `Bearer ${token}`,
                    'Accept' : "application/json",
                    "Content-Type" : "application/json",
                },
                body : JSON.stringify({steps : steps})
            });

            if(magnetConnection.status === 200){
                getSelectedForm(form_id, token);
                getMagnetData(form_id, token, resultsStart, resultsEnd);
                getMagnetLeads(form_id, token);
                sessionStorage.clear();
                navigate("/sequences/info");
            }

        }catch(e:any){
            console.log(e);
        }
    }

    useEffect(()=>{
        checkLogin();
        setLoadDone(true);
    },[]);

    return(
        <Context.Provider value={{
            //basic workspace values
            workspace,
            user,
            leadMagnets,
            workspaceLeads,
            workspaceData,
            workspaceUsers,
            invitedUsers,
            deleteUser,
            inviteUser,
            getWorkspace,
            getWorkspaceUsers,
            getWorkspaceForms,
            getWorkspaceLeads,
            getWorkspaceData,
            getInvitedUsers,
            setUser,

            //selectedMagnet stuff
            setSelectedMagnet,
            selectedMagnet,
            getSelectedForm,
            getMagnetData,
            magnetData,
            magnetDateData,
            magnetDateTotals,
            rawData,
            sources,
            campaigns,
            magnetLeads,
            changeFormName,
            getMagnetLeads,
            deleteForm,
            editFormSteps,

            //login stuff
            login,
            setUserToken,
            userToken,
            userLogOut,
            checkLogin,

            //new leadmagnet stuff
            newLeadMagnet,
            setNewLeadMagnet,
            steps,
            setSteps,
            saveNewForm,

            //AB -test stuff
            newABsteps,
            setNewABsteps,
            saveNewABTest,
            selectedABtest,
            selectedABdata,
            chooseWinner,

            //other stuff
            loadDone,
            setLoadDone,
            location,
            error,
            setError,
            navigate,
            resultsStart,
            resultsEnd,
            setResultsStart,
            setResultsEnd,
            apicall,
            inviteMsg,
            setInviteMsg
        }}>
            {props.children}
        </Context.Provider>
    );
}

export interface User{
    _id : string,
    workspace_id : string,
    first_name : string,
    last_name : string,
    email : string,
    user_rights : string,
};

export interface InvitedUser {
    timestamp : Date,
    workspace_id : string,
    _id : string,
    email : string,
    user_rights : string
}

export interface Workspace {
    _id : string,
    workspace_name : string,
    level : string,
    users : User[],
    sequences : []
}

export interface LeadMagnet {
    _id? : string,
    workspace_id : string,
    user_id : string,
    ABtest_id? : string,
    form_name : string,
    created_at : string | Date,
    steps : Step[]
}

export interface Step {
    action : string,
    tailored? : TailoredStep,
    video? : VideoInterface,
    form? : FormInterface,
    textScreen? : TextScreenInterface,
    thankYouPage? : ThankYouInterface,
}

export interface TailoredStep {
    header : string,
    description : string,
    ctaString : string,
    topicString : string,
    importanceStrings : string[],
    selectAllString : string,
    questions : TailoringInterface[],
    textScreenStyle : any,
    h1Style : any,
    pStyle : any,
    ctaStyle : any,
    tableStyle : any,
}

export interface TailoringInterface {
    question : string,
    importance : number | undefined,
    video : VideoInterface
}

export interface VideoInterface {
    stepName? : "",
    url : string,
    poster : string,
    autoPlay? : boolean,
    showTimeLeft : boolean,
    timeLeftPersentage : number,
    timeLeftText : string,
    CTAscreen? : boolean,
    title : string,
    description : string,
    ctaText : string, 
    videoStyle : any,
    textScreenStyle : any,
    h1Style : any,
    pStyle : any,
    ctaStyle : any,
    playButtonStyle? : any,
    controlStyle? : any,
    teaserStyle? : any,
}

export interface FormInterface {
    header : string,
    description : string,
    inputFields? : any, 
    firstNameLabel? : string,
    lastNameLabel? : string,
    companyLabel? : string,
    emailLabel? : string,
    emailResults? : string,
    emailNotification? : boolean,
    redirect? : boolean,
    redirectUrl? : string,
    ctaText : string,
    textScreenStyle : any,
    h1Style : any,
    pStyle : any,
    labelStyle : any,
    inputStyle : any,
    ctaStyle : any
}

export interface TextScreenInterface {
    h1 : string,
    description : string,
    ctaText : string,
    textScreenStyle : any,
    h1Style : any,
    pStyle : any,
    ctaStyle : any
}

export interface ThankYouInterface {
    h1 : string,
    description : string,
    ctaText : string,
    ctaUrl : string,
    ctaList? : any[],
    textScreenStyle : any,
    h1Style : any,
    pStyle : any,
    ctaStyle : any
}

export interface Lead {
    _id : string,
    workspace_id : string,
    form_id : string,
    firstName? : string,
    lastName? : string,
    email?: string,
    phone? : string,
    company? : string,
    data? : [],
    quality : string,
    created_at : string,
}

export interface Data {
    id : string,
    name : string,
    steps14Days : StepData[],
    stepsComparison : StepData[]
    conversion14Days : number,
    conversionComparison : number
}

export interface StepData {
    step : number,
    view? : number,
    start : number,
    pauseCount : number,
    pauseTotal : number,
    finish : number,
    subStep? : StepData[]
}

export interface RawData {
    _id : string,
    form_id : string,
    workspace_id : string,
    step : number,
    action : string,
    percentage : number,
    date : Date,
    source : string | null,
    mediun : string | null,
    campaign : string | null,
    subStep? : number | null,
}

export interface MagnetData {
    clean : Data[],
    raw : RawData[],
    sources : [],
    campaigns : []
}

export interface DateData {
    date : Date,
    completionRate : number,
    completions : number,
    conversion : number,
    startRate : number,
    views : number,
}

export interface DateTotals {
    campaigns : [],
    sources : [],
}

export interface ABtest {
    _id? : string,
    workspace_id : string,
    form_id : string,
    user_id : string,
    form_name : string,
    test_name : string,
    comments : string,
    Asteps : Step[],
    Bsteps : Step[],
    running : boolean,
    winner : string,
    startDate : Date,
    endDate : Date | null,
    mainGoal : string,
    secondaryGoal : string
}

export interface ABdata {
    _id : string,
    form_id : string,
    form_name : string,
    name : string,
    mainGoal : any,
    secondaryGoal : any,
    AvariantSteps : any[],
    BvariantSteps : any[],
    AvariantConversion : number,
    BvariantConversion : number,
}