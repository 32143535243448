import React, { useEffect, useRef, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { FormInterface } from "../../../Context";

interface InputProps {
    handleChange : Function,
    idx : number,
    inputField : any
}

const NewInput = ({idx, handleChange, inputField}:InputProps) : React.ReactElement => {
    return(
        <form onChange={(e)=>{handleChange("add", idx, e)}} className="w-full p-2 flex flex-wrap gap-2 border-brandLightGray border-2 rounded-xl">
            <div className="flex flex-row justify-start items-center w-full">
                <div className="w-2/3">
                    <p className="text-xl w-full">{inputField.viewValue} -field</p>
                </div>
                <div className="w-1/3 flex justify-end">
                    <span className="tooltip tooltip-left" data-tip="Delete field">
                        <MdDeleteForever className="size-6 text-red hover:scale-110 transition cursor-pointer" onClick={()=>{handleChange("remove", idx);}}/>    
                    </span>
                </div>
            </div>
            <p className="w-full">Input label</p>
            <input type="text"  name="Inputlabel" placeholder="Input label" defaultValue={inputField.label}
            className="input input-bordered rounded-full w-full"/>
        </form>
    );
}

interface Props {
    idx : number,
    setForm : Function,
    form : FormInterface
}

const FormContent = ({idx, form, setForm}:Props) : React.ReactElement => {
    const headerRef = useRef<HTMLInputElement>(null);
    const descRef = useRef<HTMLTextAreaElement>(null);
    const ctaRef = useRef<HTMLInputElement>(null);
    const emailResultsRef = useRef<HTMLInputElement>(null);
    const [inputs, setInputs] = useState<any>((form.inputFields)?form.inputFields:[]);

    const [formDraft, setFormDraft] = useState<FormInterface>(form);

    const addNewField = (name : string) => {
        let newInputs = inputs
        if(name === "email"){
            newInputs.push({type :"email", name : name, label : "Email", viewValue : "Email"});
        }else if(name === "company"){
            newInputs.push({type :"text", name : name, label : "Company", viewValue : "Company"});
        }else if(name === "phone"){
            newInputs.push({type :"text", name : name, label : "Phone", viewValue : "Phone"});
        }else if(name === "lastName"){
            newInputs.push({type :"text", name : name, label : "Last Name", viewValue : "Last Name"});
        }else if(name === "firstName"){
            newInputs.push({type :"text", name : name, label : "First Name", viewValue : "First Name"});
        }else if(name === "other"){
            newInputs.push({type :"text", name : name + newInputs.length, label : "Other", viewValue : "Other"});
        }
        setInputs(newInputs);
        const newForm : FormInterface = {
            header : (headerRef.current?.value) ? headerRef.current!.value : form.header,
            description : (descRef.current?.value) ? descRef.current!.value : form.description,
            inputFields : newInputs,
            emailResults : (emailResultsRef.current?.value) ? emailResultsRef.current!.value : form.emailResults,
            ctaText : (ctaRef.current?.value) ? ctaRef.current!.value : form.ctaText,
            textScreenStyle : form.textScreenStyle,
            h1Style : form.h1Style,
            pStyle : form.pStyle,
            labelStyle : form.labelStyle,
            inputStyle : form.inputStyle,
            ctaStyle : form.ctaStyle
        }
        setForm(newForm);
        setFormDraft(newForm);
        sessionStorage.setItem(`form${idx}`, JSON.stringify(form));
    }

    const handleChange = (action : string, idx : number, event? : any) => {
        let newInputs = inputs;
        if(event){
            newInputs[idx].label = event.target.value;
        }
        if(action === "remove"){
            newInputs = inputs.filter((input : any) => input !== inputs[idx]);
            setInputs(newInputs);
        }
        const newForm : FormInterface = {
            header : (headerRef.current?.value) ? headerRef.current!.value : form.header,
            description : (descRef.current?.value) ? descRef.current!.value : form.description,
            inputFields : newInputs,
            emailResults : (emailResultsRef.current?.value) ? emailResultsRef.current!.value : form.emailResults,
            ctaText : (ctaRef.current?.value) ? ctaRef.current!.value : form.ctaText,
            textScreenStyle : form.textScreenStyle,
            h1Style : form.h1Style,
            pStyle : form.pStyle,
            labelStyle : form.labelStyle,
            inputStyle : form.inputStyle,
            ctaStyle : form.ctaStyle
        }
        setForm(newForm);
        setFormDraft(newForm);
        sessionStorage.setItem(`form${idx}`, JSON.stringify(form));
    }

    const updateChanges = () => {
        const newForm : FormInterface = {
            header : (headerRef.current?.value) ? headerRef.current!.value : form.header,
            description : (descRef.current?.value) ? descRef.current!.value : form.description,
            inputFields : inputs,
            emailResults : (emailResultsRef.current?.value) ? emailResultsRef.current!.value : form.emailResults,
            ctaText : (ctaRef.current?.value) ? ctaRef.current!.value : form.ctaText,
            textScreenStyle : form.textScreenStyle,
            h1Style : form.h1Style,
            pStyle : form.pStyle,
            labelStyle : form.labelStyle,
            inputStyle : form.inputStyle,
            ctaStyle : form.ctaStyle
        }
        setForm(newForm);
        setFormDraft(newForm);
        sessionStorage.setItem(`form${idx}`, JSON.stringify(form));
    }

    useEffect(()=>{
        const sessionForm = sessionStorage.getItem(`form${idx}`);
        if(sessionForm){
            setFormDraft(JSON.parse(sessionForm));
        }
    }, []);

    return(
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                <div className="flex flex-wrap gap-5 p-2 mt-5">
                    <form onChange={updateChanges} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Static Form Elements</p>
                        <div className="w-full">
                            <p>Header</p>
                            <input type="text" ref={headerRef} defaultValue={formDraft.header} placeholder="Form Header..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Description</p>
                            <textarea cols={3} ref={descRef} defaultValue={formDraft.description} rows={4} placeholder="Engaging Description..." 
                            className="input input-bordered rounded-2xl w-full h-fit"/>
                        </div>
                        <div className="w-full">
                            <p>CTA text</p>
                            <input type="text" ref={ctaRef} defaultValue={formDraft.ctaText} placeholder="CTA Text" 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Email results to</p>
                            <input type="text" ref={emailResultsRef} defaultValue={formDraft.emailResults} placeholder="Email address..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                    </form>
                    <div className="w-[calc(50%-0.625rem)] pt-3 flex flex-col gap-2 rounded-xl">
                        <div className="flex flex-row justify-start items-center">  
                            <div className="w-1/2">
                                <p className="text-xl">Form Fields</p>
                            </div>
                            <div className="w-1/2 flex justify-end">
                                <div className="dropdown dropdown-end">
                                    <button tabIndex={0}
                                    className="btn font-light rounded-full border-2 bg-white border-brandPurple hover:bg-brandPurple hover:text-white hover:border-brandPurple">
                                        Add new field
                                    </button>
                                    <div className="dropdown-content menu menu-sm rounded-box z-[1] flex flex-col bg-brandLightGray w-full">
                                        <button className="text-left hover:bg-brandPurple hover:text-white p-2 rounded-lg cursor-pointer" 
                                        onClick={()=>{addNewField("firstName")}}>First name</button>
                                        <button className="text-left hover:bg-brandPurple hover:text-white p-2 rounded-lg cursor-pointer" 
                                        onClick={()=>{addNewField("lastName")}}>Last name</button>
                                        <button className="text-left hover:bg-brandPurple hover:text-white p-2 rounded-lg cursor-pointer" 
                                        onClick={()=>{addNewField("email")}}>Email</button>
                                        <button className="text-left hover:bg-brandPurple hover:text-white p-2 rounded-lg cursor-pointer" 
                                        onClick={()=>{addNewField("phone")}}>Phone</button>
                                        <button className="text-left hover:bg-brandPurple hover:text-white p-2 rounded-lg cursor-pointer" 
                                        onClick={()=>{addNewField("company")}}>Company</button>
                                        <button className="text-left hover:bg-brandPurple hover:text-white p-2 rounded-lg cursor-pointer" 
                                        onClick={()=>{addNewField("other")}}>Other</button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        {inputs.map((input : any, idx : number)=>{
                            return<NewInput idx={idx} handleChange={handleChange} inputField={input}/>
                        })}                        
                    </div>
                </div>
            </div>
            <div className="w-1/3 mt-5 p-2">
                <div className="p-5 border-2 border-brandLightGray rounded-lg">
                    <p className="mb-2">Funnel Step Preview:</p>
                    <div className={`text-${formDraft.textScreenStyle.textAlign} flex flex-col gap-3`} style={{padding : `${formDraft.textScreenStyle.padding}px`, 
                        backgroundColor : `${formDraft.textScreenStyle.backgroundColor}`}}>
                        {(formDraft.header)?
                        <p style={{fontSize : `${formDraft.h1Style.fontSize}px`, color : `${formDraft.h1Style.color}`}}>
                            {formDraft.header}
                        </p>
                        :null}
                        {(formDraft.description)?
                        <p style={{fontSize : `${formDraft.pStyle.fontSize}px`, color : `${formDraft.pStyle.color}`}}>
                            {formDraft.description}
                        </p>
                        :null}
                        <form>
                            {
                            (formDraft.inputFields && formDraft.inputFields.length > 0)
                            ?<>{formDraft.inputFields.map((input : any, idx : number) => {
                                return<div key={idx}>
                                        <label htmlFor={input.name}  style={{fontSize : `${formDraft.labelStyle.fontSize}px`, color : `${formDraft.labelStyle.color}`}}>
                                            {input.label}
                                        </label><br/>
                                        <input readOnly type={input.type} name={input.type} className="border-[1px]"
                                        style={{width :`${formDraft.inputStyle.width}%`, padding : `${formDraft.inputStyle.padding}px`,
                                        fontSize : `${formDraft.inputStyle.fontSize}px`, borderRadius: `${formDraft.inputStyle.borderRadius}px`}}/>
                                    </div>
                            })}</>
                            :<>
                            <label htmlFor="firstName" style={{fontSize : `${formDraft.labelStyle.fontSize}px`, color : `${formDraft.labelStyle.color}`}}>
                            {(formDraft.firstNameLabel)?formDraft.firstNameLabel:"Fist name"}
                            </label><br/>
                            <input type="text" name="firstName" className="border-[1px]" 
                            style={{width :`${formDraft.inputStyle.width}%`, padding : `${formDraft.inputStyle.padding}px`,
                            fontSize : `${formDraft.inputStyle.fontSize}px`, borderRadius: `${formDraft.inputStyle.borderRadius}px`}}/><br/>
                            <label htmlFor="lastName" style={{fontSize : `${formDraft.labelStyle.fontSize}px`, color : `${formDraft.labelStyle.color}`}}>
                                {(formDraft.lastNameLabel)?formDraft.lastNameLabel:"Last name"}
                            </label><br/>
                            <input type="text" name="lastName" className="border-[1px]"
                            style={{width :`${formDraft.inputStyle.width}%`, padding : `${formDraft.inputStyle.padding}px`,
                            fontSize : `${formDraft.inputStyle.fontSize}px`, borderRadius: `${formDraft.inputStyle.borderRadius}px`}}/><br/>
                            <label htmlFor="email" style={{fontSize : `${formDraft.labelStyle.fontSize}px`, color : `${formDraft.labelStyle.color}`}}>
                                {(formDraft.emailLabel)?formDraft.emailLabel:"Email"}
                            </label><br/>
                            <input type="text" name="email" className="border-[1px]"
                            style={{width :`${formDraft.inputStyle.width}%`, padding : `${formDraft.inputStyle.padding}px`,
                            fontSize : `${formDraft.inputStyle.fontSize}px`, borderRadius: `${formDraft.inputStyle.borderRadius}px`}}/><br/>
                            <label htmlFor="company" style={{fontSize : `${formDraft.labelStyle.fontSize}px`, color : `${formDraft.labelStyle.color}`}}>
                                {(formDraft.companyLabel)?formDraft.companyLabel:"Company"}
                                </label><br/>
                            <input type="text" name="company" className="border-[1px]"
                            style={{width :`${formDraft.inputStyle.width}%`, padding : `${formDraft.inputStyle.padding}px`,
                            fontSize : `${formDraft.inputStyle.fontSize}px`, borderRadius: `${formDraft.inputStyle.borderRadius}px`}}/><br/>
                            </>
                            }
                            <button className="mt-2"
                            style={{fontSize : `${formDraft.ctaStyle.fontSize}px`, padding :  `${formDraft.ctaStyle.padding}px`, borderRadius : `${formDraft.ctaStyle.borderRadius}px`,
                            color :  `${formDraft.ctaStyle.color}`, backgroundColor :  `${formDraft.ctaStyle.backgroundColor}`}}>
                                {(formDraft.ctaText)?formDraft.ctaText:"Submit form"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormContent;