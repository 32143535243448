import React, { useEffect, useRef, useState } from "react";
import { TailoredStep } from "../../../Context";
import { FaThumbsDown } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa6";
import { FaQuestion } from "react-icons/fa";


interface Props {
    idx : number,
    tailor : TailoredStep,
    setTailorScreen : Function
}

const TailoringContent = ({idx, tailor, setTailorScreen}:Props) : React.ReactElement => {
    const [tailoring, setTailoring] = useState<TailoredStep>(tailor);
    const headerRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const ctaRef = useRef<HTMLInputElement>(null);
    const topicRef = useRef<HTMLInputElement>(null);
    const selectAllRef = useRef<HTMLInputElement>(null);
    const importance0Ref = useRef<HTMLInputElement>(null);
    const importance1Ref = useRef<HTMLInputElement>(null);
    const importance2Ref = useRef<HTMLInputElement>(null);

    const updateChanges = () => {
        const newTailoring : TailoredStep = {
                    header : (headerRef.current?.value) ? headerRef.current!.value : tailoring.header,
                    description : (descriptionRef.current?.value) ? descriptionRef.current!.value : tailoring.description,
                    ctaString : (ctaRef.current?.value) ? ctaRef.current!.value : tailoring.ctaString,
                    topicString : (topicRef.current?.value) ? topicRef.current!.value : tailoring.topicString,
                    selectAllString : (selectAllRef.current?.value) ? selectAllRef.current!.value : tailoring.selectAllString,
                    importanceStrings : [
                        (importance0Ref.current?.value) ? importance0Ref.current!.value : tailoring.importanceStrings[0] ,
                        (importance1Ref.current?.value) ? importance1Ref.current!.value : tailoring.importanceStrings[1] ,
                        (importance2Ref.current?.value) ? importance2Ref.current!.value : tailoring.importanceStrings[2]
                    ],
                    questions : tailoring.questions,
                    textScreenStyle : tailoring.textScreenStyle,
                    h1Style : tailoring.h1Style,
                    pStyle : tailoring.pStyle,
                    ctaStyle : tailoring.ctaStyle,
                    tableStyle : tailoring.tableStyle
                }
        setTailorScreen(newTailoring);
        setTailoring(newTailoring);
        sessionStorage.setItem(`TailoringQuestions${idx}`, JSON.stringify(newTailoring));
    }

    useEffect(()=>{
        const tailored = sessionStorage.getItem(`TailoringQuestions${idx}`);
        if(tailored){
            setTailoring(JSON.parse(tailored));
        }
    }, [idx]);

    return(
        <div className="w-full flex flex-row gap-5">
            <div className="w-1/2 flex flex-row justify-start items-start gap-2 p-2 m-2 border-brandLightGray border-2 rounded-xl">
                <form onChange={updateChanges} className="w-full p-5 flex flex-col gap-2">
                    <p className="text-xl">Content above the questions</p>
                    <div className="w-full">
                        <p>Header</p>
                        <input type="text" ref={headerRef} defaultValue={tailoring.header}
                        className="input input-bordered rounded-full w-full"/>
                    </div>
                    <div className="w-full">
                        <p>Description</p>
                        <textarea cols={3} ref={descriptionRef} defaultValue={tailoring.description} rows={4}
                        className="input input-bordered rounded-2xl w-full h-fit"/>
                    </div>
                    <div className="w-full">
                        <p>CTA text</p>
                        <input type="text" ref={ctaRef} defaultValue={tailoring.ctaString} 
                        className="input input-bordered rounded-full w-full"/>
                    </div>
                </form>
                <form onChange={updateChanges} className="w-full p-5 flex flex-col gap-2">
                    <p className="text-xl">Question options</p>
                    <div className="w-full">
                        <p>Topic</p>
                        <input type="text" ref={topicRef} defaultValue={tailoring.topicString}
                        className="input input-bordered rounded-full w-full"/>
                    </div>
                    <div className="w-full">
                        <p>Select all -option</p>
                        <input type="text" ref={selectAllRef} defaultValue={tailoring.selectAllString}
                        className="input input-bordered rounded-full w-full"/>
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <p>Selection options</p>
                        <input type="text" ref={importance0Ref} defaultValue={tailoring.importanceStrings[0]} placeholder="CTA Text..." 
                        className="input input-bordered rounded-full w-full"/>
                        <input type="text" ref={importance1Ref} defaultValue={tailoring.importanceStrings[1]} placeholder="CTA Text..." 
                        className="input input-bordered rounded-full w-full"/>
                        <input type="text" ref={importance2Ref} defaultValue={tailoring.importanceStrings[2]} placeholder="CTA Text..." 
                        className="input input-bordered rounded-full w-full"/>
                    </div>
                </form>
            </div>
            <div className="w-1/2" style={{padding : `${tailor.textScreenStyle.padding}px`, backgroundColor : `${tailor.textScreenStyle.backgroundColor}`}}>
                <div className={`text-${"left"} flex flex-col gap-3`}>
                    <div className="flex flex-row">
                        <div className="flex flex-col gap-5 w-3/5">
                            <p style={{fontSize :`${tailor.h1Style.fontSize}px`, color: `${tailor.h1Style.color}`}}>{tailoring.header}</p>
                            <p style={{fontSize :`${tailor.pStyle.fontSize}px`, color: `${tailor.pStyle.color}`}}>{tailoring.description}</p>
                        </div>
                        <div className="flex justify-end items-end w-2/5">
                            <button className="btn font-light" style={{fontSize :`${tailor.ctaStyle.fontSize}px`, color: `${tailor.ctaStyle.color}`,
                                padding : `${tailor.ctaStyle.padding}px`, borderRadius : `${tailor.ctaStyle.borderRadius}px`,
                                backgroundColor : `${tailor.ctaStyle.backgroundColor}`}}>{tailor.ctaString}</button>
                        </div>
                    </div>
                    <div className="p-5 rounded-lg" style={{backgroundColor : `${tailor.tableStyle.backgroundColor}`}}>
                        <table className="w-full">
                            <thead className="">
                                <tr className="">
                                    <th className="w-2/5 text-left" style={{fontSize :`${tailor.tableStyle.titleFont}px`, color: `${tailor.tableStyle.titleColor}`}}
                                    >{tailoring.topicString}</th>
                                    {tailoring.importanceStrings.map((importance, idx) => {
                                        return(
                                            <th className="w-1/5 text-center" key={idx} style={{fontSize :`${tailor.tableStyle.titleFont}px`, color: `${tailor.tableStyle.titleColor}`}}
                                            >{importance}<br/>
                                                <span className="text-xs cursor-pointer" style={{color: `${tailor.ctaStyle.backgroundColor}`}}
                                                >{tailoring.selectAllString}</span>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                        </table>
                        <div className="divider divider-neutral"></div>
                        <table className="w-full mt-5">
                        <tbody className="mt-5">
                                {tailoring.questions.map((question : any, idx : number)=> {
                                    return(
                                        <tr style={{backgroundColor : `${(idx % 2 !== 0)? tailor.tableStyle.backgroundColor : `${tailor.tableStyle.backgroundColor} 50%`}`}}>
                                            <td className="p-5 w-2/5" style={{fontSize :`${tailor.tableStyle.textFont}px`, color: `${tailor.tableStyle.textColor}`}}
                                            >{question.question}</td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 3) ? "bg-green text-white" : "hover:bg-green hover:text-white opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{}}>
                                                <p className="flex justify-center items-center"><FaThumbsUp /></p>
                                            </td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 2) ? "bg-yellow" : "hover:bg-yellow opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{}}>
                                                <p className="flex justify-center items-center"><FaQuestion /></p>
                                            </td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 1) ? "bg-red text-white" : "hover:bg-red hover:text-white opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{}}>
                                                <p className="flex justify-center items-center"><FaThumbsDown /></p>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TailoringContent;