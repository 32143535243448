import React, { useEffect, useRef, useState } from "react";
import { TailoredStep } from "../../../Context";
import { FaThumbsDown } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa6";
import { FaQuestion } from "react-icons/fa";

interface Props {
    idx : number,
    tailored: TailoredStep,
    setTailored : Function
}

const TailoringStyle = ({idx, tailored, setTailored}:Props) : React.ReactElement => {
    const screenPRef = useRef<HTMLInputElement>(null);
    const bgColorRef = useRef<HTMLInputElement>(null);

    const titleSRef = useRef<HTMLInputElement>(null);
    const titleCRef = useRef<HTMLInputElement>(null);

    const descSRef = useRef<HTMLInputElement>(null);
    const descCRef = useRef<HTMLInputElement>(null);

    const ctaSRef = useRef<HTMLInputElement>(null);
    const ctaPRef = useRef<HTMLInputElement>(null);
    const ctaBRef = useRef<HTMLInputElement>(null);
    const ctaFCRef = useRef<HTMLInputElement>(null);
    const ctaBCRef = useRef<HTMLInputElement>(null);

    const headerSRef = useRef<HTMLInputElement>(null);
    const headerCRef = useRef<HTMLInputElement>(null);
    const topicSRef = useRef<HTMLInputElement>(null);
    const topicCRef = useRef<HTMLInputElement>(null);
    const tableBGRef = useRef<HTMLInputElement>(null);

    const [tailoringDraft, setTailoringDraft] = useState<any>(tailored);

    const updateChanges = () => {
        const newTailoring : TailoredStep = {
                header : tailored.header,
                description : tailored.description,
                ctaString : tailored.ctaString,
                topicString : tailored.topicString,
                selectAllString : tailored.selectAllString,
                importanceStrings : tailored.importanceStrings,
                questions : tailored.questions,
                textScreenStyle : {padding : screenPRef.current?.value, backgroundColor : bgColorRef.current?.value},
                h1Style : {fontSize : titleSRef.current?.value, color : titleCRef.current?.value},
                pStyle : {fontSize : descSRef.current?.value, color : descCRef.current?.value},
                ctaStyle : {fontSize : ctaSRef.current?.value, color : ctaFCRef.current?.value, padding : ctaPRef.current?.value, 
                            borderRadius : ctaBRef.current?.value, backgroundColor : ctaBCRef.current?.value},
                tableStyle : {titleFont : headerSRef.current?.value, titleColor : headerCRef.current?.value, 
                            textFont : topicSRef.current?.value, textColor : topicCRef.current?.value, backgroundColor : tableBGRef.current?.value}
                }
        setTailored(newTailoring);
        setTailoringDraft(newTailoring);
        sessionStorage.setItem(`TailoringQuesitons${idx}`, JSON.stringify(tailored));
    }

    useEffect(()=>{
        const sessionForm = sessionStorage.getItem(`TailoringQuesitons${idx}`);
        if(sessionForm){
            setTailoringDraft(JSON.parse(sessionForm));
        }
    }, []);

    return(
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                <div className="flex flex-wrap gap-5 p-2 mt-5">
                    <form onChange={()=>{updateChanges()}} className="w-[calc(33%-0.7rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Form Screen Styling</p>
                        <div className="flex flex-col justify-start items-start gap-3">
                            <div className="w-full">
                                <p>Padding</p>
                                <input type="number" ref={screenPRef} defaultValue={tailoringDraft.textScreenStyle.padding} placeholder="0px"
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-full">
                                <p>BG color</p>
                                <input type="color" ref={bgColorRef} value={tailoringDraft.textScreenStyle.color}
                                className="w-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(33%-0.7rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Header</p>
                        <div className="w-full">
                            <p>Font size</p>
                            <input type="number" placeholder="24px" ref={titleSRef} defaultValue={tailoringDraft.h1Style.fontSize}
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Color</p>
                            <input type="color" ref={titleCRef} value={tailoringDraft.h1Style.color}
                            className="w-full"/>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(33%-0.7rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Description</p>
                        <div className="w-full">
                            <p>Font size</p>
                            <input type="number" placeholder="18px" ref={descSRef} defaultValue={tailoringDraft.pStyle.fontSize}
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Color</p>
                            <input type="color" ref={descCRef} value={tailoringDraft.pStyle.color}
                            className="w-full"/>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-full p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">CTA Styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-1/5">
                                <p>Font size</p>
                                <input type="number" placeholder="18px" ref={ctaSRef} defaultValue={tailoringDraft.ctaStyle.fontSize}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Padding</p>
                                <input type="number" placeholder="5px" ref={ctaPRef} defaultValue={tailoringDraft.ctaStyle.padding}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Border radius</p>
                                <input type="number" placeholder="10px" ref={ctaBRef} defaultValue={tailoringDraft.ctaStyle.borderRadius}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Color</p>
                                <input type="color" ref={ctaFCRef} value={tailoringDraft.ctaStyle.color} className="w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>BG color</p>
                                <input type="color" ref={ctaBCRef} value={tailoringDraft.ctaStyle.backgroundColor} className="w-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-full p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Topic table styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-1/5">
                                <p>Header size</p>
                                <input type="number" placeholder="18px" ref={headerSRef} defaultValue={tailoringDraft.tableStyle.titleFont}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Header color</p>
                                <input type="color" ref={headerCRef} value={tailoringDraft.tableStyle.titleColor} className="w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Topic size</p>
                                <input type="number" placeholder="10px" ref={topicSRef} defaultValue={tailoringDraft.tableStyle.textFont}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Topic Color</p>
                                <input type="color" ref={topicCRef} value={tailoringDraft.tableStyle.textColor} className="w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>BG color</p>
                                <input type="color" ref={tableBGRef} value={tailoringDraft.tableStyle.backgroundColor} className="w-full"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="w-1/3 mt-5 p-2">
                <div className={`m-2`} style={{padding : `${tailoringDraft.textScreenStyle.padding}px`, backgroundColor : `${tailoringDraft.textScreenStyle.backgroundColor}`}}>
                    <div className={`text-${"left"} flex flex-col gap-3`}>
                        <div className="flex flex-row">
                            <div className="flex flex-col gap-5 w-3/5">
                                <p style={{fontSize :`${tailoringDraft.h1Style.fontSize}px`, color: `${tailoringDraft.h1Style.color}`}}>{tailored.header}</p>
                                <p style={{fontSize :`${tailoringDraft.pStyle.fontSize}px`, color: `${tailoringDraft.pStyle.color}`}}>{tailored.description}</p>
                            </div>
                            <div className="flex justify-end items-end w-2/5">
                                <button className="btn font-light"
                                style={{fontSize :`${tailoringDraft.ctaStyle.fontSize}px`, color: `${tailoringDraft.ctaStyle.color}`,
                                padding : `${tailoringDraft.ctaStyle.padding}px`, borderRadius : `${tailoringDraft.ctaStyle.borderRadius}px`,
                                backgroundColor : `${tailoringDraft.ctaStyle.backgroundColor}`}}>{tailored.ctaString}</button>
                            </div>
                        </div>
                        <div className="p-5 rounded-lg" style={{backgroundColor : `${tailoringDraft.tableStyle.backgroundColor}`}}>
                            <table className="w-full">
                                <thead className="">
                                    <tr className="">
                                        <th className="w-2/5 text-left" style={{fontSize :`${tailoringDraft.tableStyle.titleFont}px`, color: `${tailoringDraft.tableStyle.titleColor}`}}>
                                            {tailored.topicString}
                                        </th>
                                        {tailored.importanceStrings.map((importance, idx) => {
                                            return(
                                                <th className="w-1/5 text-center" key={idx} 
                                                style={{fontSize :`${tailoringDraft.tableStyle.titleFont}px`, color: `${tailoringDraft.tableStyle.titleColor}`}}>{importance}<br/>
                                                    <span className="text-xs cursor-pointer"
                                                    style={{color: `${tailoringDraft.ctaStyle.backgroundColor}`}}>
                                                        {tailored.selectAllString}
                                                    </span>
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                            </table>
                            <div className="divider divider-neutral"></div>
                            <table className="w-full mt-5">
                            <tbody className="mt-5">
                                    {tailored.questions.map((question : any, idx : number)=> {
                                        return(
                                            <tr style={{backgroundColor : `${(idx % 2 !== 0)? tailoringDraft.tableStyle.backgroundColor : `${tailoringDraft.tableStyle.backgroundColor} 50%`}`}}>
                                                <td className="p-5 w-2/5"
                                                style={{fontSize :`${tailoringDraft.tableStyle.textFont}px`, color: `${tailoringDraft.tableStyle.textColor}`}}>
                                                {question.question}</td>
                                                <td className={`w-1/5 text-center p-5 ${(question.importance === 3) ? "bg-green text-white" : "hover:bg-green hover:text-white opacity-70"} cursor-pointer transition-all`}
                                                onClick={()=>{}}>
                                                    <p className="flex justify-center items-center"><FaThumbsUp /></p>
                                                </td>
                                                <td className={`w-1/5 text-center p-5 ${(question.importance === 2) ? "bg-yellow" : "hover:bg-yellow opacity-70"} cursor-pointer transition-all`}
                                                onClick={()=>{}}>
                                                    <p className="flex justify-center items-center"><FaQuestion /></p>
                                                </td>
                                                <td className={`w-1/5 text-center p-5 ${(question.importance === 1) ? "bg-red text-white" : "hover:bg-red hover:text-white opacity-70"} cursor-pointer transition-all`}
                                                onClick={()=>{}}>
                                                    <p className="flex justify-center items-center"><FaThumbsDown /></p>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TailoringStyle;