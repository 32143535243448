import React, { useEffect, useRef, useState } from "react";
import { GrTextAlignCenter } from "react-icons/gr";
import { GrTextAlignLeft } from "react-icons/gr";
import { GrTextAlignRight } from "react-icons/gr";
import { VideoInterface } from "../../../Context";


interface StyleProps {
    video : VideoInterface,
    setVideo : Function,
    idx : number,
    saveToStorage : boolean,
}

const VideoStyle = ({video, setVideo, idx, saveToStorage} : StyleProps) : React.ReactElement => {
    const videoWRef = useRef<HTMLInputElement>(null);
    const videoRRef = useRef<HTMLInputElement>(null);
    const [align, setAlign] = useState<string>("left");
    const screenPRef = useRef<HTMLInputElement>(null);
    const bgColorRef = useRef<HTMLInputElement>(null);
    const titleSRef = useRef<HTMLInputElement>(null);
    const titleCRef = useRef<HTMLInputElement>(null);
    const descSRef = useRef<HTMLInputElement>(null);
    const descCRef = useRef<HTMLInputElement>(null);
    const ctaSRef = useRef<HTMLInputElement>(null);
    const ctaPRef = useRef<HTMLInputElement>(null);
    const ctaBRef = useRef<HTMLInputElement>(null);
    const ctaFCRef = useRef<HTMLInputElement>(null);
    const ctaBCRef = useRef<HTMLInputElement>(null);

    const [videoDraft, setVideoDraft] = useState<VideoInterface>(video);

    //Video preview variables
    const videoRef = useRef<HTMLVideoElement>(null);
    const [preview, setPreview] = useState({CTAscreen : video.CTAscreen, timeLeft : false , timeText : 0});

    const updateChanges = (alignStr? : string) => {
        const newVideo = {
            url : video.url,
            poster : video.poster,
            showTimeLeft : video.showTimeLeft,
            timeLeftPersentage :video.timeLeftPersentage,
            timeLeftText : video.timeLeftText,
            CTAscreen : video.CTAscreen,
            title : video.title,
            description : video.description,
            ctaText : video.ctaText, 
            videoStyle : {width : videoWRef.current?.value, borderRadius : videoRRef.current?.value},
            textScreenStyle : {textAlign : (alignStr)?alignStr:align, padding : screenPRef.current?.value, backgroundColor : bgColorRef.current?.value},
            h1Style : {fontSize : titleSRef.current?.value, color : titleCRef.current?.value},
            pStyle : {fontSize : descSRef.current?.value, color : descCRef.current?.value},
            ctaStyle : {fontSize : ctaSRef.current?.value, 
                color : ctaFCRef.current?.value, backgroundColor : ctaBCRef.current?.value, 
                padding : ctaPRef.current?.value, borderRadius : ctaBRef.current?.value
            },
        };
        setVideoDraft(newVideo);
        setVideo(newVideo);
        if(saveToStorage){sessionStorage.setItem(`video${idx}`, JSON.stringify(newVideo));}
    }

    const DisplayTimeLeft = () => {
        if(videoRef.current?.currentTime){
            if(videoRef.current.duration){
                if(videoDraft.timeLeftPersentage){
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 > videoDraft.timeLeftPersentage){
                        setPreview({...preview, timeLeft : true, timeText : Number((videoRef.current.duration - videoRef.current.currentTime).toFixed(0))});
                    }else{
                        setPreview({...preview, timeLeft : false, timeText : 0});
                    }
                }
            }
        }
    }

    useEffect(()=>{
        if(saveToStorage){
            const sessisonVideo = sessionStorage.getItem(`video${idx}`);
            if(sessisonVideo){
                setVideoDraft(JSON.parse(sessisonVideo));
                const parsedVideo = JSON.parse(sessisonVideo)
                if(parsedVideo.textScreenStyle){
                    setAlign(parsedVideo.textScreenStyle.textAlign);
                }
                setPreview({...preview, CTAscreen : parsedVideo.CTAscreen});
            }
        }
    }, []);

    return(
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                <div className="flex flex-wrap gap-5 p-2 mt-5">
                    <form onChange={()=>{updateChanges()}} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Video Styling</p>
                        <div className="w-full flex flex-rox justify-start items-center gap-3">
                            <div className="w-1/2">
                                <p>Video width {"(%)"}</p>
                                <input type="number" ref={videoWRef} defaultValue={videoDraft.videoStyle.width} placeholder="100%" max={100} min={0}
                                 className="input input-bordered rounded-full w-full" />
                            </div>
                            <div className="w-1/2">
                                <p>Border radius</p>
                                <input type="number" ref={videoRRef} defaultValue={videoDraft.videoStyle.borderRadius} placeholder="10px" max={100} min={0}
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">CTA Screen Styling</p>
                        <div className="w-full flex flex-row justify-start items-start gap-3">
                            <div className="w-1/3">
                                <p className="mb-2">Text align</p>
                                <div className="flex flex-row gap-2">
                                    <p className={`${(align === "left")?"text-green":"text-black"}`} onClick={()=>{setAlign("left"); updateChanges("left")}}>
                                        <GrTextAlignLeft className="xl:size-6 lg:size-5"/>
                                    </p>
                                    <p className={`${(align === "center")?"text-green":"text-black"}`} onClick={()=>{setAlign("center"); updateChanges("center")}}>
                                        <GrTextAlignCenter className="xl::size-6 lg:size-5"/>
                                    </p>
                                    <p className={`${(align === "right")?"text-green":"text-black"}`} onClick={()=>{setAlign("right"); updateChanges("right")}}>
                                        <GrTextAlignRight className="xl:size-6 lg:size-5"/>
                                    </p>
                                </div>
                            </div>
                            <div className="w-1/3">
                                <p>Padding</p>
                                <input type="number" ref={screenPRef} defaultValue={videoDraft.textScreenStyle.padding} placeholder="5px" className="w-full input input-bordered rounded-full"/>
                            </div>
                            <div className="w-1/3">
                                <p className="mb-2">BG color</p>
                                <input type="color" ref={bgColorRef} defaultValue={videoDraft.textScreenStyle.backgroundColor} className="rounded-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Title Styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-2/3">
                                <p>Title font size</p>
                                <input type="number" ref={titleSRef} defaultValue={(videoDraft.h1Style.fontSize)?videoDraft.h1Style.fontSize:24} placeholder="30px"
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/3">
                                <p>Color</p>
                                <input type="color" ref={titleCRef} defaultValue={(videoDraft.h1Style.color)?videoDraft.h1Style.color:""} 
                                className=" rounded-full w-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Description Styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-2/3">
                                <p>Description font size</p>
                                <input type="number" ref={descSRef} defaultValue={(videoDraft.pStyle.fontSize)?videoDraft.pStyle.fontSize:24} placeholder="24px"
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/3">
                                <p>Color</p>
                                <input type="color" ref={descCRef} defaultValue={(videoDraft.pStyle.color)?videoDraft.pStyle.color:""} 
                                className=" rounded-full w-full"/>
                            </div>
                        </div>
                    </form>
                    <form onChange={()=>{updateChanges()}} className="w-full p-5 flex flex-wrap gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">CTA styling</p>
                        <div className="flex flex-row justify-start items-start gap-3">
                            <div className="w-1/5">
                                <p>Font size</p>
                                <input type="number" ref={ctaSRef} defaultValue={(videoDraft.ctaStyle.fontSize)?videoDraft.ctaStyle.fontSize:18} placeholder="18px"
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Padding</p>
                                <input type="number" ref={ctaPRef} defaultValue={(videoDraft.ctaStyle.padding)?videoDraft.ctaStyle.padding:10} placeholder="10px"
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Border radius</p>
                                <input type="number" ref={ctaBRef} defaultValue={(videoDraft.ctaStyle.borderRadius)?videoDraft.ctaStyle.borderRadius:10} placeholder="10px"
                                className="input input-bordered rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>Color</p>
                                <input type="color" ref={ctaFCRef} defaultValue={(videoDraft.ctaStyle.color)?videoDraft.ctaStyle.color:""} 
                                className=" rounded-full w-full"/>
                            </div>
                            <div className="w-1/5">
                                <p>BG color</p>
                                <input type="color" ref={ctaBCRef} defaultValue={(videoDraft.ctaStyle.backgroundColor)?videoDraft.ctaStyle.backgroundColor:""}
                                className=" rounded-full w-full"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="w-1/3 mt-5 p-2">
                <div className="p-5 border-2 border-brandLightGray rounded-lg">
                    <p className="mb-2">Funnel Step Preview:</p>
                    {
                        (preview.CTAscreen)
                        ?<div className={`text-${videoDraft.textScreenStyle.textAlign}`} style={{
                            padding : `${videoDraft.textScreenStyle.padding}px`, 
                            backgroundColor :`${videoDraft.textScreenStyle.backgroundColor}`
                        }}>
                            {(videoDraft.title)?<p style={{fontSize : `${videoDraft.h1Style.fontSize}px`, color: `${videoDraft.h1Style.color}`}}>
                                                    {videoDraft.title}
                                                </p>:null}
                            {(videoDraft.description)?<p className="mt-5" style={{fontSize : `${videoDraft.pStyle.fontSize}px`, color: `${videoDraft.pStyle.color}`}}>
                                                        {videoDraft.description}
                                                      </p>:null}
                            {(videoDraft.ctaText)
                            ?<button className="mt-5" style={{
                                fontSize : `${videoDraft.ctaStyle.fontSize}px`, 
                                color: `${videoDraft.ctaStyle.color}`, backgroundColor :`${videoDraft.ctaStyle.backgroundColor}`,
                                padding:`${videoDraft.ctaStyle.padding}px`, borderRadius : `${videoDraft.ctaStyle.borderRadius}px`
                            }}
                            onClick={()=>{setPreview({...preview, CTAscreen:false})}}>
                                {videoDraft.ctaText}
                            </button>
                            :null}
                        </div>
                        :<div>
                            <video ref={videoRef} src={videoDraft.url} poster={videoDraft.poster} onTimeUpdate={DisplayTimeLeft} controls 
                            style={{width : `${videoDraft.videoStyle.width}%`, borderRadius : `${videoDraft.videoStyle.borderRadius}px`}}>
                            </video>
                            {(preview.timeLeft && videoDraft.showTimeLeft)
                            ?<p className="text-right">{`${videoDraft.timeLeftText} : ${preview.timeText}s`}</p>
                            :null
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default VideoStyle;