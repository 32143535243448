import React, { useState } from "react";
import { LeadMagnet } from "../../Context";
import { FaRegCopy } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";

interface Props {
    leadMagnet : LeadMagnet,
    sourceURL : string,
    funnelV2 : boolean,
}

const EmbedCode = ({leadMagnet, sourceURL, funnelV2}:Props) : React.ReactElement => {
    const [copied, setCopied] = useState<boolean>(false);

    let script : string = `
                <div class="cxtailor-embed" style="position:relative;padding-bottom:calc(56.5%);width:100%;height:0;transform:scale(1)">
                    <iframe scrolling="auto" loading="lazy" class="cxtailor-funnel" src="${sourceURL}/${(funnelV2)?"sharedFunnel":"funnel"}/${(leadMagnet)? leadMagnet._id :""}?embed=true" name="sl-embed" allow="fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%!important;height:100%!important;box-shadow: 0px 0px 18px rgba(26, 19, 72, 0.15);border-radius:10px;box-sizing:border-box;"></iframe>
                 </div>`;

    return(
        <div className="">
            {
                (leadMagnet)
                ?<div className="p-5 bg-brandLightGray rounded-xl shadow-md">
                    <div className="flex flex-row justify-start items-center mb-5">
                        <p className="text-xl w-1/2">Embed code for your website</p>
                        <div className="flex justify-end w-1/2">
                            <button onClick={()=>{
                                navigator.clipboard.writeText(script); 
                                setCopied(true); 
                                setTimeout(()=>{setCopied(false)}, 4000)}} 
                            className="btn bg-brandPurple text-white rounded-full font-light hover:bg-brandLightPurple">
                                <FaRegCopy className="size-5"/>Copy to clipboard
                            </button>
                        </div>
                    </div>
                    <textarea rows={23} className="w-full h-50 text-xs textarea textarea-bordered bg-white"
                    value={script}/>
                    {(copied)
                    ?<div className="toast transition-all">
                        <div role="alert" className="alert bg-green text-white">
                            <p className="flex justify-start items-center"><FaCheck className="size-5 mr-2"/>Script has been copied to your clipboard</p>
                        </div>
                    </div>
                    :null
                    }
                </div>
                :<div className="skeleton h-96 w-full"></div>
            }
        </div>
    );
};

export default EmbedCode;