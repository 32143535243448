import React from "react";
import VideoStep from "./VideoScreen/VideoStep";
import FormStep from "./FormPage/FormStep";
import TextStep from "./TextScreen/TextScreenStep";
import EndStep from "./ThankYouPage/EndStep";
import { Step } from "../../Context";
import TailoringStep from "./TailoredDemo/TailoringStep";

interface Props {
    steps : Step[],
    setSteps : Function,
    setEndScreenAdded : Function 
}

const FunnelBuilder = ({ steps, setSteps, setEndScreenAdded} : Props) : React.ReactElement => {

    return(
        <div className="w-full h-full">
            {steps.map((step : any, idx : number) => {
            
                if(step.action === "video"){
                    return<div className="flex flex-col justify-center items-center w-full">
                            <VideoStep setSteps={setSteps} steps={steps} idx={idx} step={step} editOnly={false}/>
                            <div className="h-20 w-1 bg-brandPurple"></div>
                        </div>
                }
                if(step.action === "form"){
                    return<div className="flex flex-col justify-center items-center w-full">
                            <FormStep setSteps={setSteps} steps={steps} step={step} idx={idx} editOnly={false}/>
                            <div className="h-20 w-1 bg-brandPurple"></div>
                            </div>
                }
                if(step.action === "textScreen"){
                    return<div className="flex flex-col justify-center items-center w-full">
                            <TextStep setSteps={setSteps} steps={steps} step={step} idx={idx} editOnly={false}/>
                            <div className="h-20 w-1 bg-brandPurple"></div>
                            </div>
                }
                if(step.action === "tailored"){
                    return<div className="flex flex-col justify-center items-center w-full">
                            <TailoringStep setSteps={setSteps} steps={steps} step={step} idx={idx} editOnly={false}/>
                            <div className="h-20 w-1 bg-brandPurple"></div>
                            </div>
                }
                if(step.action === "end screen"){
                    return <div className="flex flex-col justify-center items-center w-full">
                                <EndStep setSteps={setSteps} steps={steps} step={step} idx={idx} setEndScreenAdded={setEndScreenAdded} editOnly={false}/>
                            </div>
                }
                return null
            })}
        </div>
    );
};

export default FunnelBuilder;