import React from "react";
import { ChromePicker } from "react-color";
import { Step } from "../../Context";
import { GrTextAlignCenter, GrTextAlignLeft, GrTextAlignRight } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";

interface DeleteProps {
    steps : Step[],
    setSteps : Function
    deleteId : number,
    selectedStep : Step,
    selectedStepNumber : number,
}

const DeleteCTA = ( { steps, setSteps, deleteId, selectedStep, selectedStepNumber } : DeleteProps) : React.ReactElement => {
    return(
        <div className="dropdown dropdown-hover dropdown-left flex justify-end items-center">
            <p tabIndex={0} role="button" className="text-brandPurple cursor-pointer text-lg"><BsThreeDotsVertical /></p>
            <ul tabIndex={0} className="dropdown-content menu z-10 p-2 bg-white shadow-md rounded-lg w-fit">
                <p className="w-32 flex flex-row justify-start items-center gap-2 text-red cursor-pointer" onClick={()=>{
                    let ctas = JSON.parse(JSON.stringify(selectedStep.thankYouPage!.ctaList));
                    const newCtas = ctas.filter((cta : any) => cta !== ctas[deleteId]);
                    let ctaStyles = JSON.parse(JSON.stringify(selectedStep.thankYouPage!.ctaList));
                    const newCtaStyles = ctaStyles.filter((ctaStyle : any) => ctaStyle !== ctaStyles[deleteId]);

                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                        if(idx === selectedStepNumber){
                            step.thankYouPage!.ctaList = newCtas;
                            step.thankYouPage!.ctaStyle = newCtaStyles;
                            return step
                        }else{
                            return step
                        }
                    })
                    setSteps(newSteps);
                }}>
                   <MdDeleteForever/> Delete CTA
                </p>
            </ul>
        </div>
    );
}

interface Props{
    selectedStep : Step,
    selectedStepNumber : number,
    steps : Step[],
    setSteps : Function,
}

export default function EndingEditor ( { selectedStep, selectedStepNumber, steps, setSteps } : Props ) : React.ReactElement {

    const updateTextValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.thankYouPage!.h1 = (event.currentTarget.elements.namedItem("title") as HTMLInputElement).value
                step.thankYouPage!.description = (event.currentTarget.elements.namedItem("desc") as HTMLInputElement).value
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }
    const updateFormStyleValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.thankYouPage!.textScreenStyle.gap = Number((event.currentTarget.elements.namedItem("gap") as HTMLInputElement).value);
                step.thankYouPage!.textScreenStyle.backgroundImage = (event.currentTarget.elements.namedItem("bg-img") as HTMLInputElement).value;
                step.thankYouPage!.h1Style.fontSize = Number((event.currentTarget.elements.namedItem("title-size") as HTMLInputElement).value);
                step.thankYouPage!.pStyle.fontSize = Number((event.currentTarget.elements.namedItem("desc-size") as HTMLInputElement).value);
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateCTAvalues = (event : React.FormEvent<HTMLFormElement>, id : number) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.thankYouPage!.ctaList![id].text = (event.currentTarget.elements.namedItem("cta-text") as HTMLInputElement).value;
                step.thankYouPage!.ctaList![id].url = (event.currentTarget.elements.namedItem("redirect-url") as HTMLInputElement).value;
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateCtaStyles = (event : React.FormEvent<HTMLFormElement>, id : number) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.thankYouPage!.ctaStyle[id].fontSize = Number((event.currentTarget.elements.namedItem("cta-size") as HTMLInputElement).value);
                step.thankYouPage!.ctaStyle[id].padding = Number((event.currentTarget.elements.namedItem("cta-padding") as HTMLInputElement).value);
                step.thankYouPage!.ctaStyle[id].borderRadius = Number((event.currentTarget.elements.namedItem("cta-border") as HTMLInputElement).value);
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const addNewCTA = () => {
        let newCTAs = JSON.parse(JSON.stringify(selectedStep.thankYouPage!.ctaList));
        let newCTAStyles = JSON.parse(JSON.stringify(selectedStep.thankYouPage!.ctaStyle));

        newCTAs.push({text : `CTA ${newCTAs.length}`, url : ""});
        newCTAStyles.push({fontSize : "1.125", color : "#ffffff", padding :"1", borderRadius : "10", backgroundColor : "#6156a5"});

        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.thankYouPage!.ctaList = newCTAs;
                step.thankYouPage!.ctaStyle = newCTAStyles;
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    return(
         <div className="p-5 flex flex-col gap-5 h-full">
                    <div className="w-full flex gap-2 flex-wrap">
                        <form className="w-full flex flex-col flex-wrap gap-5" onChange={updateTextValues}>
                            <p className="text-lg">Text content</p>
                            <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                                <label className="w-full text-sm">Title</label>
                                <input type="url" defaultValue={selectedStep.thankYouPage!.h1} name="title" className="w-full input input-bordered text-sm input-md"/>
                            </div>
                            <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                                <label className="w-full text-sm">Description</label>
                                <input type="text" defaultValue={selectedStep.thankYouPage!.description} name="desc" className="w-full input input-bordered text-sm input-md"/>
                            </div>
                        </form>
                        <div className="divider w-full"></div>                     
                        <div className="w-full flex flex-col flex-wrap gap-7">
                            <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                <p className="text-lg w-1/2">Call To Actions</p>
                                <p className="w-1/2 cursor-pointer flex flex-row justify-end items-center gap-2 hover:underline decoration-green"
                                onClick={addNewCTA}>
                                    <FaPlus className="size-4 text-green"/> Add new field
                                </p>
                            </div>
                            {selectedStep.thankYouPage!.ctaList!.map((cta : any, idx : number) => {
                                return(
                                    <fieldset className="fieldset bg-brandLightGray border border-brandLightPurple p-4 rounded-box relative">
                                        <legend className="fieldset-legend bg-brandLightGray rounded-lg p-1 border border-brandLightPurple text-sm">CTA - {idx + 1}</legend>
                                        <div className="absolute top-0 right-5">
                                            <DeleteCTA selectedStep={selectedStep} steps={steps} setSteps={setSteps} deleteId={idx} selectedStepNumber={selectedStepNumber}/>
                                        </div>
                                        <form className="flex flex-col justify-start items-center gap-3" onChange={(event) => {updateCTAvalues(event, idx);}}>
                                            <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                                                <label className="w-full text-sm">Text</label>
                                                <input type="text" value={cta.text} name="cta-text" className="w-full input input-bordered text-sm input-md"/>
                                            </div>
                                            <div className={`flex flex-col justify-start items-center flex-wrap gap-2 w-full`}>
                                                <label className="w-full text-sm">Redirect URL</label>
                                                <input type="url" value={cta.url} placeholder="https://cxtailor.com" name="redirect-url" 
                                                className="w-full input input-bordered text-sm input-md"/>
                                            </div>
                                        </form>
                                    </fieldset>
                                );
                            })}
                        </div>
                        <div className="divider w-full"></div>
                        <form className="w-full flex flex-col flex-wrap gap-7" onChange={updateFormStyleValues}>
                            <p className="text-lg">Styling</p>
                            <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                <label className="w-[70%] text-sm">Background color</label>
                                <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                    <div className="tooltip tooltip-left" data-tip={`rgba(
                                        ${selectedStep.thankYouPage!.textScreenStyle.backgroundColor.r},
                                        ${selectedStep.thankYouPage!.textScreenStyle.backgroundColor.g},
                                        ${selectedStep.thankYouPage!.textScreenStyle.backgroundColor.b},
                                        ${selectedStep.thankYouPage!.textScreenStyle.backgroundColor.a}
                                        )`}>
                                        <div tabIndex={0} className="w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray" 
                                        style={{backgroundColor : 
                                        `rgba(
                                        ${selectedStep.thankYouPage!.textScreenStyle.backgroundColor.r},
                                        ${selectedStep.thankYouPage!.textScreenStyle.backgroundColor.g},
                                        ${selectedStep.thankYouPage!.textScreenStyle.backgroundColor.b},
                                        ${selectedStep.thankYouPage!.textScreenStyle.backgroundColor.a}
                                        )`}}></div>
                                    </div>
                                    <ul tabIndex={0} className="dropdown-content menu z-10">
                                        <ChromePicker color={selectedStep.thankYouPage!.textScreenStyle.backgroundColor} 
                                        onChange={(event) => {
                                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                                if(idx === selectedStepNumber){
                                                    step.thankYouPage!.textScreenStyle.backgroundColor = event.rgb;
                                                    return step
                                                }else{
                                                    return step
                                                }
                                            })
                                            setSteps(newSteps);
                                        }}/>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                                <label className="w-full text-sm">Background image URL</label>
                                <input type="url" defaultValue={selectedStep.thankYouPage!.textScreenStyle.backgroundImage} name="bg-img" 
                                className="w-full input input-bordered text-sm input-md"/>
                            </div>
                            <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                <label className="w-1/2 text-sm">Text align</label>
                                <div className="join w-1/2 flex justify-end items-center">
                                    <button type="button" className={`join-item btn text-lg btn-sm
                                        ${(selectedStep.thankYouPage!.textScreenStyle.textAlign === "start")
                                        ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                        :""}`} onClick={()=>{
                                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                                if(idx === selectedStepNumber){
                                                    selectedStep.thankYouPage!.textScreenStyle.textAlign = "start";
                                                    return step
                                                }else{
                                                    return step
                                                }
                                            })
                                            setSteps(newSteps);
                                        }}>
                                        <GrTextAlignLeft />
                                    </button>
                                    <button type="button" className={`join-item btn text-lg btn-sm
                                        ${(selectedStep.thankYouPage!.textScreenStyle.textAlign === "center")
                                        ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                        :""}`} onClick={()=>{
                                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                                if(idx === selectedStepNumber){
                                                    selectedStep.thankYouPage!.textScreenStyle.textAlign = "center";
                                                    return step
                                                }else{
                                                    return step
                                                }
                                            })
                                            setSteps(newSteps);
                                        }}>
                                        <GrTextAlignCenter />
                                    </button>
                                    <button type="button" className={`join-item btn text-lg btn-sm
                                        ${(selectedStep.thankYouPage!.textScreenStyle.textAlign === "end")
                                        ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                        :""}`} onClick={()=>{
                                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                                if(idx === selectedStepNumber){
                                                    selectedStep.thankYouPage!.textScreenStyle.textAlign = "end";
                                                    return step
                                                }else{
                                                    return step
                                                }
                                            })
                                            setSteps(newSteps);
                                        }}>
                                        <GrTextAlignRight />
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                <label className="w-2/3 text-sm">Element spacing</label>
                                <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                                    <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.thankYouPage!.textScreenStyle.gap} name="gap" className="join-item w-2/3 p-2"/>
                                    <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                                </label>
                            </div>
                            <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                <label className="w-2/3 text-sm">Title size</label>
                                <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                                    <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.thankYouPage!.h1Style.fontSize} name="title-size" className="join-item w-2/3 p-2"/>
                                    <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                                </label>
                            </div>
                            <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                <label className="w-[70%] text-sm">Title color</label>
                                <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                    <div className="tooltip tooltip-left" data-tip={`${selectedStep.thankYouPage!.h1Style.color}`}>
                                        <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                        style={{backgroundColor : `${selectedStep.thankYouPage!.h1Style.color}`}}></div>
                                    </div>
                                    <ul tabIndex={0} className="dropdown-content menu z-10">
                                        <ChromePicker color={selectedStep.thankYouPage!.h1Style.color} 
                                        onChange={(event) => {
                                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                                if(idx === selectedStepNumber){
                                                    step.thankYouPage!.h1Style.color = event.hex;
                                                    return step
                                                }else{
                                                    return step
                                                }
                                            })
                                            setSteps(newSteps);
                                        }}/>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                <label className="w-2/3 text-sm">Description size</label>
                                <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                                    <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.thankYouPage!.pStyle.fontSize} name="desc-size" className="join-item w-2/3 p-2"/>
                                    <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                                </label>
                            </div>
                            <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                <label className="w-[70%] text-sm">Description color</label>
                                <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                    <div className="tooltip tooltip-left" data-tip={`${selectedStep.thankYouPage!.pStyle.color}`}>
                                        <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                        style={{backgroundColor : `${selectedStep.thankYouPage!.pStyle.color}`}}></div>
                                    </div>
                                    <ul tabIndex={0} className="dropdown-content menu z-10">
                                        <ChromePicker color={selectedStep.thankYouPage!.pStyle.color} 
                                        onChange={(event) => {
                                            const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                                if(idx === selectedStepNumber){
                                                    step.thankYouPage!.pStyle.color = event.hex;
                                                    return step
                                                }else{
                                                    return step
                                                }
                                            })
                                            setSteps(newSteps);
                                        }}/>
                                    </ul>
                                </div>
                            </div>
                        </form>
                        {selectedStep.thankYouPage!.ctaStyle!.map((styling : any, idx : number) => {
                            return(
                                <>
                                <div className="divider w-full"></div>
                                <form className="w-full flex flex-col flex-wrap gap-7" onChange={(event) => {updateCtaStyles(event, idx)}} key={idx}>
                                    <p className="text-lg">CTA - {idx + 1} Styling</p>
                                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                        <label className="w-2/3 text-sm">CTA font size</label>
                                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                                            <input type="number" min={0} max={100} step={0.1} value={selectedStep.thankYouPage!.ctaStyle[idx].fontSize} name="cta-size" className="join-item w-2/3 p-2"/>
                                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                                        </label>
                                    </div>
                                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                        <label className="w-[70%] text-sm">CTA font color</label>
                                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.thankYouPage!.ctaStyle[idx].color}`}>
                                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                                style={{backgroundColor : `${selectedStep.thankYouPage!.ctaStyle[idx].color}`}}></div>
                                            </div>
                                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                                <ChromePicker color={selectedStep.thankYouPage!.ctaStyle[idx].color} 
                                                onChange={(event) => {
                                                    const newSteps : Step[] = steps.map((step : Step, id : number) => {
                                                        if(id === selectedStepNumber){
                                                            step.thankYouPage!.ctaStyle[idx].color = event.hex;
                                                            return step
                                                        }else{
                                                            return step
                                                        }
                                                    })
                                                    setSteps(newSteps);
                                                }}/>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                        <label className="w-[70%] text-sm">CTA background color</label>
                                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.thankYouPage!.ctaStyle[idx].backgroundColor}`}>
                                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                                style={{backgroundColor : `${selectedStep.thankYouPage!.ctaStyle[idx].backgroundColor}`}}></div>
                                            </div>
                                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                                <ChromePicker color={selectedStep.thankYouPage!.ctaStyle[idx].backgroundColor} 
                                                onChange={(event) => {
                                                    const newSteps : Step[] = steps.map((step : Step, id : number) => {
                                                        if(id === selectedStepNumber){
                                                            step.thankYouPage!.ctaStyle[idx].backgroundColor = event.hex;
                                                            return step
                                                        }else{
                                                            return step
                                                        }
                                                    })
                                                    setSteps(newSteps);
                                                }}/>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                        <label className="w-2/3 text-sm">CTA padding</label>
                                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                                            <input type="number" min={0} max={100} step={0.25} value={selectedStep.thankYouPage!.ctaStyle[idx].padding} name="cta-padding" className="join-item w-2/3 p-2"/>
                                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                                        </label>
                                    </div>
                                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                                        <label className="w-2/3 text-sm">CTA border radius</label>
                                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 text-sm input-sm">
                                            <input type="number" min={0} max={100} value={selectedStep.thankYouPage!.ctaStyle[idx].borderRadius} name="cta-border" className="join-item w-2/3 p-2"/>
                                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">px</span>
                                        </label>
                                    </div>
                                </form>
                                </>
                            );
                        })}
                    </div>
                </div>
    );
}