import React, { useContext } from 'react';
import './App.css';
import { Context } from './components/Context';
import { Route, Routes } from 'react-router-dom';
import SignInPage from './pages/NotLogged/SingIn';
import UserPageStructure from './components/general/UserPageStructure';
import HomeDashboard from './pages/LoggedIn/HomeDashboard';
import SequenceList from './pages/LoggedIn/Sequences';
import MagnetInfo from './pages/LoggedIn/MagnetInfo';
import CreateNewMagnet from './pages/LoggedIn/CreateNewMagnet';
import PreviewAndEmbed from './pages/LoggedIn/PreviewAndEmbed';
import EditMagnet from './pages/LoggedIn/EditMagnet';
import SettingsPage from './pages/LoggedIn/Settings';
import RegisterPage from './pages/NotLogged/Register';
import ABtest from './pages/LoggedIn/ABtest';
import { PublicContextProvider } from './components/PublicContext';
import Demo from './pages/NotLogged/Demo';
import NewFunnelProto from './pages/NotLogged/NewFunnelProto';
import FunnelBuilderV2 from './pages/LoggedIn/FunnelBuilderV2';
import EditFunnelV2 from './pages/LoggedIn/EditFunnelV2';
//import DemoTest from './pages/NotLogged/DemoTest';

export const UserRoutes = () : React.ReactElement => {
  return(
    <Routes>
      <Route path='/' element={<HomeDashboard/>}/>
      <Route path='/sequences' element={<SequenceList />} />
      <Route path='/sequences/info' element={<MagnetInfo />}/>
      <Route path='/sequences/addnew' element={<CreateNewMagnet />}/>
      <Route path='/sequences/newFunnel' element={<FunnelBuilderV2 />} />
      <Route path='/sequences/preview' element={<PreviewAndEmbed/>}/>
      <Route path='/sequences/edit' element={<EditMagnet />}/>
      <Route path='/sequences/editFunnel' element={<EditFunnelV2 />}/>
      <Route path='/sequences/ab-test' element={<ABtest />} />
      <Route path='/settings' element={<SettingsPage />}/>
    </Routes>
  );
}

const App : React.FC = () : React.ReactElement =>{
  const { userToken } = useContext(Context);
  return (
    <div>
      <Routes>
        <Route path='/funnel/:id' element={<PublicContextProvider><Demo/></PublicContextProvider>}/>
        <Route path='/sharedFunnel/:id' element={<PublicContextProvider><NewFunnelProto/></PublicContextProvider>}/>
      </Routes>
      {
      (userToken)
      ?<UserPageStructure />
      :<>
        <Routes>
          <Route path='/login' element={<SignInPage />}/>
          <Route path='/register' element={<RegisterPage />}/>
        </Routes>
      </>
      }
    </div>
  );
}

export default App;
