import React, { useContext, useEffect, useState } from "react";
import { Context, Step } from "../../components/Context";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import StepSelectionModal from "../../components/user/MagnetCreation/StepSelectionModal";
import VideoStep from "../../components/user/MagnetCreation/VideoScreen/VideoStep";
import FormStep from "../../components/user/MagnetCreation/FormPage/FormStep";
import EndStep from "../../components/user/MagnetCreation/ThankYouPage/EndStep";
import TextStep from "../../components/user/MagnetCreation/TextScreen/TextScreenStep";
import TailoringStep from "../../components/user/MagnetCreation/TailoredDemo/TailoringStep";

const EditMagnet = () : React.ReactElement => {
    const { userToken, selectedMagnet, steps, setSteps, editFormSteps} = useContext(Context);
    const [nameEditor, setNameEditor] = useState<boolean>(false);
    const [addNewHover, setAddNewHover] = useState<boolean>(false);
    const [name, setName] = useState<string>((selectedMagnet)?selectedMagnet.form_name:undefined);
    const [showStepSelection, setShowStepSelection] = useState<boolean>(false);
    const [endScreenAdded, setEndScreenAdded] = useState<boolean>(false);
        
    const getFromStorage = () : void => {
        const sessionName : string | null = sessionStorage.getItem("newMagnet-name");
        if(sessionName){
            setName(JSON.parse(sessionName));
        }
        const sessisonSteps : string | null = sessionStorage.getItem("steps");
        if(sessisonSteps){
            setSteps(JSON.parse(sessisonSteps));
            const parsedSteps : Step[] = JSON.parse(sessisonSteps);
            parsedSteps.forEach((step : Step) => {
                if(step.action === "end screen"){
                    setEndScreenAdded(true);
                }
            })
        }
    }

    useEffect(()=>{
        getFromStorage();
    }, []);

    return(
        <div className="h-full">
             <div className="flex flex-row gap-5 p-5 justify-start items-center w-full z-10 sticky shadow-md bg-brandLightGray">
                <div className="w-1/2 flex flex-row justify-start items-center gap-5">
                    <Link to="/sequences/info" className="flex flex-row justify-start items-center text-lg text-brandPurple cursor-pointer"
                    onClick={()=>{sessionStorage.clear();}}>
                        <MdArrowBackIosNew className="size-4"/>Cancel & Exit
                    </Link>
                    <div className="">
                        {
                            (nameEditor)
                            ?<div className="flex flex-row justify-start items-center gap-3">
                                <input type="text" value={name} className="input input-bordered w-fit border-brandLightGray"
                                placeholder={`New Lead Magnet - ${new Date().toLocaleDateString("fi-FI")}`} 
                                onChange={(e)=>{setName(e.target.value as string); sessionStorage.setItem("newMagnet-name", JSON.stringify(e.target.value as string))}}/>
                                <button className="btn border-red font-light text-red hover:text-white hover:bg-red bg-white rounded-2xl" 
                                onClick={()=>{setNameEditor(false);}}>Cancel</button>
                                <button className="btn bg-brandPurple text-white font-light hover:bg-brandLightPurple rounded-2xl"
                                onClick={()=>{setNameEditor(false);}}
                                >Save</button>
                            </div>
                            :<p 
                            className="text-lg flex flex-row justify-start items-center hover:border-brandLightPurple hover:border-2 p-2 border-2 border-brandLightGray cursor-pointer rounded-xl" 
                            onClick={()=>{setNameEditor(true)}}>
                                {(selectedMagnet)?selectedMagnet.form_name:`New Lead Magnet - ${new Date().toLocaleDateString("fi-FI")}`} <MdEdit className="size-4 ml-2"/>
                            </p>
                        }
                    </div>
                </div>
                <div className="w-1/2 flex justify-end">
                    {(steps && steps.length)
                    ?<button className="btn font-light bg-brandPurple text-white hover:bg-brandLightPurple rounded-full"
                     onClick={()=>{editFormSteps(selectedMagnet._id, userToken, steps)}}
                     disabled={!name && steps.length === 0 && (!steps[0].video || !steps[0].form || !steps[0].thankYouPage)}>
                        Save and continue
                    </button>
                    :<button className="btn font-light bg-brandPurple text-white hover:bg-brandLightPurple rounded-full" disabled>
                        Save and continue
                    </button>
                    }
                </div>
            </div>
            <div className="w-full h-[full] bg-white flex flex-col pt-20 pb-20 p-10 justify-center items-center">
                <div className="flex flex-col justify-center items-center w-full">
                    <p>Start</p>
                    <FaCircle className="size-8 text-brandPurple m-0"/>
                    <div className="h-10 w-1 bg-brandPurple"></div>
                </div>
                {
                    (steps && steps.length > 0)
                    ?<>
                    {steps.map((step : any, idx : number) => {
                        if(step.action === "video"){
                            return<div className="flex flex-col justify-center items-center w-full">
                                    <VideoStep setSteps={setSteps} steps={steps} idx={idx} step={step} editOnly={false}/>
                                    <div className="h-20 w-1 bg-brandPurple"></div>
                                </div>
                        }
                        if(step.action === "form"){
                            return<div className="flex flex-col justify-center items-center w-full">
                                    <FormStep setSteps={setSteps} steps={steps} step={step} idx={idx} editOnly={false}/>
                                    <div className="h-20 w-1 bg-brandPurple"></div>
                                  </div>
                        }
                        if(step.action === "tailored"){
                            return<div className="flex flex-col justify-center items-center w-full">
                                    <TailoringStep setSteps={setSteps} steps={steps} step={step} idx={idx} editOnly={false}/>
                                    <div className="h-20 w-1 bg-brandPurple"></div>
                                    </div>
                        }
                        if(step.action === "textScreen"){
                            return<div className="flex flex-col justify-center items-center w-full">
                                    <TextStep setSteps={setSteps} steps={steps} step={step} idx={idx} editOnly={false}/>
                                    <div className="h-20 w-1 bg-brandPurple"></div>
                                  </div>
                        }
                        if(step.action === "end screen"){
                            return<div className="flex flex-col justify-center items-center w-full">
                                    <EndStep setSteps={setSteps} steps={steps} step={step} idx={idx} setEndScreenAdded={setEndScreenAdded} editOnly={false}/>
                                  </div>
                        }
                        return null
                    })}
                    </>
                    :null
                }  
                {
                    (endScreenAdded)
                    ?null
                    :<StepSelectionModal 
                    addNewHover={addNewHover} setShowStepSelection={setShowStepSelection} 
                    setAddNewHover={setAddNewHover} showStepSelection={showStepSelection}
                    setEndScreenAdded={setEndScreenAdded} endScreenAdded={endScreenAdded}
                    />
                }
            </div>
        </div>
    );
};

export default EditMagnet;