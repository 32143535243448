import React, { useContext, useState } from "react";
import { Context, Step } from "../../components/Context";
import { MdArrowBackIosNew, MdEdit } from "react-icons/md";
import { FaMobileScreen } from "react-icons/fa6";
import { IoDesktopOutline } from "react-icons/io5";
import StepSelectionDropDown from "../../components/user/FunnelBuilderV2/StepSelectionDropDown";
import EditorSelection from "../../components/user/FunnelBuilderV2/EditorSelection";
import StepPreview from "../../components/user/FunnelBuilderV2/StepPreview";
import VideoEditor from "../../components/user/FunnelBuilderV2/VideoEditor";
import FormEditor from "../../components/user/FunnelBuilderV2/FormEditor";
import EndingEditor from "../../components/user/FunnelBuilderV2/EndingEditor";
import TextScreenEditor from "../../components/user/FunnelBuilderV2/TextScreenEditor";
import { Link } from "react-router-dom";

const EditFunnelV2 = () : React.ReactElement => {
    const {userToken, selectedMagnet, steps, setSteps, editFormSteps} = useContext(Context);
    const [funnelName, setFunnelName] = useState<string>(selectedMagnet.form_name);
    const [editName, setEditName] = useState<boolean>(false);
    const [editStep, setEditStep] = useState<boolean>(false); 

    const [selectedStep, setSelectedStep] = useState<number>(0);
    const [mobileView, setMobileView] = useState<boolean>(false);

    const [endingAdded, setEndingAdded] = useState<boolean>(false);

    const handleAddNew = () => {
        editFormSteps(selectedMagnet._id, userToken, steps);
    }

    return(
        <div className="flex flex-row flex-wrap w-full h-dvh items-start justify-start">
            <div className="flex flex-row w-full z-20 items-center justify-start p-5 gap-5 bg-white shadow-md h-20 relative">
                <Link to="/sequences/info" className="flex flex-row justify-start items-center text-lg text-brandPurple cursor-pointer"
                onClick={()=>{sessionStorage.clear();}}>
                    <MdArrowBackIosNew className="size-4"/>Cancel & Exit
                </Link>
                {
                    (editName)
                    ?<div className="join w-[25%] max-md:w-[70%]">
                        <input className="input input-bordered join-item w-[100%] rounded-full" value={funnelName} onChange={(e)=> {setFunnelName(e.target.value as string);}}/>
                        <button className="btn join-item bg-brandPurple rounded-full font-light text-white hover:bg-brandLightPurple" 
                        onClick={()=>{setEditName(false);}}>Save</button>
                    </div>
                    :<p className="text-lg max-md:text-sm flex flex-row justify-start items-center" onClick={()=>{setEditName(true);}}>{funnelName} <MdEdit className="size-4 ml-2"/></p>
                }
                <button className={`btn bg-brandPurple rounded-full absolute right-5 max-md:text-sm text-white font-light`}
                disabled={steps.length === 0} onClick={handleAddNew}>
                    Publish changes
                </button>
            </div>
            <div className="w-1/4 max-md:w-full flex flex-col h-[calc(100%-5rem)] max-md:h-[10%] shadow-lg overflow-y-scroll">
                {
                    (editStep)
                    ?<div className="bg-white">
                        <div className="w-full p-5 flex flex-row flex-wrap justify-end border-b border-b-brandLightGray z-10 sticky top-0 items-center bg-white">
                            <p className="w-1/2">Editing <span className="text-brandPurple">Step {selectedStep + 1} </span></p>
                            <p className="w-1/2 text-right text-brandPurple underline cursor-pointer" onClick={()=>{setEditStep(false);}}>Return back</p>
                        </div>
                        {steps.map((step : Step, idx : number) => {
                            if(step.action === "video" && selectedStep === idx){
                                return(
                                    <VideoEditor steps={steps} setSteps={setSteps} selectedStep={step} selectedStepNumber={selectedStep}/>
                                )
                            }
                            if(step.action === "form" && selectedStep === idx){
                                return(
                                    <div>
                                        <FormEditor steps={steps} setSteps={setSteps} selectedStep={step} selectedStepNumber={selectedStep}/>
                                    </div>
                                )
                            }
                            if(step.action === "textScreen" && selectedStep === idx){
                                return(
                                    <div>
                                        <TextScreenEditor steps={steps} setSteps={setSteps} selectedStep={step} selectedStepNumber={selectedStep} />
                                    </div>
                                )
                            }
                            if(step.action === "end screen" && selectedStep === idx){
                                return(
                                    <div>
                                        <EndingEditor steps={steps} setSteps={setSteps} selectedStep={step} selectedStepNumber={selectedStep}/>
                                    </div>
                                )
                            }
                            return null;
                        })}
                    </div>
                    :<>
                        <div className="w-full p-5 flex flex-row flex-wrap justify-start items-center border-b border-brandLightGray">
                            <p className="w-1/2">Funnel builder</p>
                            <StepSelectionDropDown steps={steps} setSteps={setSteps} setSelectedStep={setSelectedStep} endingAdded={endingAdded} setEndingAdded={setEndingAdded}/>
                        </div>
                        <EditorSelection steps={steps} setSteps={setSteps} selectedStep={selectedStep} setSelectedStep={setSelectedStep} setEditStep={setEditStep}
                        endingAdded={endingAdded} setEndingAdded={setEndingAdded}/>
                    </>
                }
            </div>
            <div className="w-3/4 max-md:w-full flex flex-wrap gap-5 justify-center items-start p-8 h-[calc(100%-5rem)] max-md:h-[calc(90%-5rem)] bg-brandLightGray" 
            style={{backgroundImage : "radial-gradient(circle at 1px 1px, white 1px, transparent 0)", backgroundSize : "5px 5px"}}>
                <div className="w-full bg-white rounded-lg p-5 gap-10 flex flex-row justify-start items-center">
                    <p className="w-3/5 text-2xl">Preview of your funnel &#128640;</p>
                    <div className="join w-2/5 flex justify-end items-center">
                        <button className={`join-item btn font-light ${(mobileView)?"":"bg-brandPurple text-white hover:bg-brandLightPurple"}`}
                        onClick={()=>{setMobileView(false);}}><IoDesktopOutline className="size-6"/></button>
                        <button className={`join-item btn font-light ${(mobileView)?"bg-brandPurple text-white hover:bg-brandLightPurple":""}`}
                        onClick={()=>{setMobileView(true);}}><FaMobileScreen className="size-6"/></button>
                    </div>
                </div>
                {
                    (mobileView)
                    ?<div className="mockup-phone border-brandPurple bg-[#232526] h-[90%]" style={{aspectRatio :"9/18"}}>
                        <div className="mockup-phone-display h-full w-full flex justify-center items-center">
                            <StepPreview steps={steps} selectedStep={selectedStep} mobile={true}/>
                        </div>
                    </div>
                    :<div className="mockup-browser border border-base-300 aspect-video h-[80%] w-full bg-[#23252680]">
                        <div className="mockup-browser-toolbar">
                            <div className="input">https://admin.cxtailor.com/funnel/...</div>
                        </div>
                        <div className="flex justify-center items-center place-content-center border-t border-base-200 bg-[#232526] h-full overflow-scroll">
                            <StepPreview steps={steps} selectedStep={selectedStep} mobile={false}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default EditFunnelV2;

