import React, { useContext, useEffect, useRef, useState } from "react";
import { MediaPlayer, MediaPlayerInstance, MediaProvider, PlayButton, TimeSlider, useStore } from '@vidstack/react';
import { PauseIcon, PlayIcon } from '@vidstack/react/icons';
import { GoUnmute } from "react-icons/go";
import { GoMute } from "react-icons/go";
import { PublicContext, SelectedFunnel } from "../../components/PublicContext";
import { FormInterface, Step, TextScreenInterface, ThankYouInterface, VideoInterface } from "../../components/Context";

interface VideoProps {
    video : VideoInterface,
    mobile : boolean,
    currentStep : number,
    setCurrentStep : Function,
    embed : boolean,
    sendStepData : Function,
    selectedFunnel : SelectedFunnel,
    sendPauseData : Function,
    pauseId : string,
    setPauseId : Function,
    deletePauseData : Function,
  }

const VideoPlayer = ( { video, mobile , currentStep, setCurrentStep, embed, sendStepData, selectedFunnel, sendPauseData, pauseId, setPauseId, deletePauseData} : VideoProps) 
: React.ReactElement => {
    const [showOverlay, setShowOverlay] = useState<boolean>(JSON.parse(JSON.stringify(video.CTAscreen)));
    const [bgColor, setBgColor] = useState<any>(JSON.parse(JSON.stringify(video.textScreenStyle.backgroundColor)));
    const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);
    const VideoRef = useRef<MediaPlayerInstance>(null), {playing} = useStore(MediaPlayerInstance, VideoRef);
    const [mute, setMute] = useState<boolean>(false);
    const [showThumbnail, setShowTumbnail] = useState<boolean>(true);
    const [playRecorded, setPlayRecorded] = useState<boolean>(false);
    
    const recordPause = () => {
      if(pauseId === undefined){
        if(VideoRef.current?.currentTime){
          if(VideoRef.current.duration){
              const pausePercentage : number = Number(((VideoRef.current.currentTime / VideoRef.current.duration) * 100).toFixed(2))
              if(pausePercentage < 100){
                sendPauseData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, currentStep, pausePercentage);
              }
          }
        }
      }
    }

    const timeUpdate = () => {
      if(VideoRef.current?.currentTime){
        if(VideoRef.current.duration){
            if(video.timeLeftPersentage){
                if((VideoRef.current.currentTime / VideoRef.current.duration) * 100 >= video.timeLeftPersentage){
                    setTimeLeft(Number((VideoRef.current.duration - VideoRef.current.currentTime).toFixed(0)))
                }else{
                    setTimeLeft(undefined);
                }
            }
        }
      }
    }

    useEffect(()=>{
      setShowOverlay(video.CTAscreen!);
      setBgColor(video.textScreenStyle.backgroundColor);
    },[video.CTAscreen, video.textScreenStyle.backgroundColor])
  
    return(
        <div className={`${(embed)?"w-full":"w-2/3"} rounded-lg flex justify-center items-center shadow-md`}>
          <MediaPlayer playsInline className='relative w-full h-full flex justify-center items-center rounded-lg' ref={VideoRef}
          title='Test video' src={video.url} onTimeUpdate={timeUpdate} autoPlay={video.autoPlay || false} muted={mute} onPause={recordPause}
          onPlay={()=>{
            if(!playRecorded){
              sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, currentStep, "start");
              setPlayRecorded(true);
            }
            if(pauseId){
              deletePauseData();
            }
          }}
          onEnded={()=>{setCurrentStep(currentStep);}}>
            <MediaProvider onClick={()=>{if(playing){VideoRef.current!.pause();}else{ VideoRef.current!.play(); setShowTumbnail(false);}}}>
            </MediaProvider>
            <div className={`group absolute ${(video.controlStyle.position === "bottom")?"bottom-3":"top-3"} left-5 z-10 w-[calc(100%-2.5rem)] flex flex-row justify-start gap-3 items-center`}>
              <TimeSlider.Root className={`inline-flex h-3 cursor-pointer touch-none select-none items-center outline-none aria-hidden:hidden`}
              style={{width : `calc(100% - ${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"})`}}>
                <TimeSlider.Track className={`relative ring-sky-400 z-0 h-4 shadow-md w-full rounded-lg group-data-[focus]:ring-[3px]`} style={{backgroundColor : `${video.controlStyle.barColor}30`}}>
                  <TimeSlider.TrackFill className={`absolute h-full w-[var(--slider-fill)] rounded-lg will-change-[width]`}
                  style={{backgroundColor : `${(video.controlStyle)? video.controlStyle.progressColor :"black"}`}} />
                  <TimeSlider.Progress className={`absolute z-10 h-full w-[var(--slider-progress)] rounded-lg will-change-[width]`} style={{backgroundColor : `${video.controlStyle.progressColor}50`}}/>
                </TimeSlider.Track>
                <TimeSlider.Thumb className="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-0 ring-white/40 transition-opacity group-data-[active]:opacity-100 group-data-[dragging]:ring-4 will-change-[left]" />
              </TimeSlider.Root>
              <div className={`flex justify-end items-center`} style={{width : `${(!mobile)?video.controlStyle.volumeSize:video.controlStyle.volumeSize/2}rem`}}>
                {
                  (mute)
                  ?<GoMute className="rounded-full shadow-sm cursor-pointer" 
                  style={{
                    backgroundColor : `${video.controlStyle.volumeBackgroundColor}`, color : `${video.controlStyle.volumeColor}`,
                    padding : `${(!mobile)? video.controlStyle.volumePadding + "rem" : video.controlStyle.volumePadding / 2 + "rem"}`,
                    height : `${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"}`,
                    width : `${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"}`
                  }}
                  onClick={()=>{setMute(false)}}/>
                  :<GoUnmute className="rounded-full shadow-sm cursor-pointer" 
                  style={{
                    backgroundColor : `${video.controlStyle.volumeBackgroundColor}`, color : `${video.controlStyle.volumeColor}`,
                    padding : `${(!mobile)? video.controlStyle.volumePadding + "rem" : video.controlStyle.volumePadding / 2 + "rem"}`,
                    height : `${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"}`,
                    width : `${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"}`
                  }}
                  onClick={()=>{setMute(true)}}/>
                }
              </div>
            </div>
            {
              (timeLeft !== undefined && video.showTimeLeft)
              ?<div className={`absolute ${(video.teaserStyle.y === "top")?"top-5":"bottom-5"} ${(video.teaserStyle.x === "right")?"right-0":"left-0"} pr-10`} 
                style={{
                  backgroundColor : `${video.teaserStyle.backgroundColor}`,
                  padding : `${(!mobile)? video.teaserStyle.padding + "rem" : video.teaserStyle.padding/2 + "rem"}`,
                }}>
                <p style={{
                  color : `${video.teaserStyle.color}`,
                  fontSize : `${(!mobile)? video.teaserStyle.size + "rem" : video.teaserStyle.size / 2 + "rem"}`
                }}>{video.timeLeftText} {timeLeft}s</p>
              </div>
              :null
            }
            {
              (showThumbnail && video.poster.length > 0)
              ?<img src={video.poster} alt="video thumbnail" className="z-10 rounded-xl h-full w-full" />
              :null
            }
            <PlayButton className={`group absolute z-10 cursor-pointer ${(playing)?"hidden":""}`} onClick={()=>{setShowTumbnail(false);}}>
              {
                (VideoRef.current && playing)
                ? <PauseIcon className="max-md:w-12 w-24 max-md:h-12 h-24 rounded-full p-4" 
                  style={{backgroundColor : `${(video.playButtonStyle.backgroundColor)}`, color : `${(video.playButtonStyle.color)}`}}/>
                : <PlayIcon className={`rounded-full`} 
                  style={{
                    backgroundColor : `${(video.playButtonStyle.backgroundColor)}`, color : `${(video.playButtonStyle.color)}`,
                    height : `${(!mobile)? video.playButtonStyle.size +"rem" : (video.playButtonStyle.size / 2) +"rem"}`,
                    width : `${(!mobile)? video.playButtonStyle.size +"rem" : (video.playButtonStyle.size / 2) +"rem"}`,
                    padding : `${(!mobile)? video.playButtonStyle.padding +"rem" : (video.playButtonStyle.padding / 2) +"rem"}`
                  }}/>
              }
            </PlayButton>
            {
              (showOverlay)
              ?<div className={`absolute z-10 w-full h-full flex justify-center items-center`} 
              style={{background : `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgColor.a})`}}>
                <div className={`w-[80%] flex flex-col justify-center`} 
                style={{gap : `${(!mobile)? video.textScreenStyle.gap + "rem" : video.textScreenStyle.gap/2 + "rem" }`, alignItems : `${video.textScreenStyle.textAlign}`}}>
                  {
                    (video.title.length > 0)
                    ?<p style={{
                      color : `${video.h1Style.color}`,
                      fontSize : `${video.h1Style.fontSize}rem`
                    }}>{video.title}</p>
                    :null
                  }
                  {
                    (video.description.length > 0)
                    ?<p style={{
                      color : `${video.pStyle.color}`,
                      fontSize : `${video.pStyle.fontSize}rem`
                    }}>{video.description}</p>
                    :null
                  }
                  <div>
                    <button className={`font-light border-none`} 
                    style={{
                      backgroundColor : `${video.ctaStyle.backgroundColor}`,
                      color : `${video.ctaStyle.color}`,
                      fontSize : `${video.ctaStyle.fontSize}rem`,
                      padding : `${video.ctaStyle.padding}rem`,
                      borderRadius : `${video.ctaStyle.borderRadius}px`
                    }}
                    onClick={()=>{setShowOverlay(false); VideoRef.current!.play(); setShowTumbnail(false);}}>{video.ctaText}</button>
                  </div>
                </div>
              </div>
              :null
            }
          </MediaPlayer>
        </div>
    );
  }

interface FormProps {
  form : FormInterface,
  videoUrl : string,
  mobile : boolean,
  currentStep : number,
  setCurrentStep : Function,
  embed : boolean,
  selectedFunnel : SelectedFunnel,
  submitNewLead : Function,
  stepData : any[],
}

const FormOverlay = ( {form, videoUrl, mobile, embed, currentStep, setCurrentStep, selectedFunnel, submitNewLead, stepData} : FormProps) : React.ReactElement => { 
  const [bgColor, setBgColor] = useState<any>(JSON.parse(JSON.stringify(form.textScreenStyle!.backgroundColor)));

  const handleFormSubmission = (event : React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      let formFields : any = {};

      form.inputFields.forEach((input : any, idx : number)=>{
          if(event.currentTarget.elements[input.label]){
              const formElement = event.currentTarget.elements.namedItem(input.label) as HTMLInputElement
              formFields[input.name] = formElement!.value;
          }
      });
      submitNewLead(selectedFunnel, form.emailResults, formFields, stepData);
      setCurrentStep(currentStep);
  }

  useEffect(()=>{
    setBgColor(form.textScreenStyle.backgroundColor);
  },[form.textScreenStyle.backgroundColor])

  return(
    <div className={`${(embed)?"w-full":"w-2/3"} rounded-lg flex justify-center items-center shadow-md`}>
    <MediaPlayer playsInline className='relative w-full h-full flex justify-center items-center rounded-lg'
    title='Test video' src={videoUrl}>
      <MediaProvider>
      </MediaProvider>
      {
        ( form.textScreenStyle.backgroundImage.length > 0 )
        ?<img src={form.textScreenStyle.backgroundImage} alt="form thumbnail" className="z-10 rounded-lg" />
        :null
      }
      <form className={`absolute z-10 w-full h-full flex justify-center items-center`} onSubmit={handleFormSubmission} 
        style={{background : `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgColor.a})`}}>
          <div className={`w-[80%] flex flex-col justify-center`} 
          style={{gap : `${(!mobile)? form.textScreenStyle.gap + "rem" : form.textScreenStyle.gap * 0.75 + "rem" }`, alignItems : `${form.textScreenStyle.textAlign}`}}>
            {
              (form.header.length > 0)
              ?<p style={{
                color : `${form.h1Style.color}`,
                fontSize : `${form.h1Style.fontSize}rem`
              }}>{form.header}</p>
              :null
            }
            {
              (form.description.length > 0)
              ?<p style={{
                color : `${form.pStyle.color}`,
                fontSize : `${form.pStyle.fontSize}rem`
              }}>{form.description}</p>
              :null
            }
            {form.inputFields.map((input : any, idx : number) => {
              return(
                <input key={idx} className="input input-bordered" required placeholder={input.label} name={(input.label === "other")?`${input.label}${idx}`:input.label}
                onChange={(e) => {console.log(e.target);}}
                style={{
                  fontSize : `${(!mobile)? form.inputStyle.fontSize + "rem" : form.inputStyle.fontSize * 0.75 + "rem" }`,
                  color : `${form.inputStyle.color}`,
                  backgroundColor : `${form.inputStyle.backgroundColor}`,
                  width : `${(!mobile)?form.inputStyle.width : 100 }%`,
                  borderRadius : `${form.inputStyle.borderRadius}px`,
                  padding : `${(!mobile)? form.inputStyle.padding + "rem" : form.inputStyle.padding / 2 + "rem" }`
                }}/>
              );
            })}
            <div>
              <button className={`font-light border-none`} 
              style={{
                backgroundColor : `${form.ctaStyle.backgroundColor}`,
                color : `${form.ctaStyle.color}`,
                fontSize : `${form.ctaStyle.fontSize}rem`,
                padding : `${form.ctaStyle.padding}rem`,
                borderRadius : `${form.ctaStyle.borderRadius}px`
              }}
              type="submit">{form.ctaText}</button>
            </div>
          </div>
        </form>
    </MediaPlayer>
  </div>
  );
}

interface TextScreenProps {
    text : TextScreenInterface,
    videoUrl : string,
    mobile : boolean,
    currentStep : number,
    setCurrentStep : Function,
    embed : boolean,
}

const TextScreenOverlay = ( {text, videoUrl, mobile, currentStep, setCurrentStep, embed} : TextScreenProps) : React.ReactElement => { 
  const [bgColor, setBgColor] = useState<any>(JSON.parse(JSON.stringify(text.textScreenStyle!.backgroundColor)));

  useEffect(()=>{
    setBgColor(text.textScreenStyle.backgroundColor);
  },[text.textScreenStyle.backgroundColor])

  return(
    <div className={`${(embed)?"w-full":"w-2/3"} rounded-lg flex justify-center items-center shadow-md`}>
    <MediaPlayer playsInline className='relative w-full h-full flex justify-center items-center rounded-lg'
    title='Test video' src={videoUrl}>
      <MediaProvider>
      </MediaProvider>
      {
        ( text.textScreenStyle.backgroundImage.length > 0 )
        ?<img src={text.textScreenStyle.backgroundImage} alt="form thumbnail" className="z-10 rounded-lg" />
        :null
      }
      <div className={`absolute z-10 w-full h-full flex justify-center items-center`} 
        style={{background : `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgColor.a})`}}>
          <div className={`w-[80%] flex flex-col justify-center`} 
          style={{gap : `${(!mobile)? text.textScreenStyle.gap + "rem" : text.textScreenStyle.gap * 0.75 + "rem" }`, alignItems : `${text.textScreenStyle.textAlign}`}}>
            {
              (text.h1.length > 0)
              ?<p style={{
                color : `${text.h1Style.color}`,
                fontSize : `${text.h1Style.fontSize}rem`
              }}>{text.h1}</p>
              :null
            }
            {
              (text.description.length > 0)
              ?<p style={{
                color : `${text.pStyle.color}`,
                fontSize : `${text.pStyle.fontSize}rem`
              }}>{text.description}</p>
              :null
            }
            <div className="flex flex-wrap items-center" style={{gap : `${(!mobile)? text.textScreenStyle.gap + "rem" : text.textScreenStyle.gap * 0.75 + "rem" }`}}>
                  <button className={`font-light border-none`}
                  style={{
                    backgroundColor : `${text.ctaStyle.backgroundColor}`,
                    color : `${text.ctaStyle.color}`,
                    fontSize : `${text.ctaStyle.fontSize}rem`,
                    padding : `${text.ctaStyle.padding}rem`,
                    borderRadius : `${text.ctaStyle.borderRadius}px`
                  }}
                  onClick={()=>{setCurrentStep(currentStep);}}>{text.ctaText}</button>
            </div>
          </div>
        </div>
    </MediaPlayer>
  </div>
  );
}

interface EndProps {
    end : ThankYouInterface,
    videoUrl : string,
    mobile : boolean,
    currentStep : number,
    embed : boolean,
    sendStepData : Function,
    selectedFunnel : SelectedFunnel,
}

const EndOverlay = ( {end, videoUrl, mobile, currentStep, embed, selectedFunnel, sendStepData} : EndProps) : React.ReactElement => { 
  const [bgColor, setBgColor] = useState<any>(JSON.parse(JSON.stringify(end.textScreenStyle!.backgroundColor)));

  const endRedirect = (url : string) => {
    sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, currentStep, "finish");
    window.open(url, "_blank");
  }

  useEffect(()=>{
    setBgColor(end.textScreenStyle.backgroundColor);
  },[end.textScreenStyle.backgroundColor])

  return(
    <div className={`${(embed)?"w-full":"w-2/3"} rounded-lg flex justify-center items-center shadow-md`}>
    <MediaPlayer playsInline className='relative w-full h-full flex justify-center items-center rounded-lg'
    title='Test video' src={videoUrl}>
      <MediaProvider>
      </MediaProvider>
      {
        ( end.textScreenStyle.backgroundImage.length > 0 )
        ?<img src={end.textScreenStyle.backgroundImage} alt="form thumbnail" className="z-10 rounded-lg" />
        :null
      }
      <div className={`absolute z-10 w-full h-full flex justify-center items-center`} 
        style={{background : `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgColor.a})`}}>
          <div className={`w-[80%] flex flex-col justify-center`} 
          style={{gap : `${(!mobile)? end.textScreenStyle.gap + "rem" : end.textScreenStyle.gap * 0.75 + "rem" }`, alignItems : `${end.textScreenStyle.textAlign}`}}>
            {
              (end.h1.length > 0)
              ?<p style={{
                color : `${end.h1Style.color}`,
                fontSize : `${end.h1Style.fontSize}rem`
              }}>{end.h1}</p>
              :null
            }
            {
              (end.description.length > 0)
              ?<p style={{
                color : `${end.pStyle.color}`,
                fontSize : `${end.pStyle.fontSize}rem`
              }}>{end.description}</p>
              :null
            }
            <div className="flex flex-wrap items-center" style={{gap : `${(!mobile)? end.textScreenStyle.gap + "rem" : end.textScreenStyle.gap * 0.75 + "rem" }`}}>
              {end.ctaList!.map((cta : any, idx : number) => {
                return(
                  <button className={`font-light border-none`} key={idx}
                  style={{
                    backgroundColor : `${end.ctaStyle[idx].backgroundColor}`,
                    color : `${end.ctaStyle[idx].color}`,
                    fontSize : `${end.ctaStyle[idx].fontSize}rem`,
                    padding : `${end.ctaStyle[idx].padding}rem`,
                    borderRadius : `${end.ctaStyle[idx].borderRadius}px`
                  }}
                  onClick={()=>{endRedirect(cta.url);}}>{cta.text}</button>
                );
              })}
            </div>
          </div>
        </div>
    </MediaPlayer>
  </div>
  );
}

export default function NewFunnelProto() : React.ReactElement {
  const {selectedFunnel, sendStepData, submitNewLead, embedded, sendPauseData, setPauseId, pauseId, deletePauseData} = useContext(PublicContext);
  const [stepData, setStepData] = useState<any>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [viewAdded, setViewAdded] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>("");
  
  //handles changin the funnel step & sending data from both finish of current step and the start of next step
  const MoveForward = (step : number) => {
      sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, step, "finish");
      if(selectedFunnel.steps[currentStep].video){
          setStepData([...stepData, {engagement : "video", log : new Date().toLocaleString("fi-FI")}]);
      }
      if(selectedFunnel.steps[currentStep].form){
          setStepData([...stepData, {engagement : "form submission", log : new Date().toLocaleString("fi-FI")}]);
      }
      if(selectedFunnel.steps[currentStep].textScreen){
          setStepData([...stepData, {engagement : "text screen", log : new Date().toLocaleString("fi-FI")}]);
      }
      setCurrentStep(step + 1);
      if(!selectedFunnel.steps[currentStep + 1].video){
          sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, step + 1, "start");
      }
  }



  //handles sending the initial view data
  useEffect(()=>{
      if(!viewAdded && selectedFunnel){
          sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, 0, "view");
          setViewAdded(true);
          if(!selectedFunnel.steps[0].video){
              sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, 0, "start");
          }
      }
  }, [viewAdded, selectedFunnel, sendStepData])

    return(
        <div className="w-svw h-svh flex justify-center items-center overflow-auto relative bg-[#232526]">
            {
              (selectedFunnel)
              ?<>
                {
                  (selectedFunnel.steps && selectedFunnel.steps.length > 0)
                  ?<>
                    {selectedFunnel.steps.map((step: Step, idx : number) => {
                      if(step.action === "video" && idx === currentStep){
                        if(videoUrl !== step.video!.url){
                          setVideoUrl(step.video!.url);
                        }
                        return <VideoPlayer mobile={false} video={step.video!} embed={embedded} selectedFunnel={selectedFunnel}
                              sendPauseData={sendPauseData} pauseId={pauseId} setPauseId={setPauseId} deletePauseData={deletePauseData}
                              currentStep={currentStep} setCurrentStep={MoveForward} sendStepData={sendStepData}/>
                      }
                      if(step.action === "form" && idx === currentStep){
                        return <FormOverlay mobile={false} form={step.form!} videoUrl={videoUrl} embed={embedded} selectedFunnel={selectedFunnel}
                              currentStep={currentStep} setCurrentStep={MoveForward} submitNewLead={submitNewLead} stepData={stepData}/>
                      }
                      if(step.action === "textScreen" && idx === currentStep){
                        return <TextScreenOverlay mobile={false} text={step.textScreen!} videoUrl={videoUrl} embed={embedded}
                              currentStep={currentStep} setCurrentStep={MoveForward}/>
                      }
                      if(step.action === "end screen" && idx === currentStep){
                        return <EndOverlay mobile={false} end={step.thankYouPage!} videoUrl={videoUrl} embed={embedded} selectedFunnel={selectedFunnel}
                              currentStep={currentStep} sendStepData={sendStepData}/>
                      }
                      return null;
                  })}
                  </>
                  :null
                }
              </>
              :<p>Loading...</p>
            }
        </div>
    );
}