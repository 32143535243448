import React, { useEffect, useRef, useState } from "react";
import { TextScreenInterface } from "../../../Context";


interface Props {
    idx : number,
    textScreen : TextScreenInterface,
    setTextScreen : Function
}

const TextContent = ({idx, textScreen, setTextScreen}:Props) : React.ReactElement => {
    const headerRef = useRef<HTMLInputElement>(null);
    const descRef = useRef<HTMLTextAreaElement>(null);
    const ctaRef = useRef<HTMLInputElement>(null);

    const [textDraft, setTextDraft] = useState<TextScreenInterface>(textScreen);

    const updateChanges = () => {
        const newEnd : TextScreenInterface = {
            h1 : (headerRef.current?.value) ? headerRef.current!.value : textScreen.h1,
            description : (descRef.current?.value) ? descRef.current!.value : textScreen.description,
            ctaText : (ctaRef.current?.value) ? ctaRef.current!.value : textScreen.ctaText,
            textScreenStyle : textScreen.textScreenStyle,
            h1Style : textScreen.h1Style,
            pStyle : textScreen.pStyle,
            ctaStyle : textScreen.ctaStyle
        }
        setTextScreen(newEnd);
        setTextDraft(newEnd);
        sessionStorage.setItem(`textScreen${idx}`, JSON.stringify(newEnd));
    }

    useEffect(()=>{
        const sessionEnd = sessionStorage.getItem(`textScreen${idx}`);
        if(sessionEnd){
            setTextDraft(JSON.parse(sessionEnd));
        }
    }, []);

    return(
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                <div className="flex flex-wrap gap-5 p-2 mt-5">
                    <form onChange={updateChanges} className="w-full p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">Text Screen Content</p>
                        <div className="w-full">
                            <p>Header</p>
                            <input type="text" ref={headerRef} defaultValue={textDraft.h1} placeholder="Form Header..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Description</p>
                            <textarea cols={3} ref={descRef} defaultValue={textDraft.description} rows={4} placeholder="Engaging Description..." 
                            className="input input-bordered rounded-2xl w-full h-fit"/>
                        </div>
                        <div className="w-full">
                            <p>CTA text</p>
                            <input type="text" ref={ctaRef} defaultValue={textDraft.ctaText} placeholder="CTA Text..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                    </form>
                </div>
            </div>
            <div className="w-1/3 mt-5 p-2">
                <div className="p-5 border-2 border-brandLightGray rounded-lg">
                    <p className="mb-2">Funnel Step Preview:</p>
                    <div className={`text-${textDraft.textScreenStyle.textAlign} flex flex-col gap-3`} style={{padding : `${textDraft.textScreenStyle.padding}px`, 
                        backgroundColor : `${textDraft.textScreenStyle.backgroundColor}`}}>
                        {(textDraft.h1)?
                        <p style={{fontSize : `${textDraft.h1Style.fontSize}px`, color : `${textDraft.h1Style.color}`}}>
                            {textDraft.h1}
                        </p>
                        :null}
                        {(textDraft.description)?
                        <p style={{fontSize : `${textDraft.pStyle.fontSize}px`, color : `${textDraft.pStyle.color}`}}>
                            {textDraft.description}
                        </p>
                        :null}
                        <button
                        style={{fontSize : `${textDraft.ctaStyle.fontSize}px`, padding :  `${textDraft.ctaStyle.padding}px`, borderRadius : `${textDraft.ctaStyle.borderRadius}px`,
                        color :  `${textDraft.ctaStyle.color}`, backgroundColor :  `${textDraft.ctaStyle.backgroundColor}`}}>
                            {(textDraft.ctaText)?textDraft.ctaText:"Continue"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextContent;