import React from "react";
import { Step } from "../../Context";
import {ChromePicker} from 'react-color';
import { MdOutlineVerticalAlignBottom, MdOutlineVerticalAlignTop } from "react-icons/md";
import { MdAlignHorizontalLeft, MdAlignHorizontalRight } from "react-icons/md";
import { GrTextAlignCenter } from "react-icons/gr";
import { GrTextAlignLeft } from "react-icons/gr";
import { GrTextAlignRight } from "react-icons/gr";

interface Props{
    selectedStep : Step,
    selectedStepNumber : number,
    steps : Step[],
    setSteps : Function,
}

export default function VideoEditor( { selectedStep, selectedStepNumber, steps, setSteps } : Props) : React.ReactElement {

    const updateVideoValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.video!.poster = (event.currentTarget.elements.namedItem("poster") as HTMLInputElement).value
                step.video!.url = (event.currentTarget.elements.namedItem("url") as HTMLInputElement).value
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateTeaserValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.video!.timeLeftText = (event.currentTarget.elements.namedItem("teaser-text") as HTMLInputElement).value
                step.video!.timeLeftPersentage = Number((event.currentTarget.elements.namedItem("teaser-perc") as HTMLInputElement).value)
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateCTAscreenValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.video!.title = (event.currentTarget.elements.namedItem("header") as HTMLInputElement).value
                step.video!.description = (event.currentTarget.elements.namedItem("description") as HTMLInputElement).value
                step.video!.ctaText = (event.currentTarget.elements.namedItem("cta-text") as HTMLInputElement).value
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updatePlayStyleValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.video!.playButtonStyle.size = Number((event.currentTarget.elements.namedItem("size") as HTMLInputElement).value);
                step.video!.playButtonStyle.padding = Number((event.currentTarget.elements.namedItem("padding") as HTMLInputElement).value);
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateControlStyleValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.video!.controlStyle.volumeSize = Number((event.currentTarget.elements.namedItem("mute-size") as HTMLInputElement).value);
                step.video!.controlStyle.volumePadding = Number((event.currentTarget.elements.namedItem("mute-padding") as HTMLInputElement).value);
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateCtaStyleValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.video!.textScreenStyle.gap = Number((event.currentTarget.elements.namedItem("gap") as HTMLInputElement).value);
                step.video!.h1Style.fontSize = Number((event.currentTarget.elements.namedItem("title-size") as HTMLInputElement).value);
                step.video!.pStyle.fontSize = Number((event.currentTarget.elements.namedItem("desc-size") as HTMLInputElement).value);
                step.video!.ctaStyle.fontSize = Number((event.currentTarget.elements.namedItem("cta-size") as HTMLInputElement).value);
                step.video!.ctaStyle.padding = Number((event.currentTarget.elements.namedItem("cta-padding") as HTMLInputElement).value);
                step.video!.ctaStyle.borderRadius = Number((event.currentTarget.elements.namedItem("cta-border") as HTMLInputElement).value);
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    const updateTeaserStyleValues = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
            if(idx === selectedStepNumber){
                step.video!.teaserStyle.size = Number((event.currentTarget.elements.namedItem("size") as HTMLInputElement).value);
                step.video!.teaserStyle.padding = Number((event.currentTarget.elements.namedItem("padding") as HTMLInputElement).value);
                console.log(step);
                return step
            }else{
                return step
            }
        })
        setSteps(newSteps);
    }

    return(
        <div className="p-5 flex flex-col gap-5 h-full">
            <div className="w-full flex gap-2 flex-wrap">
                <form onChange={updateVideoValues} className="w-full flex flex-col flex-wrap gap-7">
                    <p className="text-lg">Video Content</p>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">Video URL</label>
                        <input type="url" defaultValue={selectedStep.video?.url} name="url" className="w-full input input-bordered input-md text-sm"/>
                    </div>
                    <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                        <label className="w-full text-sm">Thumbnail URL</label>
                        <input type="text" defaultValue={selectedStep.video?.poster} name="poster" className="w-full input input-bordered input-md text-sm"/>
                    </div>
                </form>
                <div className="divider w-full"></div>
                <div className="flex flex-row justify-start items-center gap-7 flex-wrap w-full">
                    <p className="text-sm">Show teaser text</p>
                    <input type="checkbox" checked={selectedStep.video!.showTimeLeft} 
                    className="toggle toggle-sm checked:bg-green checked:border-green bg-red border-red hover:bg-red hover:checked:bg-green" onClick={()=>{
                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                            if(idx === selectedStepNumber){
                                step.video!.showTimeLeft = !step.video!.showTimeLeft
                                return step
                            }else{
                                return step
                            }
                        })
                        setSteps(newSteps);
                    }}/>
                    {
                    (steps[selectedStepNumber].video!.showTimeLeft)
                    ?<form className="w-full flex flex-col flex-wrap gap-5" onChange={updateTeaserValues}>
                        <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                            <label className="w-full text-sm">Teaser text</label>
                            <input type="text" defaultValue={selectedStep.video!.timeLeftText} name="teaser-text" className="w-full input input-bordered input-md text-sm"/>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">Show after %</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} defaultValue={selectedStep.video!.timeLeftPersentage} name="teaser-perc" 
                                className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">%</span>
                            </label>
                        </div>
                    </form>
                    :null
                    }
                </div>
                <div className="divider w-full"></div>
                <div className="flex flex-row justify-start items-center gap-5">
                    <p className="text-sm">Autoplay video</p>
                    <input type="checkbox" checked={selectedStep.video!.autoPlay} disabled={selectedStep.video!.CTAscreen}
                    className="toggle toggle-sm checked:bg-green checked:border-green bg-red border-red hover:bg-red hover:checked:bg-green disabled:bg-red" 
                    onClick={()=>{
                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                            if(idx === selectedStepNumber){
                                step.video!.autoPlay = !step.video!.autoPlay
                                return step
                            }else{
                                return step
                            }
                        })
                        setSteps(newSteps);
                    }}/>
                </div>
                <div className="divider w-full"></div>
                <div className="flex flex-row justify-start w-full items-center gap-7 flex-wrap">
                    <p className="text-sm">Show CTA screen</p>
                    <input type="checkbox" checked={selectedStep.video!.CTAscreen} disabled={selectedStep.video!.autoPlay} 
                    className="toggle toggle-sm checked:bg-green checked:border-green bg-red border-red hover:bg-red hover:checked:bg-green disabled:bg-red" 
                    onClick={()=>{
                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                            if(idx === selectedStepNumber){
                                step.video!.CTAscreen = !step.video!.CTAscreen
                                console.log(step)
                                return step
                            }else{
                                return step
                            }
                        })
                        setSteps(newSteps);
                    }}/>
                    {
                    (steps[selectedStepNumber].video!.CTAscreen)
                    ?<form className="w-full flex flex-col flex-wrap gap-7" onChange={updateCTAscreenValues}>
                        <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                            <label className="w-full text-sm">Header</label>
                            <input type="text" defaultValue={selectedStep.video?.title} name="header" className="w-full input input-bordered input-md text-sm"/>
                        </div>
                        <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                            <label className="w-full text-sm">Description</label>
                            <input type="text" defaultValue={selectedStep.video?.description} name="description" className="w-full input input-bordered input-md text-sm"/>
                        </div>
                        <div className="flex flex-col justify-start items-center flex-wrap gap-2 w-full">
                            <label className="w-full text-sm">CTA text</label>
                            <input type="text" defaultValue={selectedStep.video?.ctaText} name="cta-text" className="w-full input input-bordered input-md text-sm"/>
                        </div>                    
                    </form>
                    :null
                }
                </div>
                <div className="divider w-full"></div>
                <form className="w-full flex flex-col flex-wrap gap-7" onChange={updatePlayStyleValues}>
                    <p className="text-lg">Play button styling</p>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Play button color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.playButtonStyle.backgroundColor}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.video!.playButtonStyle.backgroundColor}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.video!.playButtonStyle.backgroundColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.video!.playButtonStyle.backgroundColor = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Play icon color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.playButtonStyle.color}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.video!.playButtonStyle.color}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.video!.playButtonStyle.color} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.video!.playButtonStyle.color = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Play icon size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} step={0.25} max={100} defaultValue={selectedStep.video!.playButtonStyle.size} name="size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Padding</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} step={0.25} max={100} defaultValue={selectedStep.video!.playButtonStyle.size} name="padding" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                </form>
                <div className="divider w-full"></div>
                <form className="w-full flex flex-col flex-wrap gap-7" onChange={updateControlStyleValues}>
                    <p className="text-lg">Video control styling</p>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Time slider progress color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.controlStyle.progressColor}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.video!.controlStyle.progressColor}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.video!.controlStyle.progressColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.video!.controlStyle.progressColor = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Time slider color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.controlStyle.barColor}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.video!.controlStyle.barColor}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.video!.controlStyle.barColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.video!.controlStyle.barColor = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-1/2 text-sm">Control position</label>
                        <div className="join w-1/2 flex justify-end items-center">
                            <button type="button" className={`join-item btn text-lg btn-sm
                                ${(selectedStep.video!.controlStyle.position==="top")
                                ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                :""}`} onClick={()=>{
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.video!.controlStyle.position = "top";
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}>
                                <MdOutlineVerticalAlignTop />
                            </button>
                            <button type="button" className={`join-item btn text-lg btn-sm
                                ${(selectedStep.video!.controlStyle.position==="bottom")
                                ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                :""}`} onClick={()=>{
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            selectedStep.video!.controlStyle.position = "bottom";
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}>
                                <MdOutlineVerticalAlignBottom />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Mute button color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.controlStyle.volumeBackgroundColor}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.video!.controlStyle.volumeBackgroundColor}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.video!.controlStyle.volumeBackgroundColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.video!.controlStyle.volumeBackgroundColor = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-[70%] text-sm">Mute icon color</label>
                        <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                            <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.controlStyle.volumeColor}`}>
                                <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                style={{backgroundColor : `${selectedStep.video!.controlStyle.volumeColor}`}}></div>
                            </div>
                            <ul tabIndex={0} className="dropdown-content menu z-10">
                                <ChromePicker color={selectedStep.video!.controlStyle.volumeColor} 
                                onChange={(event) => {
                                    const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                        if(idx === selectedStepNumber){
                                            step.video!.controlStyle.volumeColor = event.hex;
                                            return step
                                        }else{
                                            return step
                                        }
                                    })
                                    setSteps(newSteps);
                                }}/>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Mute icon size</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.25} defaultValue={selectedStep.video!.controlStyle.volumeSize} name="mute-size" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                    <div className="flex flex-row justify-start items-center flex-wrap w-full">
                        <label className="w-2/3 text-sm">Mute padding</label>
                        <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                            <input type="number" min={0} max={100} step={0.25} defaultValue={selectedStep.video!.controlStyle.volumePadding} name="mute-padding" className="join-item w-2/3 p-2"/>
                            <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                        </label>
                    </div>
                </form>
                <div className="divider w-full"></div>
                {
                    (selectedStep.video!.CTAscreen)
                    ?<form className="w-full flex flex-col flex-wrap gap-7" onChange={updateCtaStyleValues}>
                        <p className="text-lg">CTA screen styling</p>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-[70%] text-sm">Background color</label>
                            <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                <div className="tooltip tooltip-left" data-tip={`rgba(
                                    ${selectedStep.video!.textScreenStyle.backgroundColor.r},
                                    ${selectedStep.video!.textScreenStyle.backgroundColor.g},
                                    ${selectedStep.video!.textScreenStyle.backgroundColor.b},
                                    ${selectedStep.video!.textScreenStyle.backgroundColor.a}
                                    )`}>
                                    <div tabIndex={0} className="w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray" 
                                    style={{backgroundColor : 
                                    `rgba(
                                    ${selectedStep.video!.textScreenStyle.backgroundColor.r},
                                    ${selectedStep.video!.textScreenStyle.backgroundColor.g},
                                    ${selectedStep.video!.textScreenStyle.backgroundColor.b},
                                    ${selectedStep.video!.textScreenStyle.backgroundColor.a}
                                    )`}}></div>
                                </div>
                                <ul tabIndex={0} className="dropdown-content menu z-10">
                                    <ChromePicker color={selectedStep.video!.textScreenStyle.backgroundColor} 
                                    onChange={(event) => {
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                step.video!.textScreenStyle.backgroundColor = event.rgb;
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}/>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-1/2 text-sm">Text align</label>
                            <div className="join w-1/2 flex justify-end items-center">
                                <button type="button" className={`join-item btn text-lg btn-sm
                                    ${(selectedStep.video!.textScreenStyle.textAlign === "start")
                                    ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                    :""}`} onClick={()=>{
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                selectedStep.video!.textScreenStyle.textAlign = "start";
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}>
                                    <GrTextAlignLeft />
                                </button>
                                <button type="button" className={`join-item btn text-lg btn-sm
                                    ${(selectedStep.video!.textScreenStyle.textAlign === "center")
                                    ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                    :""}`} onClick={()=>{
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                selectedStep.video!.textScreenStyle.textAlign = "center";
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}>
                                    <GrTextAlignCenter />
                                </button>
                                <button type="button" className={`join-item btn text-lg btn-sm
                                    ${(selectedStep.video!.textScreenStyle.textAlign === "end")
                                    ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                    :""}`} onClick={()=>{
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                selectedStep.video!.textScreenStyle.textAlign = "end";
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}>
                                    <GrTextAlignRight />
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">Element spacing</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.video!.textScreenStyle.gap} name="gap" className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                            </label>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">Title size</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.video!.h1Style.fontSize} name="title-size" className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                            </label>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-[70%] text-sm">Title color</label>
                            <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.h1Style.color}`}>
                                    <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                    style={{backgroundColor : `${selectedStep.video!.h1Style.color}`}}></div>
                                </div>
                                <ul tabIndex={0} className="dropdown-content menu z-10">
                                    <ChromePicker color={selectedStep.video!.h1Style.color} 
                                    onChange={(event) => {
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                step.video!.h1Style.color = event.hex;
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}/>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">Description size</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.video!.pStyle.fontSize} name="desc-size" className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                            </label>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-[70%] text-sm">Description color</label>
                            <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.pStyle.color}`}>
                                    <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                    style={{backgroundColor : `${selectedStep.video!.pStyle.color}`}}></div>
                                </div>
                                <ul tabIndex={0} className="dropdown-content menu z-10">
                                    <ChromePicker color={selectedStep.video!.pStyle.color} 
                                    onChange={(event) => {
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                step.video!.pStyle.color = event.hex;
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}/>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">CTA font size</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.video!.ctaStyle.fontSize} name="cta-size" className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                            </label>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-[70%] text-sm">CTA font color</label>
                            <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.ctaStyle.color}`}>
                                    <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                    style={{backgroundColor : `${selectedStep.video!.ctaStyle.color}`}}></div>
                                </div>
                                <ul tabIndex={0} className="dropdown-content menu z-10">
                                    <ChromePicker color={selectedStep.video!.ctaStyle.color} 
                                    onChange={(event) => {
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                step.video!.ctaStyle.color = event.hex;
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}/>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-[70%] text-sm">CTA background color</label>
                            <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.ctaStyle.backgroundColor}`}>
                                    <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                    style={{backgroundColor : `${selectedStep.video!.ctaStyle.backgroundColor}`}}></div>
                                </div>
                                <ul tabIndex={0} className="dropdown-content menu z-10">
                                    <ChromePicker color={selectedStep.video!.ctaStyle.backgroundColor} 
                                    onChange={(event) => {
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                step.video!.ctaStyle.backgroundColor = event.hex;
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}/>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">CTA padding</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} step={0.25} defaultValue={selectedStep.video!.ctaStyle.padding} name="cta-padding" className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                            </label>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">CTA border radius</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} defaultValue={selectedStep.video!.ctaStyle.borderRadius} name="cta-border" className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">px</span>
                            </label>
                        </div>
                    </form>
                    :<p>Enable CTA screen to access its' style settings</p>
                }
                <div className="divider w-full"></div>
                {
                    (selectedStep.video!)
                    ?<form className="w-full flex flex-col flex-wrap gap-7" onChange={updateTeaserStyleValues}>
                        <p className="text-lg">Teaser styling</p>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">Font size</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} step={0.1} defaultValue={selectedStep.video!.teaserStyle.size} name="size" className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                            </label>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-[70%] text-sm">Font color</label>
                            <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.teaserStyle.color}`}>
                                    <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                    style={{backgroundColor : `${selectedStep.video!.teaserStyle.color}`}}></div>
                                </div>
                                <ul tabIndex={0} className="dropdown-content menu z-10">
                                    <ChromePicker color={selectedStep.video!.teaserStyle.color} 
                                    onChange={(event) => {
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                step.video!.teaserStyle.color = event.hex;
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}/>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-[70%] text-sm">Background color</label>
                            <div className="dropdown dropdown-bottom flex justify-end items-center w-[30%]">
                                <div className="tooltip tooltip-left" data-tip={`${selectedStep.video!.teaserStyle.backgroundColor}`}>
                                    <div tabIndex={0} className={`w-8 h-8 rounded-lg cursor-pointer shadow-lg border-2 border-brandLightGray`}
                                    style={{backgroundColor : `${selectedStep.video!.teaserStyle.backgroundColor}`}}></div>
                                </div>
                                <ul tabIndex={0} className="dropdown-content menu z-10">
                                    <ChromePicker color={selectedStep.video!.teaserStyle.backgroundColor} 
                                    onChange={(event) => {
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                step.video!.teaserStyle.backgroundColor = event.hex;
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}/>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-2/3 text-sm">Padding</label>
                            <label className="input input-bordered flex flex-row justify-start items-center join w-1/3 p-0 input-sm text-sm">
                                <input type="number" min={0} max={100} step={0.25} defaultValue={selectedStep.video!.teaserStyle.padding} name="padding" className="join-item w-2/3 p-2"/>
                                <span className="join-item bg-brandLightGray h-full w-1/3 text-sm flex justify-center items-center">rem</span>
                            </label>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-1/2 text-sm">Vertical position</label>
                            <div className="join w-1/2 flex justify-end items-center">
                                <button type="button" className={`join-item btn text-lg btn-sm
                                    ${(selectedStep.video!.teaserStyle.y==="top")
                                    ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                    :""}`} onClick={()=>{
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                selectedStep.video!.teaserStyle.y = "top";
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}>
                                    <MdOutlineVerticalAlignTop />
                                </button>
                                <button type="button" className={`join-item btn text-lg btn-sm
                                    ${(selectedStep.video!.teaserStyle.y==="bottom")
                                    ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                    :""}`} onClick={()=>{
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                selectedStep.video!.teaserStyle.y = "bottom";
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}>
                                    <MdOutlineVerticalAlignBottom />
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-row justify-start items-center flex-wrap w-full">
                            <label className="w-1/2 text-sm">Horizontal position</label>
                            <div className="join w-1/2 flex justify-end items-center">
                                <button type="button" className={`join-item btn text-lg btn-sm
                                    ${(selectedStep.video!.teaserStyle.x==="left")
                                    ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                    :""}`} onClick={()=>{
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                selectedStep.video!.teaserStyle.x = "left";
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}>
                                    <MdAlignHorizontalLeft />
                                </button>
                                <button type="button" className={`join-item btn text-lg btn-sm
                                    ${(selectedStep.video!.teaserStyle.x==="right")
                                    ?"bg-brandPurple text-white hover:bg-brandLightPurple"
                                    :""}`} onClick={()=>{
                                        const newSteps : Step[] = steps.map((step : Step, idx : number) => {
                                            if(idx === selectedStepNumber){
                                                selectedStep.video!.teaserStyle.x = "right";
                                                return step
                                            }else{
                                                return step
                                            }
                                        })
                                        setSteps(newSteps);
                                    }}>
                                    <MdAlignHorizontalRight />
                                </button>
                            </div>
                        </div>
                    </form>
                    :<p></p>
                }
            </div>

        </div>
    );
};
