import React, { useEffect, useRef, useState } from "react";
import { MediaPlayer, MediaPlayerInstance, MediaProvider, PlayButton, TimeSlider, useStore } from '@vidstack/react';
import { PauseIcon, PlayIcon } from '@vidstack/react/icons';
import { FormInterface, Step, TextScreenInterface, ThankYouInterface, VideoInterface } from "../../Context";
import { GoUnmute } from "react-icons/go";
import { GoMute } from "react-icons/go";

interface VideoProps {
    video : VideoInterface,
    mobile : boolean,
    currentStep : number,
    setCurrentStep : Function,
    embed : boolean,
  }

const VideoPlayer = ( { video, mobile , currentStep, setCurrentStep, embed } : VideoProps) : React.ReactElement => {
    const [showOverlay, setShowOverlay] = useState<boolean>(JSON.parse(JSON.stringify(video.CTAscreen)));
    const [bgColor, setBgColor] = useState<any>(JSON.parse(JSON.stringify(video.textScreenStyle.backgroundColor)));
    const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);
    const VideoRef = useRef<MediaPlayerInstance>(null), {playing} = useStore(MediaPlayerInstance, VideoRef);
    const [mute, setMute] = useState<boolean>(false);
    const [showThumbnail, setShowTumbnail] = useState<boolean>(true);
    
    const timeUpdate = () => {
      if(VideoRef.current?.currentTime){
        if(VideoRef.current.duration){
            if(video.timeLeftPersentage){
                if((VideoRef.current.currentTime / VideoRef.current.duration) * 100 >= video.timeLeftPersentage){
                    setTimeLeft(Number((VideoRef.current.duration - VideoRef.current.currentTime).toFixed(0)))
                }else{
                    setTimeLeft(undefined);
                }
            }
        }
      }
    }

    useEffect(()=>{
      setShowOverlay(video.CTAscreen!);
      setBgColor(video.textScreenStyle.backgroundColor);
    },[video.CTAscreen, video.textScreenStyle.backgroundColor])
  
    return(
        <div className={`${(embed)?"w-full":"w-2/3"} rounded-lg flex justify-center items-center shadow-md`}>
          <MediaPlayer playsInline className='relative w-full h-full flex justify-center items-center rounded-lg' ref={VideoRef}
          title='Test video' src={video.url} onTimeUpdate={timeUpdate} autoPlay={video.autoPlay || false} muted={mute} onEnded={()=>{setCurrentStep(currentStep + 1);}}>
            <MediaProvider onClick={()=>{if(playing){VideoRef.current!.pause();}else{ VideoRef.current!.play(); setShowTumbnail(false);}}}>
            </MediaProvider>
            <div className={`group absolute ${(video.controlStyle.position === "bottom")?"bottom-3":"top-3"} left-5 z-10 w-[calc(100%-2.5rem)] flex flex-row justify-start gap-3 items-center`}>
              <TimeSlider.Root className={`inline-flex h-3 cursor-pointer touch-none select-none items-center outline-none aria-hidden:hidden`}
              style={{width : `calc(100% - ${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"})`}}>
                <TimeSlider.Track className={`relative ring-sky-400 z-0 h-4 shadow-md w-full rounded-lg group-data-[focus]:ring-[3px]`} style={{backgroundColor : `${video.controlStyle.barColor}30`}}>
                  <TimeSlider.TrackFill className={`absolute h-full w-[var(--slider-fill)] rounded-lg will-change-[width]`}
                  style={{backgroundColor : `${(video.controlStyle)? video.controlStyle.progressColor :"black"}`}} />
                  <TimeSlider.Progress className={`absolute z-10 h-full w-[var(--slider-progress)] rounded-lg will-change-[width]`} style={{backgroundColor : `${video.controlStyle.progressColor}50`}}/>
                </TimeSlider.Track>
                <TimeSlider.Thumb className="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-0 ring-white/40 transition-opacity group-data-[active]:opacity-100 group-data-[dragging]:ring-4 will-change-[left]" />
              </TimeSlider.Root>
              <div className={`flex justify-end items-center`} style={{width : `${(!mobile)?video.controlStyle.volumeSize:video.controlStyle.volumeSize/2}rem`}}>
                {
                  (mute)
                  ?<GoMute className="rounded-full shadow-sm cursor-pointer" 
                  style={{
                    backgroundColor : `${video.controlStyle.volumeBackgroundColor}`, color : `${video.controlStyle.volumeColor}`,
                    padding : `${(!mobile)? video.controlStyle.volumePadding + "rem" : video.controlStyle.volumePadding / 2 + "rem"}`,
                    height : `${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"}`,
                    width : `${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"}`
                  }}
                  onClick={()=>{setMute(false)}}/>
                  :<GoUnmute className="rounded-full shadow-sm cursor-pointer" 
                  style={{
                    backgroundColor : `${video.controlStyle.volumeBackgroundColor}`, color : `${video.controlStyle.volumeColor}`,
                    padding : `${(!mobile)? video.controlStyle.volumePadding + "rem" : video.controlStyle.volumePadding / 2 + "rem"}`,
                    height : `${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"}`,
                    width : `${(!mobile)? video.controlStyle.volumeSize + "rem" : video.controlStyle.volumeSize / 2 + "rem"}`
                  }}
                  onClick={()=>{setMute(true)}}/>
                }
              </div>
            </div>
            {
              (timeLeft !== undefined && video.showTimeLeft)
              ?<div className={`absolute ${(video.teaserStyle.y === "top")?"top-5":"bottom-5"} ${(video.teaserStyle.x === "right")?"right-0":"left-0"} pr-10`} 
                style={{
                  backgroundColor : `${video.teaserStyle.backgroundColor}`,
                  padding : `${(!mobile)? video.teaserStyle.padding + "rem" : video.teaserStyle.padding/2 + "rem"}`,
                }}>
                <p style={{
                  color : `${video.teaserStyle.color}`,
                  fontSize : `${(!mobile)? video.teaserStyle.size + "rem" : video.teaserStyle.size / 2 + "rem"}`
                }}>{video.timeLeftText} {timeLeft}s</p>
              </div>
              :null
            }
            {
              (showThumbnail && video.poster.length > 0)
              ?<img src={video.poster} alt="video thumbnail" className="z-10 rounded-xl" />
              :null
            }
            <PlayButton className={`group absolute z-10 cursor-pointer ${(playing)?"hidden":""}`} onClick={()=>{setShowTumbnail(false);}}>
              {
                (VideoRef.current && playing)
                ? <PauseIcon className="max-md:w-12 w-24 max-md:h-12 h-24 rounded-full p-4" 
                  style={{backgroundColor : `${(video.playButtonStyle.backgroundColor)}`, color : `${(video.playButtonStyle.color)}`}}/>
                : <PlayIcon className={`rounded-full`} 
                  style={{
                    backgroundColor : `${(video.playButtonStyle.backgroundColor)}`, color : `${(video.playButtonStyle.color)}`,
                    height : `${(!mobile)? video.playButtonStyle.size +"rem" : (video.playButtonStyle.size / 2) +"rem"}`,
                    width : `${(!mobile)? video.playButtonStyle.size +"rem" : (video.playButtonStyle.size / 2) +"rem"}`,
                    padding : `${(!mobile)? video.playButtonStyle.padding +"rem" : (video.playButtonStyle.padding / 2) +"rem"}`
                  }}/>
              }
            </PlayButton>
            {
              (showOverlay)
              ?<div className={`absolute z-10 rounded-lg w-[calc(100%+5px)] h-[calc(100%+5px)] flex justify-center items-center`} 
              style={{background : `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgColor.a})`}}>
                <div className={`w-[80%] flex flex-col justify-center`} 
                style={{gap : `${(!mobile)? video.textScreenStyle.gap + "rem" : video.textScreenStyle.gap/2 + "rem" }`, alignItems : `${video.textScreenStyle.textAlign}`}}>
                  {
                    (video.title.length > 0)
                    ?<p style={{
                      color : `${video.h1Style.color}`,
                      fontSize : `${video.h1Style.fontSize}rem`
                    }}>{video.title}</p>
                    :null
                  }
                  {
                    (video.description.length > 0)
                    ?<p style={{
                      color : `${video.pStyle.color}`,
                      fontSize : `${video.pStyle.fontSize}rem`
                    }}>{video.description}</p>
                    :null
                  }
                  <div>
                    <button className={`font-light border-none`} 
                    style={{
                      backgroundColor : `${video.ctaStyle.backgroundColor}`,
                      color : `${video.ctaStyle.color}`,
                      fontSize : `${video.ctaStyle.fontSize}rem`,
                      padding : `${video.ctaStyle.padding}rem`,
                      borderRadius : `${video.ctaStyle.borderRadius}px`
                    }}
                    onClick={()=>{setShowOverlay(false); VideoRef.current!.play(); setShowTumbnail(false);}}>{video.ctaText}</button>
                  </div>
                </div>
              </div>
              :null
            }
          </MediaPlayer>
        </div>
    );
  }

interface FormProps {
  form : FormInterface,
  videoUrl : string,
  mobile : boolean,
  currentStep : number,
  setCurrentStep : Function,
  embed : boolean,
}

const FormOverlay = ( {form, videoUrl, mobile, embed, currentStep, setCurrentStep} : FormProps) : React.ReactElement => { 
  const [bgColor, setBgColor] = useState<any>(JSON.parse(JSON.stringify(form.textScreenStyle!.backgroundColor)));

  useEffect(()=>{
    setBgColor(form.textScreenStyle.backgroundColor);
  },[form.textScreenStyle.backgroundColor])

  return(
    <div className={`${(embed)?"w-full":"w-2/3"} rounded-lg flex justify-center items-center shadow-md`}>
    <MediaPlayer playsInline className='relative w-full h-full flex justify-center items-center rounded-lg'
    title='Test video' src={videoUrl}>
      <MediaProvider>
      </MediaProvider>
      {
        ( form.textScreenStyle.backgroundImage.length > 0 )
        ?<img src={form.textScreenStyle.backgroundImage} alt="form thumbnail" className="z-10 rounded-lg" />
        :null
      }
      <div className={`absolute z-10 rounded-lg w-[calc(100%+5px)] h-[calc(100%+5px)] flex justify-center items-center`} 
        style={{background : `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgColor.a})`}}>
          <div className={`w-[80%] flex flex-col justify-center`} 
          style={{gap : `${(!mobile)? form.textScreenStyle.gap + "rem" : form.textScreenStyle.gap * 0.75 + "rem" }`, alignItems : `${form.textScreenStyle.textAlign}`}}>
            {
              (form.header.length > 0)
              ?<p style={{
                color : `${form.h1Style.color}`,
                fontSize : `${form.h1Style.fontSize}rem`
              }}>{form.header}</p>
              :null
            }
            {
              (form.description.length > 0)
              ?<p style={{
                color : `${form.pStyle.color}`,
                fontSize : `${form.pStyle.fontSize}rem`
              }}>{form.description}</p>
              :null
            }
            {form.inputFields.map((input : any, idx : number) => {
              return(
                <input key={idx} className="input input-bordered" placeholder={input.label} style={{
                  fontSize : `${(!mobile)? form.inputStyle.fontSize + "rem" : form.inputStyle.fontSize * 0.75 + "rem" }`,
                  color : `${form.inputStyle.color}`,
                  backgroundColor : `${form.inputStyle.backgroundColor}`,
                  width : `${(!mobile)?form.inputStyle.width : 100 }%`,
                  borderRadius : `${form.inputStyle.borderRadius}px`,
                  padding : `${(!mobile)? form.inputStyle.padding + "rem" : form.inputStyle.padding / 2 + "rem" }`
                }}/>
              );
            })}
            <div>
              <button className={`font-light border-none`} 
              style={{
                backgroundColor : `${form.ctaStyle.backgroundColor}`,
                color : `${form.ctaStyle.color}`,
                fontSize : `${form.ctaStyle.fontSize}rem`,
                padding : `${form.ctaStyle.padding}rem`,
                borderRadius : `${form.ctaStyle.borderRadius}px`
              }}
              onClick={()=>{setCurrentStep(currentStep + 1);}}>{form.ctaText}</button>
            </div>
          </div>
        </div>
    </MediaPlayer>
  </div>
  );
}

interface TextScreenProps {
    text : TextScreenInterface,
    videoUrl : string,
    mobile : boolean,
    currentStep : number,
    setCurrentStep : Function,
    embed : boolean,
}

const TextScreenOverlay = ( {text, videoUrl, mobile, currentStep, setCurrentStep, embed} : TextScreenProps) : React.ReactElement => { 
  const [bgColor, setBgColor] = useState<any>(JSON.parse(JSON.stringify(text.textScreenStyle!.backgroundColor)));

  useEffect(()=>{
    setBgColor(text.textScreenStyle.backgroundColor);
  },[text.textScreenStyle.backgroundColor])

  return(
    <div className={`${(embed)?"w-full":"w-2/3"} rounded-lg flex justify-center items-center shadow-md`}>
    <MediaPlayer playsInline className='relative w-full h-full flex justify-center items-center rounded-lg'
    title='Test video' src={videoUrl}>
      <MediaProvider>
      </MediaProvider>
      {
        ( text.textScreenStyle.backgroundImage.length > 0 )
        ?<img src={text.textScreenStyle.backgroundImage} alt="form thumbnail" className="z-10 rounded-lg" />
        :null
      }
      <div className={`absolute z-10 rounded-lg w-[calc(100%+5px)] h-[calc(100%+5px)] flex justify-center items-center`} 
        style={{background : `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgColor.a})`}}>
          <div className={`w-[80%] flex flex-col justify-center`} 
          style={{gap : `${(!mobile)? text.textScreenStyle.gap + "rem" : text.textScreenStyle.gap * 0.75 + "rem" }`, alignItems : `${text.textScreenStyle.textAlign}`}}>
            {
              (text.h1.length > 0)
              ?<p style={{
                color : `${text.h1Style.color}`,
                fontSize : `${text.h1Style.fontSize}rem`
              }}>{text.h1}</p>
              :null
            }
            {
              (text.description.length > 0)
              ?<p style={{
                color : `${text.pStyle.color}`,
                fontSize : `${text.pStyle.fontSize}rem`
              }}>{text.description}</p>
              :null
            }
            <div className="flex flex-wrap items-center" style={{gap : `${(!mobile)? text.textScreenStyle.gap + "rem" : text.textScreenStyle.gap * 0.75 + "rem" }`}}>
                  <button className={`font-light border-none`}
                  style={{
                    backgroundColor : `${text.ctaStyle.backgroundColor}`,
                    color : `${text.ctaStyle.color}`,
                    fontSize : `${text.ctaStyle.fontSize}rem`,
                    padding : `${text.ctaStyle.padding}rem`,
                    borderRadius : `${text.ctaStyle.borderRadius}px`
                  }}
                  onClick={()=>{setCurrentStep(currentStep + 1);}}>{text.ctaText}</button>
            </div>
          </div>
        </div>
    </MediaPlayer>
  </div>
  );
}

interface EndProps {
    end : ThankYouInterface,
    videoUrl : string,
    mobile : boolean,
    currentStep : number,
    setCurrentStep : Function,
    embed : boolean,
}

const EndOverlay = ( {end, videoUrl, mobile, currentStep, setCurrentStep, embed} : EndProps) : React.ReactElement => { 
  const [bgColor, setBgColor] = useState<any>(JSON.parse(JSON.stringify(end.textScreenStyle!.backgroundColor)));

  useEffect(()=>{
    setBgColor(end.textScreenStyle.backgroundColor);
  },[end.textScreenStyle.backgroundColor])

  return(
    <div className={`${(embed)?"w-full":"w-2/3"} rounded-lg flex justify-center items-center shadow-md`}>
    <MediaPlayer playsInline className='relative w-full h-full flex justify-center items-center rounded-lg'
    title='Test video' src={videoUrl}>
      <MediaProvider>
      </MediaProvider>
      {
        ( end.textScreenStyle.backgroundImage.length > 0 )
        ?<img src={end.textScreenStyle.backgroundImage} alt="form thumbnail" className="z-10 rounded-lg" />
        :null
      }
      <div className={`absolute z-10 rounded-lg w-[calc(100%+5px)] h-[calc(100%+5px)] flex justify-center items-center`} 
        style={{background : `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgColor.a})`}}>
          <div className={`w-[80%] flex flex-col justify-center`} 
          style={{gap : `${(!mobile)? end.textScreenStyle.gap + "rem" : end.textScreenStyle.gap * 0.75 + "rem" }`, alignItems : `${end.textScreenStyle.textAlign}`}}>
            {
              (end.h1.length > 0)
              ?<p style={{
                color : `${end.h1Style.color}`,
                fontSize : `${end.h1Style.fontSize}rem`
              }}>{end.h1}</p>
              :null
            }
            {
              (end.description.length > 0)
              ?<p style={{
                color : `${end.pStyle.color}`,
                fontSize : `${end.pStyle.fontSize}rem`
              }}>{end.description}</p>
              :null
            }
            <div className="flex flex-wrap items-center" style={{gap : `${(!mobile)? end.textScreenStyle.gap + "rem" : end.textScreenStyle.gap * 0.75 + "rem" }`}}>
              {end.ctaList!.map((cta : any, idx : number) => {
                return(
                  <button className={`font-light border-none`} key={idx}
                  style={{
                    backgroundColor : `${end.ctaStyle[idx].backgroundColor}`,
                    color : `${end.ctaStyle[idx].color}`,
                    fontSize : `${end.ctaStyle[idx].fontSize}rem`,
                    padding : `${end.ctaStyle[idx].padding}rem`,
                    borderRadius : `${end.ctaStyle[idx].borderRadius}px`
                  }}
                  onClick={()=>{window.open(cta.url, "_blank");}}>{cta.text}</button>
                );
              })}
            </div>
          </div>
        </div>
    </MediaPlayer>
  </div>
  );
}

interface Props {
    steps : Step[],
    mobile : boolean,
    embed : boolean,
}

export default function FunnelV2Preview( { steps, mobile, embed } : Props ) : React.ReactElement {
    const [videoUrl, setVideoUrl] = useState<string>("");
    const [currentStep, setCurrentStep] = useState<number>(0);

    return(
        <div className="w-full h-full flex justify-center items-center absolute top-0 left-0">
            {
                (steps && steps.length > 0)
                ?<>
                {steps.map((step: Step, idx : number) => {
                    if(step.action === "video" && idx === currentStep){
                      if(videoUrl !== step.video!.url){
                        setVideoUrl(step.video!.url);
                      }
                      return <VideoPlayer mobile={mobile} video={step.video!} embed={embed}
                            currentStep={currentStep} setCurrentStep={setCurrentStep}/>
                    }
                    if(step.action === "form" && idx === currentStep){
                      return <FormOverlay mobile={mobile} form={step.form!} videoUrl={videoUrl} embed={embed}
                            currentStep={currentStep} setCurrentStep={setCurrentStep}/>
                    }
                    if(step.action === "textScreen" && idx === currentStep){
                      return <TextScreenOverlay mobile={mobile} text={step.textScreen!} videoUrl={videoUrl} embed={embed}
                            currentStep={currentStep} setCurrentStep={setCurrentStep}/>
                    }
                    if(step.action === "end screen" && idx === currentStep){
                      return <EndOverlay mobile={mobile} end={step.thankYouPage!} videoUrl={videoUrl} embed={embed}
                            currentStep={currentStep} setCurrentStep={setCurrentStep}/>
                    }
                })}
                </>
                :null
            }
        </div>
    );
}