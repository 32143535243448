import React, {createContext, useEffect, useState} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Step, User } from "./Context";

interface Props {
    children : React.ReactNode
}

export const PublicContext : React.Context<any> = createContext(undefined);

export const PublicContextProvider : React.FC<Props> = (props: Props) : React.ReactElement => {
    const [workspaceUser, setWorkspaceUser] = useState<User>();
    const [params] = useState(useParams());
    const [searchParams] = useSearchParams();
    const [selectedFunnel, setSelectedFunnel] = useState<SelectedFunnel>();
    const [leadId, setLeadId] = useState<string>();
    const [pauseId, setPauseId] = useState<string>();
    const [embedded, setEmbedded] = useState<boolean>(false);

    /*
    FUNNEL FUNCTIONS
    */
    const getSelectedMagnet = async(id : string) : Promise<void> => {
        try{
            const connection = await fetch(`/public/magnet/${id}`);
            if(connection.status === 200){
                const data = await connection.json();
                let funnel : SelectedFunnel = {
                    form_id : data[0]._id,
                    form_name : data[0].form_name,
                    workspace_id : data[0].workspace_id,
                    steps : data[0].steps,
                    pauseId : "",
                    stepData : [],
                    actions :[],
                    lead_id : null,
                    ABtest_id : data[0].ABtest_id,
                    AB_variant : null,
                    showBranding : true
                }

                if(data[0].ABtest_id){
                    const ABconnection = await fetch(`/public/magnet/abtest/${data[0].ABtest_id}`);
                    if(ABconnection.status === 200){
                        const ABdata = await ABconnection.json();
                        console.log(ABdata);
                        if(Math.floor(Math.random() * 2) === 0){
                            funnel.AB_variant = "A";
                            funnel.steps = ABdata[0].Asteps;
                        }else{
                            funnel.AB_variant = "B";
                            funnel.steps = ABdata[0].Bsteps;
                        }

                    }
                }
                const user : string | null = localStorage.getItem("userString");
                if(user){
                    const parsedUser : string = JSON.parse(user);
                    const connection : any = await fetch(`/auth/checkLogin/${parsedUser}`);
                    if(connection.status === 200){
                        const userFound : any = await connection.json();
                        if(userFound.length > 0){
                            setWorkspaceUser({
                                _id : userFound[0].user_id,
                                workspace_id : userFound[0].workspace_id,
                                first_name : userFound[0].first_name,
                                last_name : userFound[0].last_name,
                                email : userFound[0].email,
                                user_rights : userFound[0].user_rights
                            });
                        }
                    }
                }
                document.title = funnel.form_name;
                setSelectedFunnel(funnel);
            }

        }catch(e:any){
            console.log(e);
        }
    }

    /*
    DATA FUNCTIONS
    */
    const sendStepData = async(workspace_id : string, ABtest_id : string | null, variant : string | null, id : string, step : number, action : string, 
        isSubStep? : boolean, subStep? : number) : Promise<void> => {
        if(workspaceUser?.workspace_id !== workspace_id){
            let data : any = {
                workspace_id : workspace_id,
                ABtest_id : ABtest_id,
                variant : variant,
                source : (sessionStorage.getItem("utm_source") === "null")? null : sessionStorage.getItem("utm_source"), 
                medium : (sessionStorage.getItem("utm_medium") === "null")? null : sessionStorage.getItem("utm_medium"), 
                campaign : (sessionStorage.getItem("utm_campaign") === "null")? null : sessionStorage.getItem("utm_campaign"),
            };
            if(isSubStep){
                data.subStep = subStep
            }
            try{
                await fetch(`/public/magnet/newEvent/${action}/${step}/${id}`, {
                    method : "POST",
                    headers : { 
                        'Accept' : "application/json",
                        "Content-Type" : "application/json",
                    },
                    body : JSON.stringify(data)
                });
            }catch(e:any){
                console.log(e);
            }
        }
    }

    const sendPauseData = async(workspace_id : string, ABtest_id : string | null, variant : string | null, id : string, step : number, percentage : number, 
    isSubStep? : boolean, subStep? : number) : Promise<void> => {
        if(workspaceUser?.workspace_id !== workspace_id){
            let data : any = {
                workspace_id : workspace_id,
                ABtest_id : ABtest_id,
                variant : variant,
                pausePercentage : percentage,
                source : (sessionStorage.getItem("utm_source") === "null")? null : sessionStorage.getItem("utm_source"), 
                medium : (sessionStorage.getItem("utm_medium") === "null")? null : sessionStorage.getItem("utm_medium"), 
                campaign : (sessionStorage.getItem("utm_campaign") === "null")? null : sessionStorage.getItem("utm_campaign"),
            };
            try{
                const connection = await fetch(`/public/magnet/pausedSequence/${step}/${id}`, {
                    method : "POST",
                    headers : { 
                        'Accept' : "application/json",
                        "Content-Type" : "application/json",
                    },
                    body : JSON.stringify(data)
                });
                if(connection.status === 200){
                    const data = await connection.json();
                    console.log(data.mssg);
                    setPauseId(data.mssg);
                }

            }catch(e:any){
                console.log(e);
            }
        }
    }

    const deletePauseData = async() : Promise<void> => {
        try{
            const connection = await fetch(`/public/magnet/deletePause/${pauseId}`,{
                method : "DELETE",
            });
            if(connection.status === 200){
                setPauseId(undefined);
            }
        }catch(e:any){
            console.log(e);
        }

    } 

    /*
    LEAD FUNCTIONS
    */
    const submitNewLead = async(selectedFunnel : SelectedFunnel, emailResults : string, formFields : any, stepData : any[]) : Promise<void> => {
        try{
            let lead : any = {
                leadId : (leadId)?leadId:"",
                form_id : selectedFunnel.form_id, 
                quality : "MQL",
                created_at : new Date().toLocaleString("fi-FI"),
                data : stepData,
                workspace_id : selectedFunnel.workspace_id,
                emailResults : emailResults,
                form_name :  selectedFunnel.form_name,
                source : (sessionStorage.getItem("utm_source") === "null")? null : sessionStorage.getItem("utm_source"),
                medium :  (sessionStorage.getItem("utm_medium") === "null")? null : sessionStorage.getItem("utm_medium"),
                campaign :  (sessionStorage.getItem("utm_campaign") === "null")? null : sessionStorage.getItem("utm_campaign"),
            }
            Object.keys(formFields).forEach((key : string) => {
                lead[key] = formFields[key];
            })
            let connection = await fetch(`/public/magnet/newLead`,
                {
                    method : "POST",
                    headers : {'Accept' : "application/json" , "Content-Type" : "application/json"},
                    body : JSON.stringify(lead)
                }
            );
            if(connection.status === 200){
                const data = await connection.json();
                setLeadId(data.id);
            }

        }catch(e:any){
            console.log(e);
        }
    }


    useEffect(()=>{
        if(searchParams.size > 0){
            sessionStorage.setItem("utm_source", searchParams.get("utm_source") || "null");
            sessionStorage.setItem("utm_medium", searchParams.get("utm_medium") || "null");
            sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign") || "null");
            if(searchParams.get("embed")){
                setEmbedded(true);
            }
        }
        if(params.id){
            getSelectedMagnet(params.id);
        };
    }, [params.id, searchParams]);

    return(
        <PublicContext.Provider value={{
            params,
            selectedFunnel,
            sendStepData,
            submitNewLead,
            leadId,
            embedded,
            sendPauseData,
            pauseId,
            setPauseId,
            deletePauseData,
        }}>
            {props.children}
        </PublicContext.Provider>
    );
};

export interface SelectedFunnel {
    form_id : string,
    form_name : string,
    workspace_id : string,
    steps : Step[],
    pauseId : string,
    stepData : any[],
    actions : any[],
    lead_id : string | null,
    ABtest_id : string | null,
    AB_variant : string | null,
    showBranding : boolean
}

