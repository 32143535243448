import React, { useEffect, useRef, useState } from "react";
import { ThankYouInterface } from "../../../Context";


interface Props {
    idx : number,
    thankYouPage : ThankYouInterface,
    setThankYouPage : Function
}

const EndContent = ({idx, thankYouPage, setThankYouPage}:Props) : React.ReactElement => {
    const headerRef = useRef<HTMLInputElement>(null);
    const descRef = useRef<HTMLTextAreaElement>(null);
    const ctaRef = useRef<HTMLInputElement>(null);
    const ctaURLRef = useRef<HTMLInputElement>(null);

    const [endDraft, setEndDraft] = useState<ThankYouInterface>(thankYouPage);

    const updateChanges = () => {
        const newEnd : ThankYouInterface = {
            h1 : (headerRef.current?.value) ? headerRef.current!.value : thankYouPage.h1,
            description : (descRef.current?.value) ? descRef.current!.value : thankYouPage.description,
            ctaUrl : (ctaURLRef.current?.value) ? ctaURLRef.current!.value : thankYouPage.ctaUrl,
            ctaText : (ctaRef.current?.value) ? ctaRef.current!.value : thankYouPage.ctaText,
            textScreenStyle : thankYouPage.textScreenStyle,
            h1Style : thankYouPage.h1Style,
            pStyle : thankYouPage.pStyle,
            ctaStyle : thankYouPage.ctaStyle
        }
        setThankYouPage(newEnd);
        setEndDraft(newEnd);
        sessionStorage.setItem(`end${idx}`, JSON.stringify(newEnd));
    }

    useEffect(()=>{
        const sessionEnd = sessionStorage.getItem(`end${idx}`);
        if(sessionEnd){
            setEndDraft(JSON.parse(sessionEnd));
        }
    }, []);

    return(
        <div className="w-full flex flex-row">
            <div className="w-2/3">
                <div className="flex flex-wrap gap-5 p-2 mt-5">
                    <form onChange={updateChanges} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl">End Screen Text</p>
                        <div className="w-full">
                            <p>Header</p>
                            <input type="text" ref={headerRef} defaultValue={endDraft.h1} placeholder="Form Header..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>Description</p>
                            <textarea cols={3} ref={descRef} defaultValue={endDraft.description} rows={4} placeholder="Engaging Description..." 
                            className="input input-bordered rounded-2xl w-full h-fit"/>
                        </div>
                    </form>
                    <form onChange={updateChanges} className="w-[calc(50%-0.625rem)] p-5 flex flex-col gap-2 border-brandLightGray border-2 rounded-xl">
                        <p className="text-xl w-full">CTA & Next URL</p>
                        <div className="w-full">
                            <p>CTA text</p>
                            <input type="text" ref={ctaRef} defaultValue={endDraft.ctaText} placeholder="CTA Text..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                        <div className="w-full">
                            <p>CTA URL</p>
                            <input type="text" ref={ctaURLRef} defaultValue={endDraft.ctaUrl} placeholder="CTA URL..." 
                            className="input input-bordered rounded-full w-full"/>
                        </div>
                    </form>
                </div>
            </div>
            <div className="w-1/3 mt-5 p-2">
                <div className="p-5 border-2 border-brandLightGray rounded-lg">
                    <p className="mb-2">Funnel Step Preview:</p>
                    <div className={`text-${endDraft.textScreenStyle.textAlign} flex flex-col gap-3`} style={{padding : `${endDraft.textScreenStyle.padding}px`, 
                        backgroundColor : `${endDraft.textScreenStyle.backgroundColor}`}}>
                        {(endDraft.h1)?
                        <p style={{fontSize : `${endDraft.h1Style.fontSize}px`, color : `${endDraft.h1Style.color}`}}>
                            {endDraft.h1}
                        </p>
                        :null}
                        {(endDraft.description)?
                        <p style={{fontSize : `${endDraft.pStyle.fontSize}px`, color : `${endDraft.pStyle.color}`}}>
                            {endDraft.description}
                        </p>
                        :null}
                        <button
                        style={{fontSize : `${endDraft.ctaStyle.fontSize}px`, padding :  `${endDraft.ctaStyle.padding}px`, borderRadius : `${endDraft.ctaStyle.borderRadius}px`,
                        color :  `${endDraft.ctaStyle.color}`, backgroundColor :  `${endDraft.ctaStyle.backgroundColor}`}}>
                            {(endDraft.ctaText)?endDraft.ctaText:"Continue"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EndContent;