import React, { useEffect, useRef, useState } from "react";
import { Step, VideoInterface } from "../../../Context";
import { MdOndemandVideo } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import VideoContent from "./VideoContent";
import VideoStyle from "./VideoStyle";



interface ChangeList {
    stepIdx : number,
    action : string
}

interface Props {
    steps : Step[],
    setSteps : Function,
    step : Step,
    idx : number,
    editOnly : boolean,
    changesMadeTo? : ChangeList[],
    setChangesMadeTo? : Function,
}

const VideoStep = ({steps, setSteps, step, idx, editOnly, changesMadeTo, setChangesMadeTo}:Props) : React.ReactElement => {

    //Video Object -values;
    const [editVideo, setEditVideo] = useState<boolean>(false);
    const [editScreen, setEditScreen] = useState<string>("content");
    const [video, setVideo] = useState<VideoInterface>({
        url : (step.video) ? step.video.url : "",
        poster : (step.video) ? step.video.poster : "",
        showTimeLeft : (step.video) ? step.video.showTimeLeft : false,
        timeLeftPersentage : (step.video) ? step.video.timeLeftPersentage : 50,
        timeLeftText : (step.video) ? step.video.timeLeftText : "",
        CTAscreen : (step.video && step.video.CTAscreen) ? step.video.CTAscreen : false,
        title : (step.video) ? step.video.title : "",
        description : (step.video) ? step.video.description : "",
        ctaText : (step.video) ? step.video.ctaText : "",
        videoStyle : (step.video && step.video.videoStyle) ? step.video.videoStyle : {width : "100", borderRadius : "10"},
        textScreenStyle : (step.video && step.video.textScreenStyle) ? step.video.textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : ""},
        h1Style : (step.video && step.video.h1Style) ? step.video.h1Style : {fontSize : "", color : ""},
        pStyle : (step.video && step.video.pStyle) ? step.video.pStyle : {fontSize : "", color : ""},
        ctaStyle : (step.video && step.video.ctaStyle) ? step.video.ctaStyle : {fontSize : "", color : "", padding :"", borderRadius : "", backgroundColor : ""}
    });
    const videoRef = useRef<HTMLVideoElement>(null);
    const [preview, setPreview] = useState({CTAscreen : (step.video && step.video.CTAscreen) ? step.video.CTAscreen : false, timeLeft : false , timeText : 0});

    const saveToStorage = () => {
        sessionStorage.removeItem(`video${idx}`);
        sessionStorage.setItem(`steps`, JSON.stringify(steps));
    }

    const setGeneralPreview = (boolean : boolean) => {
        setPreview({...preview, CTAscreen : boolean});
    }

    const DisplayTimeLeft = () => {
        if(videoRef.current?.currentTime){
            if(videoRef.current.duration){
                if(video.timeLeftPersentage){
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 > video.timeLeftPersentage){
                        setPreview({...preview, timeLeft : true, timeText : Number((videoRef.current.duration - videoRef.current.currentTime).toFixed(0))});
                    }else{
                        setPreview({...preview, timeLeft : false, timeText : 0});
                    }
                }
            }
        }
    }

    const removeStep = () => {
        sessionStorage.removeItem(`video${idx}`);
        sessionStorage.setItem(`steps`, JSON.stringify(steps.filter((stepRemoval : Step) => stepRemoval !== steps[idx])));
        setSteps(steps.filter((stepRemoval : Step) => stepRemoval !== steps[idx]));
    }

    useEffect(()=>{
        const sessisonSteps = sessionStorage.getItem(`steps`);
        if(sessisonSteps){
            const parsedSteps = JSON.parse(sessisonSteps);
            if(parsedSteps[idx] && parsedSteps[idx].video){
                setPreview({...preview, CTAscreen : (parsedSteps[idx].video.CTAscreen)?parsedSteps[idx].video.CTAscreen:false});      
            }
        }
    }, []);

    const [editContent, setEditContent] = useState<React.ReactElement>(<VideoContent setGeneralPreview={setGeneralPreview} video={video} setVideo={setVideo} idx={idx} saveToStorage={true}/>);

    return(
        <div className="bg-brandLightGray p-3 2xl:w-1/3 xl:w-1/2 lg:w-1/2 rounded-xl shadow-md" key={idx}>
            <div className="flex flex-row justify-start items-center">
                <div className="w-1/2">
                    <p className="flex flex-row justify-start items-center text-lg"><MdOndemandVideo className="size-6 mr-1 text-brandPurple"/><b>Step {idx + 1}:</b> Video</p>
                </div>
                <div className="w-1/2 flex justify-end gap-2">
                    {(editOnly)
                    ?null
                    :<button className="btn rounded-full border-red boder-[1px] text-black bg-white hover:bg-red hover:text-white font-light"
                    onClick={removeStep}>
                        <MdDeleteForever className="size-4"/>Remove
                    </button>
                    }
                    <button onClick={()=>{setEditVideo(true);}} 
                    className="btn rounded-full font-light border-[1px] text-sm border-brandPurple bg-white hover:bg-brandLightPurple hover:text-white">
                        <FaPen className="size-4"/> Edit Step
                    </button>
                </div>
            </div>
            <div className="p-2">
                    {
                        (preview.CTAscreen)
                        ?<div className={`text-${video.textScreenStyle.textAlign}`} style={{
                            padding : `${video.textScreenStyle.padding}px`, 
                            backgroundColor :`${video.textScreenStyle.backgroundColor}`
                        }}>
                            {(video.title)?<p style={{fontSize : `${video.h1Style.fontSize}px`, color: `${video.h1Style.color}`}}>
                                                    {video.title}
                                                </p>:null}
                            {(video.description)?<p className="mt-5" style={{fontSize : `${video.pStyle.fontSize}px`, color: `${video.pStyle.color}`}}>
                                                        {video.description}
                                                      </p>:null}
                            {(video.ctaText)
                            ?<button className="mt-5" style={{
                                fontSize : `${video.ctaStyle.fontSize}px`, 
                                color: `${video.ctaStyle.color}`, backgroundColor :`${video.ctaStyle.backgroundColor}`,
                                padding:`${video.ctaStyle.padding}px`, borderRadius : `${video.ctaStyle.borderRadius}px`
                            }}
                            onClick={()=>{setPreview({...preview, CTAscreen:false})}}>
                                {video.ctaText}
                            </button>
                            :null}
                        </div>
                        :<div>
                            <video ref={videoRef} src={video.url} poster={video.poster} onTimeUpdate={DisplayTimeLeft} controls 
                            style={{width : `${video.videoStyle.width}%`, borderRadius : `${video.videoStyle.borderRadius}px`}}>
                            </video>
                            {(preview.timeLeft && video.showTimeLeft)
                            ?<p className="text-right">{`${video.timeLeftText} : ${preview.timeText}s`}</p>
                            :null
                            }
                        </div>
                    }
            </div>
            <input type="checkbox" checked={editVideo} id="videoEditor" className="modal-toggle" readOnly/>
            <div className="modal" role="dialog">
                <div className="modal-box w-[80%] max-w-none">
                    <div className="flex flex-row justify-start items-center pb-5 border-b-[1px]">
                        <div className="w-1/3">
                            <p className="text-lg"><b>Editing:</b> Step {idx + 1}: Video</p>
                        </div>
                        <div className="w-1/3 join flex justify-center">
                            <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                            ${(editScreen === "content")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                            onClick={()=>{setEditContent(
                                <VideoContent setGeneralPreview={setGeneralPreview} setVideo={setVideo} video={video} idx={idx} saveToStorage={true}/>
                            ); setEditScreen("content")}}>
                                Content
                            </button>
                            <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                            ${(editScreen === "style")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                            onClick={()=>{setEditContent(<VideoStyle setVideo={setVideo} video={video} idx={idx} saveToStorage={true}/>); setEditScreen("style");}}>Style</button>
                        </div>
                        <div className="w-1/3 flex justify-end gap-2">
                            <button className="btn rounded-full border-2 border-red text-red font-light hover:bg-red hover:text-white bg-white"
                            onClick={()=>{setEditVideo(false);}}>Cancel</button>
                            <button className="btn bg-brandPurple text-white rounded-full font-light hover:bg-brandLightPurple" 
                            onClick={()=>{setEditVideo(false); step.video = video; saveToStorage();
                            if(setChangesMadeTo && changesMadeTo){setChangesMadeTo([...changesMadeTo, {stepIdx : idx, action : "video"}])}; }}>Save & Close</button>
                        </div>
                    </div>
                    {editContent}
                </div>
            </div>
        </div>
    );
};

export default VideoStep;