import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { Context, Step } from "../../components/Context";
import StepSelectionModal from "../../components/user/MagnetCreation/StepSelectionModal";
import FunnelBuilder from "../../components/user/MagnetCreation/FunnelBuilder";



const CreateNewMagnet = () : React.ReactElement => {
    const {workspace, user, userToken, newLeadMagnet, setNewLeadMagnet, steps, setSteps, saveNewForm} = useContext(Context);
    const [nameEditor, setNameEditor] = useState<boolean>(false);
    const [addNewHover, setAddNewHover] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const [showStepSelection, setShowStepSelection] = useState<boolean>(false);
    const [endScreenAdded, setEndScreenAdded] = useState<boolean>(false);

    const getFromStorage = () : void => {
        const sessionName : string | null = sessionStorage.getItem("newMagnet-name");
        if(sessionName){
            setName(JSON.parse(sessionName));
        }
         const sessisonSteps : string | null = sessionStorage.getItem("steps");
        if(sessisonSteps){
            setSteps(JSON.parse(sessisonSteps))
            const parsedSteps : Step[] = JSON.parse(sessisonSteps);
            parsedSteps.forEach((step : Step) => {
                if(step.action === "end screen"){
                    setEndScreenAdded(true);
                }
            })
        }
    }

    const handleAddNew = () => {
        sessionStorage.removeItem("steps");
        sessionStorage.removeItem("newMagnet-name");
        saveNewForm(workspace._id, user._id, userToken, name, steps);
    }

    useEffect(()=>{
        getFromStorage();
    }, []);

    return(
        <div className="h-full">
            <div className="flex flex-row gap-5 p-5 justify-start items-center w-full z-10 sticky shadow-md bg-brandLightGray">
                <div className="w-1/2 flex flex-row justify-start items-center gap-5">
                    <Link to="/sequences" className="flex flex-row justify-start items-center text-lg text-brandPurple cursor-pointer"
                    onClick={()=>{sessionStorage.clear();}}>
                        <MdArrowBackIosNew className="size-4"/>Cancel & Exit
                    </Link>
                    <div className="">
                        {
                            (nameEditor)
                            ?<div className="flex flex-row justify-start items-center gap-3">
                            <input type="text" value={name} className="input input-bordered w-fit border-brandLightGray"
                            placeholder={`New Lead Magnet - ${new Date().toLocaleDateString("fi-FI")}`} 
                            onChange={(e)=>{setName(e.target.value as string); sessionStorage.setItem("newMagnet-name", JSON.stringify(e.target.value as string))}}/>
                            <button className="btn border-red font-light text-red hover:text-white hover:bg-red bg-white rounded-2xl" 
                            onClick={()=>{setNameEditor(false);}}>Cancel</button>
                            <button className="btn bg-brandPurple text-white font-light hover:bg-brandLightPurple rounded-2xl"
                            onClick={()=>{setNewLeadMagnet({...newLeadMagnet, form_name : name}); setNameEditor(false);}}
                            >Save</button>
                            </div>
                            :<p 
                            className="text-lg flex flex-row justify-start items-center hover:border-brandLightPurple hover:border-2 p-2 border-2 border-brandLightGray cursor-pointer rounded-xl" 
                            onClick={()=>{setNameEditor(true)}}>
                                {(name)?name:`New Lead Magnet - ${new Date().toLocaleDateString("fi-FI")}`} <MdEdit className="size-4 ml-2"/>
                            </p>
                        }
                    </div>
                </div>
                <div className="w-1/2 flex justify-end">
                    {(steps && steps.length)
                    ?<button className="btn font-light bg-brandPurple text-white hover:bg-brandLightPurple rounded-full"
                     onClick={handleAddNew}
                     disabled={!name && steps.length === 0 && (!steps[0].video || !steps[0].form || !steps[0].thankYouPage)}>
                        Save and continue
                    </button>
                    :<button className="btn font-light bg-brandPurple text-white hover:bg-brandLightPurple rounded-full" disabled>
                        Save and continue
                    </button>
                    }
                </div>
            </div>
            <div className="flex flex-row justify-start items-start">
                <div className="w-full h-full bg-white flex flex-col pt-20 pb-20 p-10 justify-center items-center">
                    <div className="flex flex-col justify-center items-center w-full">
                        <p>Start</p>
                        <FaCircle className="size-8 text-brandPurple m-0"/>
                        <div className="h-10 w-1 bg-brandPurple"></div>
                    </div>
                    {
                        (steps && steps.length > 0)
                        ?<>
                            <FunnelBuilder steps={steps} setSteps={setSteps} setEndScreenAdded={setEndScreenAdded}/>
                        </>
                        :null
                    }  
                    {
                        (endScreenAdded)
                        ?null
                        :<StepSelectionModal 
                        addNewHover={addNewHover} setShowStepSelection={setShowStepSelection} 
                        setAddNewHover={setAddNewHover} showStepSelection={showStepSelection}
                        setEndScreenAdded={setEndScreenAdded} endScreenAdded={endScreenAdded}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default CreateNewMagnet;