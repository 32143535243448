import React, { useState } from "react";
import { Step, TailoredStep, TailoringInterface } from "../../../Context";
import { FaPen } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { MdDashboardCustomize } from "react-icons/md";
import { FaThumbsDown } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa6";
import { FaQuestion } from "react-icons/fa";
import TailoringContent from "./TailoringContent";
import TailoringQuestions from "./TailoringQuestions";
import TailoredVideo from "./TailoredVideo";
import TailoringStyle from "./TailoringStyle";

interface ChangeList {
    stepIdx : number,
    action : string
}

interface Props {
    step : Step,
    steps : Step[],
    setSteps : Function,
    idx : number,
    editOnly : boolean,
    changesMadeTo? : ChangeList[],
    setChangesMadeTo? : Function
}

const TailoringStep = ({step, steps, setSteps, idx, editOnly, changesMadeTo, setChangesMadeTo}:Props) : React.ReactElement => {
    const [editForm, setEditForm] = useState<boolean>(false);
    const [tailored, setTailored] = useState<TailoredStep>(step.tailored!);
    const [editScreen, setEditScreen] = useState<string>("content");
    const [editContent, setEditContent] = useState<React.ReactElement>(<TailoringContent idx={idx} tailor={tailored} setTailorScreen={setTailored}/>);

    const saveToStorage = () => {
        sessionStorage.removeItem(`TailoringQuesitons${idx}`);
        sessionStorage.setItem(`steps`, JSON.stringify(steps));
    }

    const removeStep = () => {
        sessionStorage.removeItem(`TailoringQuestions${idx}`)
        sessionStorage.setItem(`steps`, JSON.stringify(steps.filter((stepRemoval : Step) => stepRemoval !== steps[idx])));
        setSteps(steps.filter((stepRemoval : Step) => stepRemoval !== steps[idx]));
    }

    return(
        <div className="bg-[rgba(52,199,89,0.3)] p-3 w-3/5 rounded-xl shadow-md" key={idx}>
            <div className="bg-brandLightGray p-3 w-full rounded-xl">
                <div className="flex flex-row justify-start items-center">
                    <div className="w-1/2">
                        <p className="flex flex-row justify-start items-center text-lg"><MdDashboardCustomize className="size-6 mr-1 text-brandPurple"/><b>Step {idx + 1}:</b> Tailored demo</p>
                    </div>
                    <div className="w-1/2 flex justify-end gap-2">
                        {(editOnly)
                            ?null
                            :<button className="btn rounded-full border-red boder-[1px] text-black bg-white hover:bg-red hover:text-white font-light"
                            onClick={removeStep}>
                                <MdDeleteForever className="size-4"/>Remove
                            </button>
                        }
                        <button onClick={()=>{setEditForm(true);}} 
                        className="btn rounded-full font-light border-[1px] text-sm border-brandPurple bg-white hover:bg-brandLightPurple hover:text-white">
                            <FaPen className="size-4"/> Edit Step
                        </button>
                    </div>
                </div>
                <div className="m-2" style={{padding : `${tailored.textScreenStyle.padding}px`, backgroundColor : `${tailored.textScreenStyle.backgroundColor}`}}>
                    <div className={`text-${"left"} flex flex-col gap-3`}>
                        <div className="flex flex-row">
                            <div className="flex flex-col gap-5 w-3/5">
                                <p style={{fontSize :`${tailored.h1Style.fontSize}px`, color: `${tailored.h1Style.color}`}}>{tailored.header}</p>
                                <p style={{fontSize :`${tailored.pStyle.fontSize}px`, color: `${tailored.pStyle.color}`}}>{tailored.description}</p>
                            </div>
                            <div className="flex justify-end items-end w-2/5">
                                <button className="btn font-light" style={{fontSize :`${tailored.ctaStyle.fontSize}px`, color: `${tailored.ctaStyle.color}`,
                                padding : `${tailored.ctaStyle.padding}px`, borderRadius : `${tailored.ctaStyle.borderRadius}px`,
                                backgroundColor : `${tailored.ctaStyle.backgroundColor}`}}>{tailored.ctaString}</button>
                            </div>
                        </div>
                        <div className="p-5 rounded-lg" style={{backgroundColor : `${tailored.tableStyle.backgroundColor}`}}>
                            <table className="w-full">
                                <thead className="">
                                    <tr className="">
                                        <th className="w-2/5 text-left" style={{fontSize :`${tailored.tableStyle.titleFont}px`, color: `${tailored.tableStyle.titleColor}`}}>
                                            {tailored.topicString}
                                        </th>
                                        {tailored.importanceStrings.map((importance, idx) => {
                                            return(
                                                <th className="w-1/5 text-center" key={idx}  
                                                style={{fontSize :`${tailored.tableStyle.titleFont}px`, color: `${tailored.tableStyle.titleColor}`}}>
                                                    {importance}<br/>
                                                    <span className="text-xs cursor-pointer" style={{color: `${tailored.ctaStyle.backgroundColor}`}}>
                                                        {tailored.selectAllString}
                                                    </span>
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                            </table>
                            <div className="divider divider-neutral"></div>
                            <table className="w-full mt-5">
                            <tbody className="mt-5">
                                    {tailored.questions.map((question : any, idx : number)=> {
                                        return(
                                            <tr style={{backgroundColor : `${(idx % 2 !== 0)? tailored.tableStyle.backgroundColor : `${tailored.tableStyle.backgroundColor} 50%`}`}}>
                                                <td className="p-5 w-2/5" style={{fontSize :`${tailored.tableStyle.textFont}px`, color: `${tailored.tableStyle.textColor}`}}>
                                                    {question.question}
                                                </td>
                                                <td className={`w-1/5 text-center p-5 ${(question.importance === 3) ? "bg-green text-white" : "hover:bg-green hover:text-white opacity-70"} cursor-pointer transition-all`}
                                                onClick={()=>{}}>
                                                    <p className="flex justify-center items-center"><FaThumbsUp /></p>
                                                </td>
                                                <td className={`w-1/5 text-center p-5 ${(question.importance === 2) ? "bg-yellow" : "hover:bg-yellow opacity-70"} cursor-pointer transition-all`}
                                                onClick={()=>{}}>
                                                    <p className="flex justify-center items-center"><FaQuestion /></p>
                                                </td>
                                                <td className={`w-1/5 text-center p-5 ${(question.importance === 1) ? "bg-red text-white" : "hover:bg-red hover:text-white opacity-70"} cursor-pointer transition-all`}
                                                onClick={()=>{}}>
                                                    <p className="flex justify-center items-center"><FaThumbsDown /></p>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <input type="checkbox" checked={editForm} id="videoEditor" className="modal-toggle" readOnly/>
                <div className="modal" role="dialog">
                    <div className="modal-box w-[80%] max-w-none">
                        <div className="flex flex-row justify-start items-center pb-5 border-b-[1px]">
                            <div className="w-1/3">
                                <p className="text-lg"><b>Editing:</b> Step {idx + 1}: Tailored demo</p>
                            </div>
                            <div className="w-1/3 join flex justify-center">
                                <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                                ${(editScreen === "content")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                                onClick={()=>{; setEditScreen("content"); setEditContent(<TailoringContent idx={idx} tailor={tailored} setTailorScreen={setTailored}/>);}}>
                                    Content
                                </button>
                                <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                                ${(editScreen === "questions")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                                onClick={()=>{; setEditScreen("questions"); setEditContent(<TailoringQuestions idx={idx} tailor={tailored} setTailorScreen={setTailored}/>)}}>
                                    Questions
                                </button>
                                <button className={`join-item btn rounded-full font-light hover:bg-brandLightPurple hover:text-brandLightGray
                                ${(editScreen === "style")?"bg-brandPurple text-white":"bg-brandLightGray text-black"}`}
                                onClick={()=>{setEditScreen("style"); setEditContent(<TailoringStyle idx={idx} tailored={tailored} setTailored={setTailored}/>)}}>Style</button>
                            </div>
                            <div className="w-1/3 flex justify-end gap-2">
                                <button className="btn rounded-full border-2 border-red text-red font-light hover:bg-red hover:text-white bg-white"
                                onClick={()=>{setEditForm(false);}}>Cancel</button>
                                <button className="btn bg-brandPurple text-white rounded-full font-light hover:bg-brandLightPurple" 
                                onClick={()=>{setEditForm(false); step.tailored = tailored; saveToStorage();
                                if(setChangesMadeTo && changesMadeTo){setChangesMadeTo([...changesMadeTo, {stepIdx : idx, action : "tailored"}])}; }}>Save & Close</button>
                            </div>
                        </div>
                        {editContent}
                    </div>
                </div>
            </div>
            <div>
                {tailored.questions.map((question : TailoringInterface, idx : number) => {
                    return <div className="flex flex-col justify-center items-center w-full">
                                <div className="h-10 w-1 bg-brandPurple"></div>
                                <TailoredVideo steps={steps} idx={idx} step={question}/>
                            </div>
                })}
            </div>
        </div>
    );
};

export default TailoringStep;