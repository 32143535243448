import React from "react";
import { IoIosAddCircle } from "react-icons/io";
import { MdOndemandVideo } from "react-icons/md";
import { MdFormatListBulleted } from "react-icons/md";
import { GrLinkNext } from "react-icons/gr";
import { RiTextSnippet } from "react-icons/ri";
import { Step } from "../../Context";

export const emptyVideo : Step = {
    action : "video",
    video : {
        stepName : "",
        url : "https://cxtailor.com/wp-content/uploads/2024/09/CX-Tailor-The-Solution-Mika.mp4",
        poster : "",
        showTimeLeft : true,
        timeLeftPersentage : 50,
        timeLeftText : "Next video starts in",
        CTAscreen : true,
        title : "Watch a quick video",
        description : "See how our soluion works from a quick 2 min video",
        ctaText : "Start video",
        autoPlay : false,
        videoStyle : {width : "100", borderRadius : "10"},
        textScreenStyle : {textAlign : "start", padding : "20", backgroundColor : {r:35, g:37, b:38, a:0.75}, gap : 1.6},
        h1Style : {fontSize : "1.5", color : "#ffffff"},
        pStyle : {fontSize : "1.125", color : "#ffffff"},
        ctaStyle : {fontSize : "1.125", color : "#ffffff", padding :"1", borderRadius : "10", backgroundColor : "#6156a5"},
        playButtonStyle : {size : "6", color : "#ffffff", backgroundColor : "#6156a5", padding : "1"},
        controlStyle : {progressColor : "#6156a5", barColor : "#ffffff", position: "top", volumeSize: "3", volumePadding: "0.75", volumeColor:"#ffffff", volumeBackgroundColor : "#6156a5"},
        teaserStyle : {size: "1", color : "#ffffff", backgroundColor : "#6156a5", padding: "1", y : "bottom", x : "right"}
    }
}

export const emptyForm : Step = {
    action : "form",
    form : {
        header : "Before you continue...",
        description : "Fill in your contact details to access the rest of the demo.",
        inputFields : [
            {type : "text", name : "firstName", label : "first name", viewValue : "First name"},
            {type : "email", name : "email", label : "email", viewValue : "Email"}
        ],
        emailResults : "",
        emailNotification : false,
        redirect : false,
        redirectUrl : "",
        ctaText : "Continue",
        textScreenStyle : {textAlign : "start", padding : "20", backgroundColor : {r:35, g:37, b:38, a:0.75}, gap : 1.6, backgroundImage : ""},
        h1Style : {fontSize : "1.5", color : "#ffffff"},
        pStyle : {fontSize : "1.125", color : "#ffffff"},
        ctaStyle : {fontSize : "1.125", color : "#ffffff", padding :"1", borderRadius : "10", backgroundColor : "#6156a5"},
        labelStyle : {fontSize : "1", color : "#ffffff"},
        inputStyle : {width : "50", padding : "1", fontSize :"1", borderRadius : "10", backgroundColor : "#ffffff", color : "#000000"},
    }
}

export const emptyTextScreen : Step = {
    action : "textScreen",
    textScreen : {
        h1 : "Continue forwad",
        description : "Click the button below to continue your demo",
        ctaText : "Continue",
        textScreenStyle : {textAlign : "start", padding : "20", backgroundColor : {r:35, g:37, b:38, a:0.75}, gap : 1.6, backgroundImage : ""},
        h1Style : {fontSize : "1.5", color : "#ffffff"},
        pStyle : {fontSize : "1.125", color : "#ffffff"},
        ctaStyle : {fontSize : "1.125", color : "#ffffff", padding :"1", borderRadius : "10", backgroundColor : "#6156a5"},
    }
}

export const emptyEndScreen : Step = {
    action : "end screen",
    thankYouPage : {
        h1 : "Thank you for completing your demo",
        description : "Create your own engaging lead funnels at cxtailor.com",
        ctaText : "Continue to next page",
        ctaUrl : "",
        ctaList : [{text : "Continue to next page", url : ""}],
        textScreenStyle : {textAlign : "start", padding : "20", backgroundColor : {r:35, g:37, b:38, a:0.75}, gap : 1.6, backgroundImage : ""},
        h1Style : {fontSize : "1.5", color : "#ffffff"},
        pStyle : {fontSize : "1.125", color : "#ffffff"},
        ctaStyle : [{fontSize : "1.125", color : "#ffffff", padding :"1", borderRadius : "10", backgroundColor : "#6156a5"}],
    }
}

interface Props {
    steps : any,
    setSteps : Function,
    setSelectedStep : Function,
    endingAdded : boolean,
    setEndingAdded : Function,
}

export default function StepSelectionDropDown({ steps, setSteps, setSelectedStep, endingAdded, setEndingAdded } : Props) : React.ReactElement {

    return(
        <>
        {
            (endingAdded)
            ?<div className="tooltip tooltip-bottom w-1/2" data-tip="Ending already added">
                <p className="w-full flex flex-row justify-end items-center gap-1 text-brandPurple/50 hover:underline cursor-not-allowed">
                    <IoIosAddCircle className="size-7"/> Add new step
                </p>
            </div>
            :<div className="dropdown dropdown-end w-1/2">
                <p tabIndex={0} className="w-full flex flex-row justify-end items-center gap-1 text-brandPurple hover:underline cursor-pointer">
                    <IoIosAddCircle className="size-7"/> Add new step
                </p>
                <ul tabIndex={0} className="menu menu-sm dropdown-content bg-brandLightGray z-10 w-60 mt-2 p-2 shadow-md rounded-lg">
                    <li>
                        <div className="hover:bg-brandPurple hover:text-white m-2"
                        onClick={()=>{setSteps([...steps, JSON.parse(JSON.stringify(emptyVideo))]); setSelectedStep((steps.length)? steps.length : 0)}}>
                            <p className="flex flex-row justify-start items-center gap-2 text-lg p-2"><MdOndemandVideo className="size-6"/> Video Step</p>
                        </div>
                    </li>
                    <li>
                        <div className="hover:bg-brandPurple hover:text-white m-2"
                        onClick={()=>{setSteps([...steps, JSON.parse(JSON.stringify(emptyForm))]); setSelectedStep((steps.length)? steps.length : 0)}}>
                            <p className="flex flex-row justify-start items-center gap-2 text-lg p-2"><MdFormatListBulleted className="size-6"/> Form Step</p>
                        </div>
                    </li>
                    <li>
                        <div className="hover:bg-brandPurple hover:text-white m-2"
                        onClick={()=>{setSteps([...steps, JSON.parse(JSON.stringify(emptyTextScreen))]); setSelectedStep((steps.length)? steps.length : 0)}}>
                            <p className="flex flex-row justify-start items-center gap-2 text-lg p-2"><RiTextSnippet className="size-6"/> Text Screen</p>
                        </div>
                    </li>
                    <li>
                        <div className="hover:bg-brandPurple hover:text-white m-2"
                        onClick={()=>{setSteps([...steps, JSON.parse(JSON.stringify(emptyEndScreen))]); setSelectedStep((steps.length)? steps.length : 0); setEndingAdded(true);}}>
                            <p className="flex flex-row justify-start items-center gap-2 text-lg p-2"><GrLinkNext className="size-6"/> Ending</p>
                        </div>
                    </li>
                </ul>
            </div>
        }
        </>
    );
}