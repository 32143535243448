import React, { useContext, useEffect, useRef, useState } from "react";
import { FormInterface, Step, TailoredStep, TailoringInterface, TextScreenInterface, ThankYouInterface, VideoInterface } from "../../components/Context";
import { PublicContext, SelectedFunnel } from "../../components/PublicContext";
import { FaThumbsDown } from "react-icons/fa6";
import { FaThumbsUp } from "react-icons/fa6";
import { FaQuestion } from "react-icons/fa";

interface VideoPrevProps {
    video : VideoInterface,
    setCurrentStep : Function,
    currentStep : number,
    sendStepData : Function,
    selectedFunnel : SelectedFunnel,
    isSubStep : boolean,
    subStep? : number,
    embedded : boolean,
}

const VideoPreview = ({video, setCurrentStep, currentStep, sendStepData, selectedFunnel, isSubStep, subStep, embedded}: VideoPrevProps) : React.ReactElement => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [playRecorded, setPlayRecorded] = useState<boolean>(false);
    const videoPrev : VideoInterface = (
        (video.textScreenStyle && video.h1Style && video.pStyle && video.ctaStyle)
        ? video
        : {
            url : video.url,
            poster : video.poster,
            showTimeLeft : video.showTimeLeft,
            timeLeftPersentage : video.timeLeftPersentage,
            timeLeftText : video.timeLeftText,
            CTAscreen : video.CTAscreen,
            title : video.title,
            description : video.description,
            ctaText : video.ctaText,
            videoStyle : {width : "100", borderRadius : "10"},
            textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : ""},
            h1Style : {fontSize : "24", color : ""},
            pStyle : {fontSize : "18", color : ""},
            ctaStyle : {fontSize : "18", color : "", padding :"", borderRadius : "", backgroundColor : ""}
        }
    ) 
    const [preview, setPreview] = useState({CTAscreen : video.CTAscreen, timeLeft : false , timeText : 0});

    const DisplayTimeLeft = () => {
        if(videoRef.current?.currentTime){
            if(videoRef.current.duration){
                if(video.timeLeftPersentage){
                    if((videoRef.current.currentTime / videoRef.current.duration) * 100 >= video.timeLeftPersentage){
                        setPreview({...preview, timeLeft : true, timeText : Number((videoRef.current.duration - videoRef.current.currentTime).toFixed(0))});
                    }else{
                        setPreview({...preview, timeLeft : false, timeText : 0});
                    }
                }
            }
        }
    }

    return(
        <div className="w-full h-full flex justify-center items-center" style={{backgroundColor : `${videoPrev.textScreenStyle.backgroundColor}`}}>
            {
                (preview.CTAscreen)
                ?<div className={`text-${videoPrev.textScreenStyle.textAlign} w-2/3`} style={{
                    padding : `${videoPrev.textScreenStyle.padding}px`, 
                    backgroundColor :`${videoPrev.textScreenStyle.backgroundColor}`
                }}>
                    {(videoPrev.title)?<p style={{fontSize : `${videoPrev.h1Style.fontSize}px`, color: `${videoPrev.h1Style.color}`}}>
                                            {videoPrev.title}
                                        </p>:null}
                    {(videoPrev.description)?<p className="mt-5" style={{fontSize : `${videoPrev.pStyle.fontSize}px`, color: `${videoPrev.pStyle.color}`}}>
                                                {videoPrev.description}
                                                </p>:null}
                    {(videoPrev.ctaText)
                    ?<button className="mt-5" style={{
                        fontSize : `${videoPrev.ctaStyle.fontSize}px`, 
                        color: `${videoPrev.ctaStyle.color}`, backgroundColor :`${videoPrev.ctaStyle.backgroundColor}`,
                        padding:`${videoPrev.ctaStyle.padding}px`, borderRadius : `${videoPrev.ctaStyle.borderRadius}px`
                    }}
                    onClick={()=>{setPreview({...preview, CTAscreen:false})}}>
                        {videoPrev.ctaText}
                    </button>
                    :null}
                </div>
                :<div className={`${(embedded)?"w-full":"w-2/3"}`}>
                    <video ref={videoRef} src={videoPrev.url} poster={videoPrev.poster} onPlay={()=>{
                        if(!playRecorded){
                            if(isSubStep){
                                sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, 
                                    currentStep, "start", isSubStep, subStep);
                                setPlayRecorded(true);
                            }else{
                                sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, currentStep, "start");
                                setPlayRecorded(true);
                            }
                        }
                    }} 
                    onTimeUpdate={DisplayTimeLeft} controls onEnded={()=>{setCurrentStep(currentStep)}}
                    style={{width : `${videoPrev.videoStyle.width}%`, borderRadius : `${videoPrev.videoStyle.borderRadius}px`}}>
                    </video>
                    {(preview.timeLeft && videoPrev.showTimeLeft && !embedded)
                    ?<p className="text-right" style={{color:`${videoPrev.pStyle.color}`}}>{`${videoPrev.timeLeftText} : ${preview.timeText}s`}</p>
                    :null
                    }
                </div>
            }
        </div>
    );
}

interface FormPrevProps {
    form : FormInterface,
    setCurrentStep : Function,
    currentStep : number,
    selectedFunnel : SelectedFunnel,
    submitNewLead : Function,
    stepData : any[],
    embedded : boolean,
}

const FormPreview = ({form, setCurrentStep, currentStep, submitNewLead, selectedFunnel, stepData, embedded} : FormPrevProps) : React.ReactElement => {
    const formPrev : FormInterface = (form.textScreenStyle && form.h1Style && form.pStyle && form.labelStyle && form.inputStyle && form.ctaStyle) 
    ? form 
    : {
        header : "",
        description : "",
        firstNameLabel : "",
        lastNameLabel : "",
        companyLabel : "",
        emailLabel : "",
        emailResults : "",
        ctaText : "",
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        labelStyle : {fontSize : "16", color : ""},
        inputStyle : {with : "50", padding : "0", fontSize :"16", borderRadius : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }

    const handleFormSubmission = (event : React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        let formFields : any = {};

        form.inputFields.forEach((input : any, idx : number)=>{
            if(event.currentTarget.elements[input.label]){
                const formElement = event.currentTarget.elements.namedItem(input.label) as HTMLInputElement
                formFields[input.name] = formElement!.value;
            }
        });

        submitNewLead(selectedFunnel, form.emailResults, formFields, stepData);
        setCurrentStep(currentStep);
    } 

    return(
        <div className="w-full h-full flex justify-center max-md:items-start md:items-center pt-10 pb-10">
            <div className={`text-${formPrev.textScreenStyle.textAlign} mb-20 w-2/3 `} style={{padding : `${formPrev.textScreenStyle.padding}px`, 
                backgroundColor : `${formPrev.textScreenStyle.backgroundColor}`}}>
                {(formPrev.header)?
                <p style={{fontSize : `${formPrev.h1Style.fontSize}px`, color : `${formPrev.h1Style.color}`}}>
                    {formPrev.header}
                </p>
                :null}
                {(formPrev.description)?
                <p style={{fontSize : `${formPrev.pStyle.fontSize}px`, color : `${formPrev.pStyle.color}`}} className="mt-5">
                    {formPrev.description}
                </p>
                :null}
                <form className="mt-5" onSubmit={handleFormSubmission}>
                    {
                    (form.inputFields && form.inputFields.length > 0)
                    ?<>{form.inputFields.map((input : any, idx : number) => {
                        return<div key={idx}>
                                <label htmlFor={input.name} style={{fontSize : `${form.labelStyle.fontSize}px`, color : `${form.labelStyle.color}`}}>
                                    {input.label}
                                </label><br/>
                                <input type={input.type} name={(input.name === "other")?`${input.name}${idx}`:input.name} id={input.label} className="border-[1px]" required 
                                style={{width :`${form.inputStyle.width}%`, padding : `${form.inputStyle.padding}px`,
                                fontSize : `${form.inputStyle.fontSize}px`, borderRadius: `${form.inputStyle.borderRadius}px`}}/>
                            </div>
                    })}</>
                    :<>
                    <label htmlFor="firstName" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                    {(formPrev.firstNameLabel)?formPrev.firstNameLabel:"Fist name"}
                    </label><br/>
                    <input type="text" name="firstName" className="border-[1px]" required
                    style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                    fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                    <label htmlFor="lastName" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                        {(formPrev.lastNameLabel)?formPrev.lastNameLabel:"Last name"}
                    </label><br/>
                    <input type="text" name="lastName" className="border-[1px]" required
                    style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                    fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                    <label htmlFor="email" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                        {(formPrev.emailLabel)?formPrev.emailLabel:"Email"}
                    </label><br/>
                    <input type="text" name="email" className="border-[1px]" required
                    style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                    fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                    <label htmlFor="company" style={{fontSize : `${formPrev.labelStyle.fontSize}px`, color : `${formPrev.labelStyle.color}`}}>
                        {(formPrev.companyLabel)?formPrev.companyLabel:"Company"}
                        </label><br/>
                    <input type="text" name="company" className="border-[1px]" required
                    style={{width :`${formPrev.inputStyle.width}%`, padding : `${formPrev.inputStyle.padding}px`,
                    fontSize : `${formPrev.inputStyle.fontSize}px`, borderRadius: `${formPrev.inputStyle.borderRadius}px`}}/><br/>
                    </>
                    }
                    <button className="mt-5 mb-10" type="submit"
                    style={{fontSize : `${formPrev.ctaStyle.fontSize}px`, padding :  `${formPrev.ctaStyle.padding}px`, borderRadius : `${formPrev.ctaStyle.borderRadius}px`,
                    color :  `${formPrev.ctaStyle.color}`, backgroundColor :  `${formPrev.ctaStyle.backgroundColor}`}}>
                        {(formPrev.ctaText)?formPrev.ctaText:"Submit form"}
                    </button>
                </form>
            </div>
        </div>
    );
};

interface TextPrevProps {
    textScreen : TextScreenInterface,
    setCurrentStep : Function,
    currentStep : number,
    embedded : boolean,
}

const TextPreview = ({textScreen, setCurrentStep, currentStep, embedded} : TextPrevProps) : React.ReactElement => {
    const textPrev : TextScreenInterface = (textScreen.textScreenStyle && textScreen.h1Style && textScreen.pStyle && textScreen.ctaStyle)
    ?textScreen 
    :{
        h1 : textScreen.h1,
        description : textScreen.description,
        ctaText : textScreen.ctaText,
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }

    return(
        <div className="w-full h-full flex justify-center items-center">
            <div className={`text-${textPrev.textScreenStyle.textAlign} w-2/3`} style={{padding : `${textPrev.textScreenStyle.padding}px`, 
            backgroundColor : `${textPrev.textScreenStyle.backgroundColor}`}}>
                {(textPrev.h1)?
                <p style={{fontSize : `${textPrev.h1Style.fontSize}px`, color : `${textPrev.h1Style.color}`}} className="">
                    {textPrev.h1}
                </p>
                :null}
                {(textPrev.description)?
                <p style={{fontSize : `${textPrev.pStyle.fontSize}px`, color : `${textPrev.pStyle.color}`}} className="mt-5">
                    {textPrev.description}
                </p>
                :null}
                <button onClick={()=>{setCurrentStep(currentStep);}} className="mt-5"
                style={{fontSize : `${textPrev.ctaStyle.fontSize}px`, padding :  `${textPrev.ctaStyle.padding}px`, borderRadius : `${textPrev.ctaStyle.borderRadius}px`,
                color :  `${textPrev.ctaStyle.color}`, backgroundColor :  `${textPrev.ctaStyle.backgroundColor}`}}>
                    {(textPrev.ctaText)?textPrev.ctaText:"Continue"}
                </button>
            </div>
        </div>
    );
}

interface TailoredProps {
    tailored : TailoredStep,
    currentStep : number,
    setCurrentStep : Function,
    selectedFunnel : SelectedFunnel,
    sendStepData : Function,
    embedded : boolean,
}

const TailoredFunnel = ({tailored, currentStep, setCurrentStep, selectedFunnel, sendStepData, embedded} : TailoredProps) : React.ReactElement => {
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [DemoStarted, setDemoStarted] = useState<boolean>(false);
    const [questions, setQuestions] = useState<TailoringInterface[]>(JSON.parse(JSON.stringify(tailored.questions)));

    const [tailoredDemo, setTailoredDemo] = useState<TailoringInterface[]>([]);
    const [tailoredDemoStep, setTailoredDemoStep] = useState<number>(0);

    const nextVideo = (step : number) => {
        sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, 
            currentStep, "finish", true, tailoredDemoStep);
        setTailoredDemoStep(tailoredDemoStep + 1)
    };

    const handleShowChange = (idx : number, importance : number | undefined) => {
        let TempQuestions = [...questions];
        TempQuestions[idx].importance = importance
        setQuestions(TempQuestions);
        if(importance === undefined){
            setAllSelected(false);
        }else{
            let alldone = true;
            questions.forEach((question : TailoringInterface) =>{
                if(question.importance === undefined){
                    alldone = false;
                }
            });
            setAllSelected(alldone);
        }
    }

    const handleShowAllChange = (importance : number) => {
        let TempQuestions = [...questions];
        TempQuestions.forEach((question : any) => {   
            question.importance = importance;
            setAllSelected(true);
            setQuestions(TempQuestions);
        });
    }

    return(
        <div className="w-full h-full">
        {
            (!DemoStarted)
            ?<div className="mt-5 h-full w-full flex flex-col justify-start items-center relative" 
            style={{backgroundColor : `${tailored.textScreenStyle.backgroundColor}`}}>
                <div className={`text-${"left"} flex flex-col gap-3`} style={{padding : `${tailored.textScreenStyle.padding}px`}}>
                    <div className="flex flex-row">
                        <div className="flex flex-col gap-5 w-3/5 max-md:w-full">
                            <p style={{fontSize :`${tailored.h1Style.fontSize}px`, color: `${tailored.h1Style.color}`}}>{tailored.header}</p>
                            <p style={{fontSize :`${tailored.pStyle.fontSize}px`, color: `${tailored.pStyle.color}`}}>{tailored.description}</p>
                        </div>
                        <div className="flex justify-end items-end w-2/5 max-md:hidden">
                            <button className="btn font-light" style={{fontSize :`${tailored.ctaStyle.fontSize}px`, color: `${tailored.ctaStyle.color}`,
                                padding : `${tailored.ctaStyle.padding}px`, borderRadius : `${tailored.ctaStyle.borderRadius}px`,
                                backgroundColor : `${tailored.ctaStyle.backgroundColor}`}}
                            disabled={!allSelected} 
                            onClick={()=>{
                                let tempQuestionList : TailoringInterface[] = [];
                                questions.forEach((question : TailoringInterface) => {
                                    if(question.importance !== undefined && question.importance > 1){
                                        tempQuestionList.push(question);
                                    }
                                });
                                if(tempQuestionList.length > 0){
                                    setTailoredDemo(tempQuestionList);
                                    setDemoStarted(true);
                                }else{
                                    setCurrentStep(currentStep)
                                }
                            }}
                            >{tailored.ctaString}</button>
                        </div>
                    </div>
                    <div className="p-5 rounded-lg mt-5 shadow-lg" style={{backgroundColor : `${tailored.tableStyle.backgroundColor}`}}>
                        <table className="w-full  max-md:hidden">
                            <thead className="">
                                <tr className="">
                                    <th className="w-2/5 text-left" style={{fontSize :`${tailored.tableStyle.titleFont}px`, color: `${tailored.tableStyle.titleColor}`}}
                                    >{tailored.topicString}</th>
                                    {tailored.importanceStrings.map((importance, idx) => {
                                        return(
                                            <th className="w-1/5 text-center" key={idx} style={{fontSize :`${tailored.tableStyle.titleFont}px`, color: `${tailored.tableStyle.titleColor}`}}
                                            >{importance}<br/>
                                                <span className="text-xs cursor-pointer" style={{color: `${tailored.ctaStyle.backgroundColor}`}}
                                                onClick={()=>{
                                                    if(idx === 0){handleShowAllChange(3)}
                                                    if(idx === 1){handleShowAllChange(2)}
                                                    if(idx === 2){handleShowAllChange(1)}
                                                }}>{tailored.selectAllString}</span>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                        </table>
                        <div className="md:hidden join flex flex-row justify-center items-center sticky top-0">
                            {tailored.importanceStrings.map((importance : any, idx : number) => {
                                return  <div className="join-item w-1/3 flex justify-center items-center" key={idx}>
                                            <p style={{fontSize :`${tailored.tableStyle.titleFont}px`, color: `${tailored.tableStyle.titleColor}`}}>
                                                {importance} <br/>
                                                <span className="text-xs cursor-pointer" style={{color: `${tailored.ctaStyle.backgroundColor}`}}
                                                onClick={()=>{
                                                    if(idx === 0){handleShowAllChange(3)}
                                                    if(idx === 1){handleShowAllChange(2)}
                                                    if(idx === 2){handleShowAllChange(1)}
                                                }}>{tailored.selectAllString}</span>
                                            </p>
                                        </div>
                            })}
                        </div>

                        <div className="divider divider-neutra  max-md:hidden"></div>
                        <table className="w-full mt-5 max-md:hidden">
                        <tbody className="mt-5">
                                {questions.map((question : any, idx : number)=> {
                                    return(
                                        <tr style={{backgroundColor : `${(idx % 2 !== 0)? tailored.tableStyle.backgroundColor : `${tailored.tableStyle.backgroundColor} 50%`}`}}>
                                            <td className="p-5 w-2/5" style={{fontSize :`${tailored.tableStyle.textFont}px`, color: `${tailored.tableStyle.textColor}`}} 
                                            >{question.question}</td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 3) ? "bg-green text-white" : "hover:bg-green hover:text-white opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{if(question.importance === 3){handleShowChange(idx, undefined);}else{handleShowChange(idx, 3);}}}>
                                                <p className="flex justify-center items-center"><FaThumbsUp /></p>
                                            </td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 2) ? "bg-yellow" : "hover:bg-yellow opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{if(question.importance === 2){handleShowChange(idx, undefined);}else{handleShowChange(idx, 2);}}}>
                                                <p className="flex justify-center items-center"><FaQuestion /></p>
                                            </td>
                                            <td className={`w-1/5 text-center p-5 ${(question.importance === 1) ? "bg-red text-white" : "hover:bg-red hover:text-white opacity-70"} cursor-pointer transition-all`}
                                            onClick={()=>{if(question.importance === 1){handleShowChange(idx, undefined);}else{handleShowChange(idx, 1);}}}>
                                                <p className="flex justify-center items-center"><FaThumbsDown /></p>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="md:hidden flex flex-col gap-5 pb-10">
                        {questions.map((question : any, idx : number) => {
                            return(
                                <div className={`flex flex-col justify-center items-center w-full rounded-xl shadow-lg p-5`} style={{backgroundColor : tailored.tableStyle.backgroundColor}}>
                                    <p className="w-full mb-5" style={{fontSize :`${tailored.tableStyle.textFont}px`, color: `${tailored.tableStyle.textColor}`}}>{question.question}</p>
                                    <div className={`join flex flex-row justify-center items-center w-full`}>
                                        <div className={`w-1/3 text-center p-5 ${(question.importance === 3) ? "bg-green text-white" : "lg:hover:bg-green lg:hover:text-white opacity-70"} cursor-pointer transition-all`}>
                                            <p className="flex flex-row w-full justify-center items-center"
                                            onClick={()=>{if(question.importance === 3){handleShowChange(idx, undefined);}else{handleShowChange(idx, 3);}}}>
                                                <FaThumbsUp />
                                            </p>
                                        </div>
                                        <div className={`w-1/3 text-center p-5 ${(question.importance === 2) ? "bg-yellow" : "lg:hover:bg-yellow opacity-70"} cursor-pointer transition-all`}>
                                            <p className="flex flex-row w-full justify-center items-center"
                                            onClick={()=>{if(question.importance === 2){handleShowChange(idx, undefined);}else{handleShowChange(idx, 2);}}}>
                                                <FaQuestion />
                                            </p>
                                        </div>
                                        <div className={`w-1/3 text-center p-5 ${(question.importance === 1) ? "bg-red text-white" : "lg:hover:bg-red lg:hover:text-white opacity-70"} cursor-pointer transition-all`}>
                                            <p className="flex flex-row w-full justify-center items-center"
                                            onClick={()=>{if(question.importance === 1){handleShowChange(idx, undefined);}else{handleShowChange(idx, 1);}}}>
                                                <FaThumbsDown />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="md:hidden w-full sticky bottom-0 p-3 text-right z-10" 
                style={{boxShadow : `0px -2px 2px 1px ${tailored.ctaStyle.backgroundColor + "50"}`, backgroundColor : tailored.textScreenStyle.backgroundColor}}>
                    <button className="btn font-light" style={{fontSize :`${tailored.ctaStyle.fontSize}px`, color: `${tailored.ctaStyle.color}`,
                            padding : `${tailored.ctaStyle.padding}px`, borderRadius : `${tailored.ctaStyle.borderRadius}px`,
                            backgroundColor : `${(allSelected)?tailored.ctaStyle.backgroundColor:""}`}}
                        disabled={!allSelected} 
                        onClick={()=>{
                            let tempQuestionList : TailoringInterface[] = [];
                            questions.forEach((question : TailoringInterface) => {
                                if(question.importance !== undefined && question.importance > 1){
                                    tempQuestionList.push(question);
                                }
                            });
                            if(tempQuestionList.length > 0){
                                setTailoredDemo(tempQuestionList);
                                setDemoStarted(true);
                            }else{
                                setCurrentStep(currentStep)
                            }
                        }}
                        >{tailored.ctaString}</button>
                </div>
            </div>
            :<div className="h-full w-full">
                {tailoredDemo.map((question : TailoringInterface, idx : number) => {
                    if(tailoredDemoStep === idx){
                        if(idx === tailoredDemo.length - 1){
                            return <VideoPreview selectedFunnel={selectedFunnel} sendStepData={sendStepData} isSubStep={true} subStep={tailoredDemoStep}
                            video={question.video} currentStep={currentStep} setCurrentStep={setCurrentStep} embedded={embedded}/>
                        }else{
                            return <VideoPreview selectedFunnel={selectedFunnel} sendStepData={sendStepData} isSubStep={true} subStep={tailoredDemoStep}
                            video={question.video} currentStep={currentStep} setCurrentStep={nextVideo} embedded={embedded}/>
                        }
                    }
                })}
            </div>
        }
        </div>
    );
}

interface EndPrevProps {
    end : ThankYouInterface,
    currentStep : number,
    sendStepData : Function,
    selectedFunnel : SelectedFunnel,
    embedded : boolean,
}

const EndPreview = ({end, sendStepData, selectedFunnel, currentStep, embedded} : EndPrevProps) : React.ReactElement => {
    const endPrev : ThankYouInterface = (end.textScreenStyle && end.h1Style && end.pStyle && end.ctaStyle)
    ?end 
    :{
        h1 : end.h1,
        description : end.description,
        ctaText : end.ctaText,
        ctaUrl : end.ctaUrl,
        textScreenStyle : {textAlign : "left", padding : "0", backgroundColor : "#ffffff"},
        h1Style : {fontSize : "24", color : ""},
        pStyle : {fontSize : "18", color : ""},
        ctaStyle : {fontSize : "18", color : "#ffffff", backgroundColor : "", padding : "10", borderRadius : "10"}
    }

    const endRedirect = () => {
        sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, currentStep, "finish");
        window.open(end.ctaUrl, "_blank");
    }

    return(
        <div className="w-full h-full flex justify-center items-center">
            <div className={`text-${endPrev.textScreenStyle.textAlign} w-2/3`} style={{padding : `${endPrev.textScreenStyle.padding}px`, 
                        backgroundColor : `${endPrev.textScreenStyle.backgroundColor}`}}>
                {(endPrev.h1)?
                <p style={{fontSize : `${endPrev.h1Style.fontSize}px`, color : `${endPrev.h1Style.color}`}}>
                    {endPrev.h1}
                </p>
                :null}
                {(endPrev.description)?
                <p style={{fontSize : `${endPrev.pStyle.fontSize}px`, color : `${endPrev.pStyle.color}`}} className="mt-5">
                    {endPrev.description}
                </p>
                :null}
                <button className="btn mt-5 font-normal" onClick={endRedirect}
                style={{fontSize : `${endPrev.ctaStyle.fontSize}px`, padding :  `${endPrev.ctaStyle.padding}px`, borderRadius : `${endPrev.ctaStyle.borderRadius}px`,
                color :  `${endPrev.ctaStyle.color}`, backgroundColor :  `${endPrev.ctaStyle.backgroundColor}`}}>
                    {(endPrev.ctaText)?endPrev.ctaText:"Continue"}
                </button>
            </div>
        </div>
    );
}

const Demo = () : React.ReactElement => {
    const {selectedFunnel, sendStepData, submitNewLead, embedded} = useContext(PublicContext);
    const [stepData, setStepData] = useState<any>([]);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [viewAdded, setViewAdded] = useState<boolean>(false);
    const [bgColor, setBgColor] = useState<string>("white");

    //handles changin the funnel step & sending data from both finish of current step and the start of next step
    const MoveForward = (step : number) => {
        sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, step, "finish");
        if(selectedFunnel.steps[currentStep].video){
            setStepData([...stepData, {engagement : "video", log : new Date().toLocaleString("fi-FI")}]);
        }
        if(selectedFunnel.steps[currentStep].form){
            setStepData([...stepData, {engagement : "form submission", log : new Date().toLocaleString("fi-FI")}]);
        }
        if(selectedFunnel.steps[currentStep].textScreen){
            setStepData([...stepData, {engagement : "text screen", log : new Date().toLocaleString("fi-FI")}]);
        }
        if(selectedFunnel.steps[currentStep].tailored){
            setStepData([...stepData, {engagement : "tailoring", log : new Date().toLocaleString("fi-FI")}]);
        }
        setCurrentStep(step + 1);
        if(!selectedFunnel.steps[currentStep + 1].video){
            sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, step + 1, "start");
        }
    }

    //handles sending the initial view data
    useEffect(()=>{
        if(!viewAdded && selectedFunnel){
            sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, 0, "view");
            setViewAdded(true);
            if(!selectedFunnel.steps[0].video){
                sendStepData(selectedFunnel.workspace_id, selectedFunnel.ABtest_id, selectedFunnel.AB_variant, selectedFunnel.form_id, 0, "start");
            }
        }
    }, [viewAdded, selectedFunnel, sendStepData])

    return(
        <div className="w-svw h-svh flex justify-center items-center overflow-auto relative"
        style={{ backgroundColor : `${bgColor}`, scrollbarWidth : "none"}}>
           
            {
                (selectedFunnel)
                ?<div className="h-full flex w-full">
                    {
                        (selectedFunnel.steps && selectedFunnel.steps.length > 0)
                        ?<div className="w-full h-full">
                            {selectedFunnel.steps.map((step : Step, idx : number) => {
                                if(step.action === "video" && idx === currentStep){
                                    if(step.video?.textScreenStyle && bgColor !== step.video.textScreenStyle.backgroundColor){
                                        setBgColor(step.video.textScreenStyle.backgroundColor);
                                    }
                                    return <VideoPreview isSubStep={false} video={step.video!} sendStepData={sendStepData} selectedFunnel={selectedFunnel}
                                    setCurrentStep={MoveForward} currentStep={currentStep} embedded={embedded}/>
                                }
                                if(step.action === "form" && idx === currentStep){
                                    if(step.form?.textScreenStyle && bgColor !== step.form.textScreenStyle.backgroundColor){
                                        setBgColor(step.form.textScreenStyle.backgroundColor);
                                    }
                                    return <FormPreview form={step.form!} selectedFunnel={selectedFunnel} submitNewLead={submitNewLead} stepData={stepData}
                                    setCurrentStep={MoveForward} currentStep={currentStep} embedded={embedded}/>
                                }
                                if(step.action === "textScreen" && idx === currentStep){
                                    if(step.textScreen?.textScreenStyle && bgColor !== step.textScreen.textScreenStyle.backgroundColor){
                                        setBgColor(step.textScreen.textScreenStyle.backgroundColor);
                                    }
                                    return <TextPreview textScreen={step.textScreen!} embedded={embedded}
                                    setCurrentStep={MoveForward} currentStep={currentStep}/>
                                }
                                if(step.action === "tailored" && idx === currentStep){
                                    if(step.tailored?.textScreenStyle && bgColor !== step.tailored.textScreenStyle.backgroundColor){
                                        setBgColor(step.tailored.textScreenStyle.backgroundColor);
                                    }
                                    return <TailoredFunnel tailored={step.tailored!} selectedFunnel={selectedFunnel} sendStepData={sendStepData}
                                    setCurrentStep={MoveForward} currentStep={currentStep} embedded={embedded}/>
                                }
                                if(step.action === "end screen" && idx === currentStep){
                                    if(step.thankYouPage?.textScreenStyle && bgColor !== step.thankYouPage.textScreenStyle.backgroundColor){
                                        setBgColor(step.thankYouPage.textScreenStyle.backgroundColor);
                                    }
                                    return <EndPreview end={step.thankYouPage!} embedded={embedded} currentStep={currentStep} sendStepData={sendStepData} 
                                    selectedFunnel={selectedFunnel}/>
                                }
                                if(currentStep >= selectedFunnel.steps.length){
                                    setCurrentStep(0);
                                }
                                return null;
                            })}
                        </div>
                        :<div className="skeleton h-96 w-full"></div>
                    }
                </div>
                :<p>Loading...</p>
            }
            {
                (selectedFunnel && selectedFunnel.showBranding)
                ? <a className="absolute top-2 right-2 max-md:hidden
                w-fit flex flex-row justify-center items-center shadow-xl 
                p-2 pr-4 pl-4 rounded-xl cursor-pointer bg-brandPurple
                hover:bg-brandLightPurple transition text-white text-xs" 
                    href="https://cxtailor.com/" target="_blank">
                        <p>Built with</p>
                        <img src="/CX-Tailor-Logo-Light.png" alt='CX Tailor logo' className="h-4 pr-2 pl-2"/>
                  </a>
                :null
            }
        </div>
    );
};

export default Demo;